import React from "react";
import styles from "./Input.module.css";

const Toggle = ({ isActive, onChange, text }) => {
  return (
    <div className={styles["toggle-container"]}>
      <label className={styles["switch"]}>
        <input type='checkbox' checked={isActive} onChange={onChange} />
        <span className={styles["slider"]}></span>
      </label>
      <h4>{text}</h4>
    </div>
  );
};

export default Toggle;
