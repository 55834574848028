import React, { useEffect, useRef, useState } from "react";
import Chart from "../../Campaign/NewCampaign/CreateEmailWorkflow/Chart/Chart";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import ReactFlow, { Controls } from "reactflow";
import styles from "./EmailWorkflow.module.css";
import EmailPreview from "./EmailPreview";
import UserReceiver from "./UserReceiver";
import MobileEmailPreview from "./MobileEmailPreview";
import { useFetchNodeInformationbyId } from "../../../../Hooks/Campaign";
import Loader from "../../Loader/Loader";
import ActionForm from "../../Campaign/NewCampaign/CreateEmailWorkflow/RightSideComponents/ActionForm";
import ConditionsForm from "../../Campaign/NewCampaign/CreateEmailWorkflow/RightSideComponents/ConditionsForm";
import EmptyText from "../../Custom/EmptyText";
const EmailWorkflow = ({ campaignDetails }) => {
  const formRef = useRef();

  const [selectedNode, setSelectedNode] = useState({});
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [showMobilePopup, setShowMobilePopup] = useState(false);
  const [nodeId, setNodeId] = useState("");

  const { data: nodeInformation, isLoading: isInformationLoading } =
    useFetchNodeInformationbyId(nodeId);
  const isMobile = () => window.innerWidth <= 800;

  const handleNodeSelect = (node) => {
    setSelectedNode(node);
    setNodeId(node.nodeId);
    if (isMobile()) {
      setShowMobilePopup(true);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      if (isMobile()) {
        setShowMobilePopup(true);
      } else {
        setShowMobilePopup(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const fetchedEdges = campaignDetails?.campaignEdges?.map((item) => {
      return {
        id: item.edgeName,
        source: item.sourceId?.toString(),
        sourceHandle: item.sourceHandle || null,
        target: item.targetId?.toString(),
      };
    });
    setEdges(fetchedEdges);

    const fetchedNodes = campaignDetails?.campaignNodes?.map((item) => {
      if (item.diagramType == 0) {
        return {
          nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
          type: "rectangularNode",
          data: {
            label: {
              email:
                item.emailTemplate && item.emailTemplate[0].emailTemplateId,
              emailCategory:
                item.emailTemplate && item.emailTemplate[0].emailTag,
            },
            action: "email",
          },
          position: { x: item.positionX, y: item.positionY },
          id: item.intId?.toString(),
          innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
        };
      } else if (item.diagramType == 1) {
        return {
          nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
          type: "rectangularNode",
          data: {
            label: {
              wait: item.waitTime,
            },
            action: "wait",
          },
          position: { x: item.positionX, y: item.positionY },
          id: item.intId?.toString(),
          innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
        };
      } else if (item.diagramType == 3) {
        return {
          nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
          type: "rectangularNode",
          data: {
            label: {
              actionChoice: item.actions && item.actions[0]?.actionChoice,
              changeTo: item.actions && parseInt(item.actions[0]?.updateTo),
            },
            action: "action",
          },
          position: { x: item.positionX, y: item.positionY },
          id: item.intId?.toString(),
          innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
        };
      } else if (item.diagramType == 2) {
        return {
          nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
          type: "diamondNode",
          data: {
            label: {
              field: item.conditions && item.conditions[0]?.audienceOptionId,
              operation: item.conditions && item.conditions[0]?.inNotIn,
              value: item.conditions && item.conditions[0]?.valueString,
            },
            action: "condition",
          },
          position: { x: item.positionX, y: item.positionY },
          id: item.intId?.toString(),
          innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
        };
      } else {
        return {
          nodeId: item.nodeId || "00000000-0000-0000-0000-000000000000",
          type: "startNode",
          data: { label: "Start" },
          position: { x: item.positionX, y: item.positionY },
          id: item.intId?.toString(),
          innerId: item.innerId || "00000000-0000-0000-0000-000000000000",
        };
      }
    });
    setNodes(fetchedNodes);
  }, [campaignDetails]);

  const isSelectedNodeEmpty = Object.keys(selectedNode).length === 0;

  console.log("nodes", nodes);

  return (
    <>
      <WhiteContainer noPadding>
        <div className={!isSelectedNodeEmpty ? styles["grid_container"] : ""}>
          {campaignDetails?.campaignNodes?.length > 0 ? (
            <Chart
              nodes={nodes}
              edges={edges}
              setEdges={null}
              setNodes={null}
              onNodeSelect={handleNodeSelect}
              readOnly
            />
          ) : (
            <WhiteContainer white>
              <EmptyText text='Nodes' />
            </WhiteContainer>
          )}

          {isInformationLoading ? (
            <Loader flexStart />
          ) : (
            <>
              {selectedNode?.data?.action == "email" ? (
                <div className={styles["mobile_responsive"]}>
                  {!isSelectedNodeEmpty && (
                    <UserReceiver
                      users={nodeInformation?.data}
                      selectedUserId={selectedUserId}
                      setSelectedUserId={setSelectedUserId}
                    />
                  )}
                </div>
              ) : selectedNode?.data?.action == "action" ? (
                <div className={styles["mobile_responsive"]}>
                  {!isSelectedNodeEmpty && (
                    <div
                      className={styles["container"]}
                      style={{ backgroundColor: "#FFFF", padding: "20px" }}
                    >
                      <ActionForm
                        ref={formRef}
                        selectedNode={selectedNode}
                        view
                      />
                    </div>
                  )}
                </div>
              ) : selectedNode?.data?.action == "condition" ? (
                <div className={styles["mobile_responsive"]}>
                  {!isSelectedNodeEmpty && (
                    <div
                      className={styles["container"]}
                      style={{ backgroundColor: "#FFFF", padding: "20px" }}
                    >
                      <ConditionsForm
                        ref={formRef}
                        selectedNode={selectedNode}
                        view
                      />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </div>
      </WhiteContainer>
      {selectedNode?.data?.label !== "Start" && showMobilePopup && (
        <>
          {" "}
          {selectedNode?.data?.action == "email" ? (
            <MobileEmailPreview
              handleOnClose={() => setShowMobilePopup(false)}
              isInformationLoading={isInformationLoading}
              data={nodeInformation?.data}
            />
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default EmailWorkflow;
