import { createContext, useContext, useState } from "react";

const EmailContext = createContext();

export const EmailProvider = ({ children }) => {
  const [selectedEmail, setSelectedEmail] = useState({});

  const setNewSelectedEmail = (email) => {
    setSelectedEmail(email);
  };

  return (
    <EmailContext.Provider value={{ selectedEmail, setNewSelectedEmail }}>
      {children}
    </EmailContext.Provider>
  );
};

export const useEmailContext = () => {
  return useContext(EmailContext);
};
