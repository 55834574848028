import React, { useState, useEffect } from "react";
import styles from "./TableTopHeader.module.css";
import columnVisibilityIcon from "../../../assets/Imagess/columnVisibility.png";
import MoreButton from "../Custom/PopOver/MoreButton";
import CheckBox from "../../Atoms/Inputs/CheckBox";

const ColumnVisibility = ({ tableHeader, visibleColumn, setVisibleColumn, title = null }) => {
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const allColumnsVisible = tableHeader.every((item) => visibleColumn[item.key]);
    setSelectAll(allColumnsVisible);
  }, [visibleColumn, tableHeader]);

  const toggleCheckColumn = (key) => {
    setVisibleColumn((prevVisibleColumn) => ({
      ...prevVisibleColumn,
      [key]: !prevVisibleColumn[key],
    }));
  };

  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const newVisibleColumn = tableHeader.reduce((acc, item) => {
      acc[item.key] = newSelectAll;
      return acc;
    }, {});
    setVisibleColumn(newVisibleColumn);
  };

  return (
    <MoreButton
      icon={columnVisibilityIcon}
      title={title}
      width='250px'
      toolTipText='Column Visibility'
    >
      <div className={styles["columnVisibility-cont"]}>
        <div className={styles["columnVisibility-subcont"]}>
          <CheckBox
            text="Select All"
            value={selectAll}
            onChange={handleSelectAllChange}
            fontWeight="500"
          />
        </div>
        {tableHeader?.map((item, index) => {
          const subContainerClassName = `${
            styles["columnVisibility-subcont"]
          } ${index % 2 === 0 ? styles["columnVisibility-subcont-even"] : ""}`;
          return (
            <div key={index} className={subContainerClassName}>
              <CheckBox
                text={item.title}
                value={visibleColumn[item.key]}
                onChange={() => toggleCheckColumn(item.key)}
                fontWeight="500"
              />
            </div>
          );
        })}
      </div>
    </MoreButton>
  );
};

export default ColumnVisibility;
