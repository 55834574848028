import React from "react";
import styles from "./WrappingContainer.module.css";
const WhiteBoxContainer = ({ children, center }) => {
  return (
    <div
      className={styles["whiteBoxContainer"]}
      style={{ justifyContent: center ? "center" : "" }}
    >
      {children}
    </div>
  );
};

export default WhiteBoxContainer;
