import React, { useEffect, useRef, useState } from "react";
import WrappingContainer from "../WrappingContainer/WrappingContainer";
import styles from "./SettingsBody.module.css";
import SettingsNavbar from "../../Moecules/Settings/SettingsNavBar/SettingsNavbar";
import SettingsPage from "../../Template/SettingsTemplate/SettingsPage";
import {
  useFetchAllTypes,
  useFetchDropDownTypes,
} from "../../../Hooks/Settings";
import { useLocation } from "react-router-dom";
import uploadbulk from "../../../assets/Imagess/bulkicon.svg";
import ExternalContactSettings from "../../Moecules/Settings/ExternalPages/ExternalContactSettings";
import TermSettings from "../../Moecules/Settings/ExternalPages/TermSettings";
import AcademicYearSettings from "../../Moecules/Settings/ExternalPages/AcademicYearSettings";
import ESignature from "../../Moecules/Settings/ExternalPages/ESignature";
import WhiteContainer from "../WrappingContainer/WhiteContainer";
import TitlePageText from "../../Atoms/Texts/TitlePageText";
import ColoredButton from "../../Atoms/Buttons/ColoredButtons/ColoredButton";
import SettingsBurger from "../../Moecules/Settings/SettingsNavBar/SettingBurger/SettingsBurger";

const SettingsBody = () => {
  const location = useLocation();
  const [type, setType] = useState(location?.state?.type || "0");
  const [title, setTitle] = useState("Application Type");
  const [isRelated, setIsRelated] = useState(null);
  const [orderBy, setOrderBy] = useState("");
  const [orderDir, setOrderDir] = useState("desc");
  const [search, setSearch] = useState("");
  const printRef = useRef();

  const { data: allTypes, isLoading: isTypesLoading } = useFetchAllTypes();
  const {
    data: options,
    refetch: refetchTypes,
    isLoading: isOptionsLoading,
  } = useFetchDropDownTypes({
    typeId: type,
    orderBy: orderBy,
    search: search,
    orderDir: orderDir,
  });

  useEffect(() => {
    const titleMapping = {
      0: "Application Type",
      1: "Title",
      2: "Hear About Us",
      3: "Gender",
      4: "Nationality",
      5: "Program",
      6: "Guardian Relationship",
      7: "Diploma Type",
      8: "Field of Interest",
      9: "Country",
      10: "City/State",
      11: "University",
      12: "School",
      13: "Level Of Study",
      14: "Employment Status",
      15: "Employment Sector",
      16: "Contact Type",
      17: "Contact Priority",
      18: "Curriculum",
      19: "Terms",
      20: "Academic Year",
      21: "External Contact",
      22: "Language",
      23: "Grade",
      24: "E-Signature",
      25: "Source of Connection",
    };
    setTitle(titleMapping[type] || "Source of Connection");
  }, [type]);

  useEffect(() => {
    setSearch("");
    setOrderBy("");
  }, [type]);

  return (
    <>
      <WrappingContainer>
        <div className={styles["settings-container"]}>
          <div className={styles["settings-bulk"]}>
            <SettingsNavbar
              setType={setType}
              allTypes={allTypes}
              setIsRelated={setIsRelated}
              isTypesLoading={isTypesLoading}
              type={type}
            />
          </div>
          <WhiteContainer white>
            <div className={styles["settings-title"]}>
              <TitlePageText title={title} />
              <div className={styles["settings-bulk"]}>
                <ColoredButton
                  icon={uploadbulk}
                  title='Upload Bulk'
                  color='#F3223C'
                />
              </div>
              <div className={styles["burgerIcon"]}>
                <SettingsBurger
                  allTypes={allTypes}
                  setType={setType}
                  setIsRelated={setIsRelated}
                  isTypesLoading={isTypesLoading}
                  type={type}
                  mobile
                />
              </div>
            </div>

            {type === 21 ? (
              <ExternalContactSettings
                title={title}
                type={type}
                setSearch={setSearch}
                search={search}
              />
            ) : type === 19 ? (
              <TermSettings
                title={title}
                type={type}
                setSearch={setSearch}
                search={search}
              />
            ) : type === 20 ? (
              <AcademicYearSettings
                title={title}
                type={type}
                setSearch={setSearch}
                search={search}
                orderBy={orderBy}
                orderDir={orderDir}
                setOrderBy={setOrderBy}
                setOrderDir={setOrderDir}
              />
            ) : type === 24 ? (
              <ESignature
                title={title}
                type={type}
                setSearch={setSearch}
                search={search}
              />
            ) : (
              <SettingsPage
                data={options?.data}
                title={title}
                type={type}
                refetchTypes={refetchTypes}
                isRelated={isRelated}
                isOptionsLoading={isOptionsLoading}
                setSearch={setSearch}
                search={search}
                orderBy={orderBy}
                orderDir={orderDir}
                setOrderBy={setOrderBy}
                setOrderDir={setOrderDir}
              />
            )}
          </WhiteContainer>
        </div>
      </WrappingContainer>
      {/* <ESognatureModal/> */}
    </>
  );
};

export default SettingsBody;
