import React from "react";
import styles from "./DashboardCategories.module.css";
import DashboardContainer from "../../DashboardContainer";
import WhiteBoxContainer from "../../../../HOC/WrappingContainer/WhiteBoxContainer";
import archIcon from "../../../../../assets/Imagess/Dashboard/archICon.svg";
import businessIcon from "../../../../../assets/Imagess/Dashboard/businessIcon.svg";
import communicationIcon from "../../../../../assets/Imagess/Dashboard/communicationIcon.svg";
import leadershipIcon from "../../../../../assets/Imagess/Dashboard/leadershipIcon.svg";
import managementIcon from "../../../../../assets/Imagess/Dashboard/managementIcon.svg";
import progressionIcon from "../../../../../assets/Imagess/Dashboard/progressionIcon.svg";

const DashboardCategories = () => {
  const data = [
    {
      title: "Architecture",
      number: "200",
      icon: archIcon,
    },
    {
      title: "Business Administration",
      number: "200",
      icon: businessIcon,
    },
    {
      title: "Communication and Information Studies",
      number: "200",
      icon: communicationIcon,
    },
    {
      title: "Intellectual Property and Innovation Management",
      number: "200",
      icon: leadershipIcon,
    },
    {
      title: "Leadership and Innovation in Contemporary Media",
      number: "200",
      icon: managementIcon,
    },
    {
      title: "MMU International Progression Path",
      number: "200",
      icon: progressionIcon,
    },
  ];
  return (
    <DashboardContainer none>
      <WhiteBoxContainer>
        <h1 style={{ padding: "15px" }}>Most Field of Interest Applied</h1>
      </WhiteBoxContainer>
      <div className={styles["gridTwo"]}>
        {data?.map((item) => {
          return (
            <WhiteBoxContainer center>
              <div className={styles["iconContainer"]}>
                <img src={item.icon} alt='icon' />
                <div className={styles["titleContainer"]}>
                  <h3>{item.title}</h3>
                  <h5>{item.number}</h5>
                </div>
              </div>
            </WhiteBoxContainer>
          );
        })}
      </div>
    </DashboardContainer>
  );
};

export default DashboardCategories;
