import React from "react";
import styles from "./Title_Subtitle.module.css";
const Title_Subtitle = ({ title, subTitle, opacity, row, fontWeight,notes }) => {
  return (
    <div
      className={styles["campaign_titleContainer"]}
      style={{
        flexDirection: row ? "row-reverse" : "",
        justifyContent: row ? "start" : "",
      }}
    >
      <h2 style={{ fontWeight: fontWeight ? fontWeight : "" }}>{title}</h2>
      <h3 style={{ opacity: opacity ? opacity : "" }} className={notes ? styles["notes"] :""}>{subTitle}</h3>
    </div>
  );
};

export default Title_Subtitle;
