import React from "react";
import styles from "./EmailWorkflow.module.css";

const EmailPreview = ({ email }) => {
  return (
    <div className={styles["subContainer"]}>
      <h2 style={{ fontWeight: "600", fontSize: "16px" }}>Email Preview</h2>
      <p
        style={{
          color: "#1B224C",
          fontWeight: "600",
          lineHeight: "30px",
          paddingTop: "25px",
        }}
      >
        {!email ? (
          "Select a user ..."
        ) : (
          <p dangerouslySetInnerHTML={{ __html: email }}></p>
        )}
      </p>
    </div>
  );
};

export default EmailPreview;
