import React, { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import ApplicantLogs from "../../Moecules/Applicants/ApplicantLogs/ApplicantLogs";
import logsIcon from "../../../assets/Imagess/logsIcon.svg";
import notesIcon from "../../../assets/Imagess/notesIcon.svg";
import campaignIcon from "../../../assets/Imagess/Campaign/campaignTabIcon.svg";
import emailTabIcon from "../../../assets/Imagess/Campaign/emailTabIcon.svg";
import referenceTabICon from "../../../assets/Imagess/referenceIConTab.png";
import Inactivenotificationicom from "../../../assets/Imagess/inactivebell.svg";
import inacactiveonline from "../../../assets/Imagess/inACtiveOnlineIcon.svg";
import StatusModal from "../../Moecules/Settings/SettingsModal/StatusModal";
import EmailModal from "../../Moecules/Settings/SettingsModal/EmailModal";
import { useFetchSingleInterested } from "../../../Hooks/Interested";
import { useFetchSingleApplicant } from "../../../Hooks/Applicant";
import Documents from "../../Moecules/Applicants/Documents/Documents";
import inactiveDocumentTabIcon from "../../../assets/Imagess/inActiveDocumentTab.svg";
import OnlineFormContainer from "../../Moecules/Applicants/OnlineFormContainer/OnlineFormContainer";
import NotesContainer from "../../Moecules/Applicants/Notes/NotesContainer";
import ReferenceMolecule from "../../Moecules/Applicants/Reference/ReferenceMolecule";
import SingleApplicantTopHeader from "../../Moecules/SingleApplicant/SingleApplicantTopHeader";
import SingleApplicantNav from "../../Moecules/SingleApplicant/SingleApplicantNav";
import sendEmailICon from "../../../assets/Imagess/sendemailicon.svg";
import ApplicantCampaign from "../../Moecules/Applicants/ApplicantCampaign/ApplicantCampaign";
import EmailsReceived from "../../Moecules/Settings/ContactDetails/EmailsReceived/ReceivedEmail/EmailsReceived";
import EmailsReceivedContainer from "../../Moecules/Settings/ContactDetails/EmailsReceived/EmailsReceivedContainer";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";

const SingleApplicantPAge = () => {
  const { id } = useParams();
  const printRef = useRef();
  const location = useLocation();

  const [isApplicant, setIsApplicant] = useState(
    location?.state?.isApplicant || false
  );
  const [applicant, setApplicant] = useState();
  const [selected, setSelected] = useState("online form");
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [selectedApplicants, setSelectedApplicants] = useState([]);
  const [selectedInterested, setSElectedInterested] = useState([]);
  const [isProspectLead, setIsProspectLead] = useState(false);
  const {
    data: interested,
    refetch: refetchInterested,
    isLoading: isInterestedLoading,
  } = useFetchSingleInterested({
    id: id,
    isInterested: isApplicant ? false : true,
  });
  const {
    data: singleApplicant,
    refetch: refetchApplicant,
    isLoading: isApplicantLoading,
  } = useFetchSingleApplicant({ id: id, isApplicant: isApplicant });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const handleOpenEmailModal = () => {
    setShowEmailModal(true);
    if (isApplicant) {
      setSelectedApplicants([id]);
    } else {
      setSElectedInterested([id]);
    }
  };

  useEffect(() => {
    if (isApplicant) {
      setApplicant(singleApplicant?.data);
    } else {
      setApplicant(interested?.data);
    }
  }, [isApplicant, id, interested, singleApplicant]);

  useEffect(() => {
    if (
      (applicant?.applicantLeadLogs &&
        applicant?.applicantLeadLogs?.length !== 0 &&
        isApplicant) ||
      (applicant?.applicantLeadNotes &&
        applicant?.applicantLeadNotes?.length !== 0 &&
        isApplicant)
    ) {
      setIsProspectLead(true);
    }
  }, [applicant]);

  //tabs container
  const singleTabContainer = [
    {
      text: "Online Form",
      value: "online form",
      icon: inacactiveonline,
      content: (
        <OnlineFormContainer
          applicant={applicant}
          isApplicant={isApplicant}
          isLoading={isApplicant ? isApplicantLoading : isInterestedLoading}
          setShowStatusModal={setShowStatusModal}
        />
      ),
    },
    {
      text: "Logs",
      value: "Logs",
      icon: logsIcon,
      content: (
        <ApplicantLogs
          logs={applicant?.logs}
          isProspectLead={isProspectLead}
          applicantLeadLogs={applicant?.applicantLeadLogs}
        />
      ),
    },
    {
      text: "Notes",
      value: "Notes",
      icon: notesIcon,
      content: (
        <NotesContainer
          notes={applicant?.applicantNotes}
          id={
            isApplicant
              ? applicant?.intersted?.applicantId
              : applicant?.intersted?.studentId
          }
          refetch={isApplicant ? refetchApplicant : refetchInterested}
          isProspectLead={isProspectLead}
          applicantLeadNotes={applicant?.applicantLeadNotes}
        />
      ),
    },
    ...(isApplicant
      ? [
          {
            text: "Documents",
            value: "Documents",
            icon: inactiveDocumentTabIcon,
            content: <Documents data={applicant} />,
          },
        ]
      : []),
    ...(isApplicant
      ? [
          {
            text: "Reference",
            value: "Reference",
            icon: referenceTabICon,
            content: <ReferenceMolecule data={applicant} />,
          },
        ]
      : []),
    {
      text: "Campaigns",
      value: "Campaigns",
      icon: campaignIcon,
      content: <ApplicantCampaign campaign={applicant?.campaigns} />,
    },
    {
      text: "Emails",
      value: "Emails",
      icon: emailTabIcon,
      content: (
        <EmailsReceivedContainer notifications={applicant?.notifications} />
      ),
    },
  ];

  return (
    <>
      <WrappingContainer>
        <SingleApplicantTopHeader
          data={applicant?.intersted}
          isProspectLead={isProspectLead}
          rightComponent={{
            icon: sendEmailICon,
            text: "Send Email",
            handleFunction: handleOpenEmailModal,
          }}
          bottomLeftComponent={[]}
          bottomRightComponent='kjjknjkn'
          isLoading={isInterestedLoading || isApplicantLoading}
        />
        <SingleApplicantNav
          tabContainer={singleTabContainer}
          selected={selected}
          setSelected={setSelected}
          applicant={applicant}
          handlePrint={handlePrint}
        />

        <PrintComponent
          ref={printRef}
          title={`${applicant?.intersted?.firstName} ${applicant?.intersted?.lastName}`}
        >
          <div>
            {
              singleTabContainer.find((item) => item.value === selected)
                ?.content
            }
          </div>
        </PrintComponent>
      </WrappingContainer>
      {showStatusModal && (
        <StatusModal
          handleOnClose={() => setShowStatusModal(false)}
          title='Change Status'
          isApplicant={isApplicant}
          selected={[
            isApplicant
              ? applicant?.intersted?.applicantId
              : applicant?.intersted?.studentId,
          ]}
          refetch={isApplicant ? refetchApplicant : refetchInterested}
          status={applicant?.intersted?.statusText}
        />
      )}
      {showEmailModal && (
        <EmailModal
          handleOnClose={() => setShowEmailModal(false)}
          title='Send Email'
          selectedApplicants={selectedApplicants}
          selectedInterested={selectedInterested}
          setSelectedApplicants={setSelectedApplicants}
          setSelectedInterested={setSElectedInterested}
          refetchData={isApplicant ? refetchApplicant : refetchInterested}
        />
      )}
    </>
  );
};

export default SingleApplicantPAge;
