import React from "react";
import styles from "./Title_Subtitle.module.css";
const TitleImageComponent = ({
  width,
  height,
  icon,
  fontSize,
  fontWeight,
  text,
  color,
  onClick,
}) => {
  return (
    <div className={styles["container"]} onClick={onClick}>
      <img
        src={icon}
        alt={`${icon}-icon`}
        style={{ width: width, height: height }}
      />
      <p style={{ fontSize: fontSize, fontWeight: fontWeight, color: color }}>
        {text}
      </p>
    </div>
  );
};

export default TitleImageComponent;
