import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const TranscriptMailingAddress = ({ applicationData }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Transcript Mailing Address</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title='Study Abroad Advisor/ Office Name'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.studyBoardAdvisor
            }
            required={true}
          />

          <TextContainer
            title="Registrar's Email"
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.registrarEmail
            }
            required={true}
          />

          <TextContainer
            title='Mobile'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.registrarPhone
            }
            required={true}
            mobile={true}
          />
        </div>
      </div>
    </div>
  );
};

export default TranscriptMailingAddress;
