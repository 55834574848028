import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

/**************Add notes */
const addNote = async (data) => {
  return await axios.post(
    API_URL + `/api/ApplicantView/PostApplicantNotes`,
    data,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useAddNotes = () => {
  return useMutation(addNote);
};

/***************Get application by applicant and application id */
const fetchApplicationByApplicantAndApplication = async (
  applicantId,
  applicationId
) => {
  if (applicationId) {
    return await axios.get(
      API_URL +
        `/api/ApplicantView/GetApplicantByIdAndApplication/${applicantId}/${applicationId}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};
export const useFetchApplicationByApplicantAndApplication = (
  applicantId,
  applicationId
) => {
  return useQuery({
    queryKey: ["applicationByApplicantId", applicantId, applicationId],
    queryFn: () =>
      fetchApplicationByApplicantAndApplication(applicantId, applicationId),
    enabled: true,
  });
};

/***************Get document by id */
const fetchDocumentById = async (applicantFileId) => {
  return await axios.get(
    API_URL + `/api/ApplicantView/GetDocumentById/${applicantFileId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const useDownloadDocument = () => {
  return useMutation(fetchDocumentById);
};

/**************Download all documents */
const fetchAllDocuments = async (params) => {
  return await axios.get(
    API_URL +
      `/api/ApplicantView/DownloadAllDocuments/${params.applicantId}/${params.applicationId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDownloadAllDocuments = () => {
  return useMutation(fetchAllDocuments);
};

/**************Generate PDF ***/
const fetchGeneratePdf = (applicantId) => {
  return axios.get(API_URL + `/api/ApplicantView/GeneratePdf/${applicantId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useFetchGeneratePdf = () => {
  return useMutation(fetchGeneratePdf);
};

/******************Download selected documents  */
const downloadSelectedDocuments = (values) => {
  return axios.post(
    API_URL + `/api/ApplicantView/DownloadSpecificDocuments`,
    values,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDownloadSelectedDocuments = () => {
  return useMutation(downloadSelectedDocuments);
};

/********Delete note */
const deleteNotes = (noteId) => {
  return axios.delete(
    API_URL + `/api/ApplicantView/DeleteApplicantNote/${noteId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDeleteNote = () => {
  return useMutation(deleteNotes);
};
