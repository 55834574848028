import React from "react";
import styles from "./ApplicantDemographics.module.css";
import AgeGraph from "./AgeGraph/AgeGraph";
import GenderGraph from "./GenderGraph/GenderGraph";
import LevelOfEducation from "./LevelOfEducation/LevelOfEducation";
const ApplicantDemographics = () => {
  return (
    <div className={styles["grid_three"]}>
      <AgeGraph />
      <GenderGraph />
      <LevelOfEducation />
    </div>
  );
};

export default ApplicantDemographics;
