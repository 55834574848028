import React from "react";
import styles from "./SourceChannels.module.css";
import ReactApexChart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
const SourceChannels = () => {
  const chartData = {
    series: [
      {
        name: "Value",
        data: [130, 100, 135, 180],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        width: 4,
        colors: ["#3886BD"],
        fill: {
          colors: ["#8cc9f154"],
        },
      },
      title: {
        text: "",
        align: "left",
      },
      xaxis: {
        categories: ["AUD Website", "Email", "Ads", "Facebook Ads"],
        labels: {
          style: {
            colors: "#1b224c",
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        // title: {
        //   text: "Value",
        // },
      },
      grid: {
        borderColor: "#e0e0e0",
      },
      markers: {
        size: 6,
        colors: ["#3886BD"],
        strokeWidth: 0,
        hover: {
          size: 8,
        },
      },
      tooltip: {
        enabled: true,
      },
    },
  };
  return (
    <DashboardContainer>
      <h2>Source from different Channels</h2>
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type='area'
        height={350}
      />
    </DashboardContainer>
  );
};

export default SourceChannels;
