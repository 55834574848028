import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//add external contact
const addESignature = (values) => {
  return axios.post(
    API_URL + `/api/Notifications/PostEmailSignatures`,
    values,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useAddESignature = () => {
  return useMutation(addESignature);
};

//get External Contact
const fetchESignature = async (data) => {
  let url = `${API_URL}/api/Notifications/GetEmailSignatures`;
  const queryParams = [];
  if (data?.search) queryParams.push(`SearchBy=${data.search}`);
  if (data?.orderBy) queryParams.push(`OrderBy=${data.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);
  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchESignature = (data) => {
  return useQuery({
    queryKey: ["eSignature",data],
    queryFn: () => fetchESignature(data),
    enabled: true,
  });
};

//delete by id external contact by id
const deleteEmailSignature = (emailSignature) => {
  return axios.post(
    API_URL + `/api/Notifications/DeleteEmailSignature/${emailSignature}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDeleteEmailSignature = () => {
  return useMutation(deleteEmailSignature);
};
////export e-signature
const exportEmailSignature = async () => {
  return await axios.get(API_URL + `/api/Export/ExportEmailSignature`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportEmailSignature = () => {
  return useMutation(() => exportEmailSignature());
};
