import React, { useEffect, useState } from "react";
import styles from "./Input.module.css";
import searhicon from "../../../assets/Imagess/searchicon.svg";
import closeIcon from "../../../assets/Imagess/xvector.svg";
import Tooltip from "../Tooltip/Tooltip";
const SearchInput = ({ search, setSearch }) => {
  const [filteredSearch, setFilteredSearch] = useState(search);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(filteredSearch);
    }, 900);
    return () => clearTimeout(delayDebounceFn);
  }, [filteredSearch]);

  return (
    <div className={styles["search-bar"]}>
      <div className={styles["search-container"]}>
        <img src={searhicon} className={styles["search-ic"]} alt='icon' />
        <input
          className={styles["search-inp"]}
          type='text'
          placeholder='Search'
          onChange={(e) => setFilteredSearch(e.target.value)}
          value={filteredSearch}
        />
      </div>
      <Tooltip content='Clear Search'>
        <img
          src={closeIcon}
          className={styles["exit-ic"]}
          alt='icon'
          onClick={() => setFilteredSearch("")}
      />
      </Tooltip>
    </div>
  );
};

export default SearchInput;
