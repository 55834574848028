import React from "react";
import styles from "./CampaignDetailedBox.module.css";
const CampaignStatisticsBox = ({ data,noBorder }) => {
  return (
    <div
      className={`${styles["response_container"]} ${noBorder ? "" :styles["responseBorder_container"]}`}
    >
      <h1>{data.number}</h1>
      <div>
        <h3>{data.text}</h3>
        <h4>last 0 hours</h4>
      </div>
    </div>
  );
};

export default CampaignStatisticsBox;
