import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const ParentOfGuardian = ({ applicant, applicationData }) => {
 
  return (
    <div className={styles["personal-container"]}>
      <h3>Parent or Guardian Information</h3>
      <div className={styles["grid-container"]}>
        <div>
          <TextContainer
            title='Guardian Relation'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianRelation_1_Display
            }
            required={true}
          />
          <TextContainer
            title='Guardian Name'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianName_1
            }
            required={true}
          />
          <TextContainer
            title='Mobile'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianMobile_1
            }
            required={true}
            mobile={true}
          />
          <TextContainer
            title='Email'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianEmail_1
            }
            required={true}
            email={true}
          />
        </div>
        <div>
          <TextContainer
            title='Guardian Relation'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianRelation_2_Display
            }
            required={false}
          />
          <TextContainer
            title='Guardian Name'
            data={
              applicant?.applicantProgramDisplay &&
              applicant?.applicantProgramDisplay[0]?.guardianName_2
            }
            required={false}
          />
          <TextContainer
            title='Mobile'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianMobile_2
            }
            required={false}
            mobile={true}
          />
          <TextContainer
            title='Email'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.guardianEmail_2
            }
            required={false}
            email={true}
          />
        </div>
        {applicationData?.applicantDetails &&
        applicationData?.applicantDetails[0]?.legacyApplicant === true ? (
          <>
            <div>
              <TextContainer
                title="Father's Name"
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.legacyFatherName
                }
                required={false}
              />
              <TextContainer
                title='Program of Study at AUD'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]
                    ?.legacyFatherProgram_Display
                }
                required={false}
              />
              <TextContainer
                title='Graduation Year'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]
                    ?.legacyFatherGraduationYear
                }
                required={false}
              />
              <TextContainer
                title='Mobile'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.legacyFatherMobile
                }
                required={false}
                mobile={true}
              />
            </div>
            <div>
              <TextContainer
                title="Mother's Name"
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.legacyMotherName
                }
                required={false}
              />
              <TextContainer
                title='Program of Study at AUD'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]
                    ?.legacyMotherProgram_Display
                }
                required={false}
              />
              <TextContainer
                title='Graduation Year'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]
                    ?.legacyMotherGraduationYear
                }
                required={false}
              />
              <TextContainer
                title='Mobile'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.legacyMotherMobile
                }
                required={false}
                mobile={true}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default ParentOfGuardian;
