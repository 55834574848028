import React from "react";
import styles from "./TableTopHeader.module.css";
import SearchInput from "../../Atoms/Inputs/SearchInput";
import MoreButton from "../Custom/PopOver/MoreButton";
import moreicon from "../../../assets/Imagess/moreicon.svg";
import exportICon from "../../../assets/Imagess/exportIcon.svg";
import printicon from "../../../assets/Imagess/printIcon.svg";
import PopOver from "../Custom/PopOver/PopOver";
const TableTopHeader = ({
  rightButtons,
  search,
  setSearch,
  handleExport,
  isSearch,
  leftComponent,
  additionalButtons,
  handlePrint = null,
}) => {
  return (
    <>
      <div className={styles["icon-container"]}>
        <div className={styles["icon-subcontainer"]}>
          {isSearch && <SearchInput search={search} setSearch={setSearch} />}
          {leftComponent && leftComponent}
        </div>

        <div className={styles["icon-subcontainer"]}>
          <div className={styles["multi_icon_cont"]}>
            {additionalButtons && additionalButtons}
          </div>
          <div className={styles["iconFilter-subcontainer"]}>
            {rightButtons}

            <MoreButton icon={moreicon} toolTipText='More'>
              <PopOver
              stop
                info={[
                  {
                    title: "Export",
                    icon: exportICon,
                    handleFunction: handleExport,
                  },
                  {
                    title: "Print",
                    icon: printicon,
                    handleFunction: handlePrint,
                  },
                ]}
              />
            </MoreButton>
          </div>
        </div>
      </div>
      {additionalButtons && (
        <div className={styles["mobile"]}>{additionalButtons}</div>
      )}
    </>
  );
};

export default TableTopHeader;
