import React from "react";
import moment from "moment";
import StatusContainer from "../Custom/StatusContainer";
import styles from "./TableComponent.module.css";
import recruitmentIcon from "../../../assets/Imagess/Users/recruitmentIcon.svg";

export const categorizeApplications = (applicationString) => {
  const applicationList = applicationString?.split(",").map(Number);
  const uniqueCategories = [...new Set(applicationList)];
  const categoryMap = {
    0: "Undergraduate",
    1: "Graduate",
    2: "Visiting",
  };
  return (
    uniqueCategories.map((cat) => categoryMap[cat]).join(", ") || "Unknown"
  );
};

export const activeFuntion = (active) => (active ? "Yes" : "No");

export const formatFunction = (flag, bodyData) => {
  const formattedData = bodyData === null || bodyData === undefined || bodyData === "" ? "-" : bodyData;

  if (flag === "status") {
    return <StatusContainer status={formattedData} />;
  } else if (flag === "date") {
    if (!formattedData || formattedData === "-") return "-";
    return moment(formattedData).format("MMM D");
  } else if (flag === "dateTime") {
    if (!formattedData || formattedData === "-") return "-";
    return moment(formattedData).format("MMM D, YYYY h:mm A");
  } else if (flag === "appType") {
    return categorizeApplications(formattedData);
  } else if (flag === "mobile") {
    return "+" + formattedData;
  } else if (flag === "link") {
    return (
      <a
        href={formattedData}
        target="blank"
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          color: "#1b224c",
        }}
      >
        {formattedData}
      </a>
    );
  } else if (flag === "bolean") {
    return activeFuntion(formattedData);
  } else {
    return formattedData;
  }
};
