import React from "react";
import styles from "./StatusContainer.module.css";
import UnavailableIcon from "../../../assets/Imagess/notAvailableIcon.svg";
const EmptyText = ({ text }) => {
  return (
    <span className={styles["unfilled-text"]}>
      Empty {text} <img src={UnavailableIcon} alt='Unavailable' />
    </span>
  );
};

export default EmptyText;
