import React, { useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./EmailWorkflow.module.css";
import moment from "moment";
import EmailPreview from "./EmailPreview";
const UserReceiver = ({ users, selectedUserId, setSelectedUserId }) => {
  const [email, setEmail] = useState("");
  return (
    <div className={styles["container"]}>
      <div className={styles["subContainer"]}>
        <h1> {users?.length} User Receiver</h1>
        <div style={{ paddingTop: "8px" }}>
          {users?.map((item, index) => {
            return (
              <div
                key={index}
                className={`${styles["title_container"]} ${
                  index % 2 !== 0 ? styles["even"] : ""
                } ${item.id === selectedUserId ? styles["active"] : ""}`}
                onClick={() => setEmail(item.emailContent)}
              >
                <h2>{item.fullName}</h2>
                <h4>{moment(item.completedOn).format("MMM D, YYYY h:mm A")}</h4>
              </div>
            );
          })}
        </div>
      </div>

      <EmailPreview email={email} />
    </div>
  );
};

export default UserReceiver;
