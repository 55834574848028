import React from "react";
import Modal from "../../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../../Atoms/Modal/ModalChildrenContainer";
import DateContainer from "../../../Custom/DateContainer/DateContainer";
import HorizantalLine from "../../../../Atoms/Lines/HorizantalLine";
import styles from "./EmailsREceivedContainer.module.css";
import profile from "../../../../../assets/Imagess/profileIcon.svg";
import moment from "moment";
import EmailSent from "../../../Applicants/ApplicantLogs/EmailSent";
const ReceivedEmailPopup = ({ selectedEmail, handleOnClose }) => {
  return (
    <Modal onClose={handleOnClose}>
      <ModalChildrenContainer>
        <div className={styles["title_cont"]}>
          <img src={profile} alt='profile image' className={styles["image"]} />
          <div className={styles["title_subCont"]}>
            <h3>From:</h3>
            <p>{selectedEmail.from}</p>
            <DateContainer
              label='Date'
              date={moment(selectedEmail.dateSent).format("MMM D, YYYY h:mm A")}
            />
          </div>
        </div>

        <HorizantalLine />
        <div className={styles["logsEmail-cont"]}>
          <EmailSent selectedEmail={selectedEmail} />
        </div>
      </ModalChildrenContainer>
    </Modal>
  );
};

export default ReceivedEmailPopup;
