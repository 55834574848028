import React from "react";
import styles from "../Input.module.css";
const RadioButton = ({
  label,
  value,
  checked,
  handleOnChange,
  disabled,
  red,
}) => {
  return (
    <div className={`${styles["radio-input"]} ${red ? styles["red"] : ""}`}>
      <input
        type='radio'
        value={value}
        checked={checked}
        onChange={handleOnChange}
        disabled={disabled}
        style={{ cursor: disabled ? "not-allowed" : "pointer" }}
      />
      <div className={styles["circle"]}></div>
      <label
        style={{ fontSize: red ? "18px" : "", fontWeight: red ? "400" : "" }}
      >
        {label}
      </label>
    </div>
  );
};
export default RadioButton;
