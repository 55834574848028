import React from "react";
import Modal from "../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import styles from "./SettingsModa.module.css";
import CheckBox from "../../../Atoms/Inputs/CheckBox";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
const ViewTermModal = ({ handleOnClose, selectedItem, handleDelete,handleEdit}) => {
  return (
    <Modal width='30rem' onClose={handleOnClose} title='Term'>
      <ModalChildrenContainer>
        <div className={styles["terms-viewContainer"]}>
          <div className={styles["terms-titleContainer"]}>
            <h1>Name</h1>
            <p>{selectedItem?.termName}</p>
          </div>

          <div className={styles["date-container"]}>
            <div className={styles["terms-titleContainer"]}>
              <h1>Start Date</h1>
              <p>{selectedItem?.startDate?.substring(0, 10)}</p>
            </div>
            <div className={styles["terms-titleContainer"]}>
              <h1>End Date</h1>
              <p>{selectedItem?.endDate?.substring(0, 10)}</p>
            </div>
          </div>
          <h4 className={styles["application_title"]}>
            Application Submission period{" "}
          </h4>
          <div className={styles["date-container"]}>
            <div className={styles["terms-titleContainer"]}>
              <h1>Start Date</h1>
              <p>{selectedItem?.submissionStartDate?.substring(0, 10)}</p>
            </div>
            <div className={styles["terms-titleContainer"]}>
              <h1>End Date</h1>
              <p>{selectedItem?.submissionEndDate?.substring(0, 10)}</p>
            </div>
          </div>
          <div className={styles["subclinton-container"]}>
            <CheckBox
              text='Active Clinton Scholar'
              name='isClintonScholarTerm'
              disabled={true}
              onChange={(checked) => {}}
              value={selectedItem?.isClintonScholarTerm}
              required={true}
            />
          </div>
          {selectedItem?.isClintonScholarTerm === true ? (
            <div className={styles["terms-titleContainer"]}>
              <h1>Clinton Application Deadline</h1>
              <p>{selectedItem?.clintonScholarDeadline?.substring(0, 10)}</p>
            </div>
          ) : (
            ""
          )}
        </div>
        <div className={styles["buttonContainer"]}>
          <ColoredButton blue title='Edit' handleOnClick={handleEdit} />
          <ColoredButton unfilled title='Delete' handleOnClick={handleDelete} />
        </div>
      </ModalChildrenContainer>
    </Modal>
  );
};

export default ViewTermModal;
