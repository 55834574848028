import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const ProgramInformation = ({ applicant, applicationData }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Program Information </h3>
      <div className={styles["personal-container2"]}>
        <TextContainer
          title="Start Your Application"
          data={applicant?.intersted?.applicationStart_Display}
          required={true}
        />
        <TextContainer
          title="Applying As"
          data={
            applicant?.applications &&
            applicant?.applications[0]?.applyingAsText
          }
          required={true}
        />
        <TextContainer
          title="Program of Interest"
          data={
            applicant?.applications &&
            applicant?.applications[0]?.programOfInterest_Display
          }
          required={true}
        />
        {applicant?.applications &&
          (applicant?.applications[0]?.applyingAs == 1 ||
            applicant?.applications[0]?.applyingAs == 5) && (
            <TextContainer
              title="Your Current Place of Study"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.currentPlaceOfStudy
              }
              required={true}
            />
          )}
        <TextContainer
          title="Term"
          data={applicant?.applications && applicant?.applications[0]?.termName}
          required={true}
        />
      </div>
    </div>
  );
};

export default ProgramInformation;
