import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import ReactDOM from "react-dom";
import styles from "./Input.module.css";
import dropdownicon from "../../../assets/Imagess/dropdownicon.svg";
import StatusContainer from "../../Moecules/Custom/StatusContainer";

const CustomizedSelect = ({
  options,
  value,
  onChange,
  placeholder,
  icon,
  noborder,
  label,
  status,
  errors,
  touched,
  name,
  required,
  grey,
  disabled,
  height,
  notAbsolute,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedText, setSelectedText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);
  const [dropdownStyle, setDropdownStyle] = useState({});

  const handleOpenDropDowOptions = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelect = (e, selectedValue) => {
    e.stopPropagation();
    setSelectedValue(selectedValue);
    setSelectedText(selectedValue?.text);
    if (name) {
      onChange(name, selectedValue.value);
    } else {
      onChange(selectedValue);
    }
    setIsOpen(false);
  };
  const handleToggleMenu = (e) => {
    if (options && !options.length) {
      return false;
    }
    e.stopPropagation();
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "";
  };

  useEffect(() => {
    if (options && options.length > 0) {
      if (typeof value === "object") {
        setSelectedText(value.text || "");
      } else {
        const selectedOption = options.find((option) => option.value === value);
        setSelectedText(selectedOption ? selectedOption.text : "");
      }
    }
  }, [value, options]);

  useLayoutEffect(() => {
    const handleDocumentClick = (e) => {
      if (selectRef.current && !selectRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    const updateDropdownStyle = () => {
      if (isOpen && selectRef.current) {
        const rect = selectRef.current.getBoundingClientRect();
        setDropdownStyle({
          top: rect.bottom + window.scrollY,
          left: rect.left + window.scrollX,
          width: rect.width,
        });
      }
    };

    const handleWindowScroll = () => {
      updateDropdownStyle();
    };

    document.addEventListener("click", handleDocumentClick);
    window.addEventListener("scroll", handleWindowScroll);
    window.addEventListener("resize", handleWindowScroll);

    updateDropdownStyle();

    return () => {
      document.removeEventListener("click", handleDocumentClick);
      window.removeEventListener("scroll", handleWindowScroll);
      window.removeEventListener("resize", handleWindowScroll);
    };
  }, [isOpen]);

  const renderDropdown = () => (
    <>
      <div className={`${styles.backdrop}`} onClick={handleToggleMenu}></div>
      <div className={styles.dropdown} style={dropdownStyle} ref={dropdownRef}>
        {options && options.length > 0 ? (
          options.map((option, index) => (
            <div
              key={option.value}
              className={
                index % 2 === 0 ? styles.dropdownItem : styles.dropdownItemOdd
              }
              onClick={(e) => handleSelect(e, option)}
            >
              {status ? (
                <StatusContainer status={option?.text} />
              ) : (
                <div className={styles.dropdowncontainer}>{option?.text}</div>
              )}
            </div>
          ))
        ) : (
          <div className={styles.dropdownItemOdd}>No available data</div>
        )}
      </div>
    </>
  );

  return (
    <div className={styles.selectContainer}>
      {label && (
        <label>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      )}
      <div
        ref={selectRef}
        className={`${
          noborder
            ? styles.selectInpuNoBorders
            : errors && touched
            ? styles.error
            : styles.selectInput
        } ${isOpen ? styles.open : ""} `}
        onClick={handleOpenDropDowOptions}
        style={{
          border: grey ? "1px solid #1b224c3b" : "",
          height: height ? height : "",
        }}
      >
        <div className={styles.selectedValueicont}>
          <span
            className={`${styles.placeholder} ${
              noborder ? styles.ellipsedPlaceholder : ""
            }`}
          >
            {icon && <img src={icon} alt='icon' />}
            {selectedText ? <span>{selectedText}</span> : placeholder}
          </span>
          {!disabled && (
            <img
              src={dropdownicon}
              alt='dropdown icon'
              className={styles.arrowIcon}
            />
          )}
        </div>
      </div>
      {isOpen && ReactDOM.createPortal(renderDropdown(), document.body)}
    </div>
  );
};

export default CustomizedSelect;
