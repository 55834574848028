import React, { useState, useEffect, useRef } from "react";
import styles from "./Input.module.css";

const DateInput = ({
  label,
  required,
  value,
  onChange,
  width,
  name,
  errors,
  touched,
  startDate,
  disabled,
}) => {
  const [initialValue, setInitialValue] = useState(value);
  const dateRef = useRef();

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInitialValue(newValue);
    if (onChange) {
      onChange(name, newValue);
    }
  };

  const handleFileInputClick = () => {
    if (!disabled) {
      dateRef.current.showPicker();
    }
  };

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  return (
    <div className={styles["textBox-container"]}>
      <label htmlFor={label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>
      <div
        id='dateInput'
        className={`${
          errors && touched
            ? styles["custom-date-input-red"]
            : styles["custom-date-input"]
        }  ${touched ? styles["value"] : ""}`}
        style={{ width: width }}
        onClick={handleFileInputClick}
      >
        <input
          type='date'
          id={label}
          required={required}
          onChange={handleChange}
          value={initialValue}
          min={startDate}
          disabled={disabled}
          className={`${styles["date-input"]} ${value ? styles["value"] : ""}`}
          ref={dateRef}
        />
        <span
          className={`${styles["custom-date-icon"]} ${
            value ? styles["value"] : ""
          }`}
        ></span>
      </div>
    </div>
  );
};

export default DateInput;
