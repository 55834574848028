import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//fetch refenrence data
const fetchReferenceById = async (id) => {
  if (id) {
    return await axios.get(API_URL + `/api/Referance/GetReferenceById/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};

export const useFEtchReferenceById = (id) => {
  return useQuery({
    queryKey: ["referenceById", id],
    queryFn: () => fetchReferenceById(id),
    enabled: true,
  });
};

//post reference
const postReference = (values) => {
  return axios.post(API_URL + "/api/Referance/PostReferance", values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const usePostReferance = () => {
  return useMutation(postReference);
};




const fetchDropdownbyTypes = async (typeId) => {
  if (typeId) {
    return await axios.get(API_URL + `/api/Setting/GetDrowDown/${typeId}`);
  }
};

export const useFetchDropDownbyType = (typeId) => {
  return useQuery({
    queryKey: ["Types", typeId],
    queryFn: () => fetchDropdownbyTypes(typeId),
    enabled: true,
  });
};

