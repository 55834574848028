import React, { useEffect, useState } from "react";
import { Handle, Position } from "reactflow";
import conditionIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/conditionIconNode.svg";
import styles from "./DiamonBox.module.css";
import AdditionalList from "./AdditionalList";
import Tooltip from "../../../../../Atoms/Tooltip/Tooltip";
import {
  useGetFieldOption,
  useGetValueAPI,
} from "../../../../../../Hooks/Audience";

const handleCommonStyle = {
  backgroundColor: "red",
  width: "11px",
  height: "11px",
};

const DiamondBox = ({
  isLastSourceNode,
  onAddNode,
  data,
  id,
  xPos,
  yPos,
  readOnly,
  ...props
}) => {
  const [valueApiState, setValueApiState] = useState({});
  const [filteredValues, setFilterValues] = useState([]);
  const [valueLabel, setValueLabel] = useState("");

  const { data: fieldsOptionsById, refetch: refetchFieldsOptionsById } =
    useGetFieldOption(0);
  const {
    data: values,
    refetch: refetchValues,
    isLoading: isValuesLoading,
  } = useGetValueAPI(filteredValues && filteredValues[0]?.valueAPI);

  useEffect(() => {
    if (fieldsOptionsById && data.label.field) {
      setFilterValues(
        fieldsOptionsById?.data?.filter(
          (item) => item.audienceOptionId === data.label.field
        )
      );
    }
  }, [fieldsOptionsById, data.label.field]);

  useEffect(() => {
    if (values && data.label.value) {
      const matchedValue = values?.data?.find(
        (item) => item.ddid == data.label.value
      );
      setValueLabel(matchedValue ? matchedValue.text : "");
    }
  }, [values, data.label.value]);


  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        id='a'
        style={handleCommonStyle}
      />
      <div className={styles.bigger_div}>
        <div
          className={`${styles["diamond"]} ${
            props.selected ? styles.selected : ""
          }`}
        >
          <div className={styles.diamond_inner_container}>
            <img src={conditionIcon} alt='Condition Icon' />
            <h2>Condition</h2>
            <h4>{valueLabel ? valueLabel : "Configure"} </h4>
          </div>
        </div>
        <div className={styles.line}></div>

        <div className={`${styles.circle} ${styles.left}`}>
          {" "}
          <Tooltip content={data?.label?.conditionOne || "Yes"}>Yes </Tooltip>
        </div>

        <div className={`${styles.line_circle} ${styles.left}`}></div>
        <div className={`${styles.vertical_line} ${styles.left}`}></div>

        <div className={`${styles.circle} ${styles.right}`}>
          {" "}
          <Tooltip content={data?.label?.conditionTwo || "No"}>No </Tooltip>
        </div>

        <div className={`${styles.line_circle} ${styles.right}`}></div>
        <div className={`${styles.vertical_line} ${styles.right}`}></div>

        {!readOnly
          ? isLastSourceNode(id, "left", xPos, yPos) && (
              <div className={styles.plusLeft}>
                <AdditionalList
                  onAddNode={(type, action) =>
                    onAddNode(type, action, "left", id, xPos, yPos)
                  }
                />
              </div>
            )
          : ""}
        {!readOnly
          ? isLastSourceNode(id, "right", xPos, yPos) && (
              <div className={styles.plusRight}>
                <AdditionalList
                  onAddNode={(type, action) =>
                    onAddNode(type, action, "right", id, xPos, yPos)
                  }
                />
              </div>
            )
          : ""}
      </div>
      {(readOnly || !isLastSourceNode(id, "left", xPos, yPos)) && (
        <Handle
          type='source'
          position={Position.Left}
          id='left'
          style={{
            ...handleCommonStyle,
            marginTop: "12px",
            marginLeft: "-90px",
          }}
        />
      )}{" "}
      {(readOnly || !isLastSourceNode(id, "right", xPos, yPos)) && (
        <Handle
          type='source'
          position={Position.Right}
          id='right'
          style={{
            ...handleCommonStyle,
            marginTop: "12px",
            marginRight: "-90px",
          }}
        />
      )}
    </>
  );
};

export default DiamondBox;
