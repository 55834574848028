import React from "react";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";

const CampaignContainer = ({ step }) => {
  return (
    <WhiteContainer center={step.step == 0 ? true : false} noPadding>
      {step.content}
    </WhiteContainer>
  );
};

export default CampaignContainer;
