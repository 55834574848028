import React, { useRef } from "react";
import {
  useExportAcademicYearsOptions,
  useExportDropDownOptionsTable,
  useExportTrmsOptions,
} from "../../../../Hooks/Settings";
import PopOver from "../../Custom/PopOver/PopOver";
import MoreButton from "../../Custom/PopOver/MoreButton";
import moreicon from "../../../../assets/Imagess/moreicon.svg";
import exportICon from "../../../../assets/Imagess/exportIcon.svg";
import printicon from "../../../../assets/Imagess/printIcon.svg";
import { useExportExternalContact } from "../../../../Hooks/Externalcontact";
import { useExportEmailSignature } from "../../../../Hooks/ESignature";
const ExportPrintComponent = ({
  type,
  title,
  handleExport = null,
  handlePrint,
}) => {
  const exportDropDownOptionsTable = useExportDropDownOptionsTable(type, title);
  const exportTermsTable = useExportTrmsOptions();
  const exportAcademicYears = useExportAcademicYearsOptions();
  const exportExternalcontact = useExportExternalContact();
  const exportESignature = useExportEmailSignature();

  const handleExportDropDownOptions = async () => {
    try {
      let data;
      if (type === 19) {
        data = await exportTermsTable.mutateAsync();
      } else if (type === 24) {
        data = await exportESignature.mutateAsync();
      } else if (type === 21) {
        data = await exportExternalcontact.mutateAsync();
      } else if (type === 20) {
        data = await exportAcademicYears.mutateAsync();
      } else {
        data = await exportDropDownOptionsTable.mutateAsync();
      }

      if (!data) {
        console.error("No data received.");
        return;
      }

      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `${title}Table.xlsx`;
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected Blob.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <>
      {" "}
      <MoreButton icon={moreicon} toolTipText='More'>
        <PopOver
          stop
          info={[
            {
              title: "Export",
              icon: exportICon,
              handleFunction: handleExport
                ? handleExport
                : handleExportDropDownOptions,
            },
            {
              title: "Print",
              icon: printicon,
              handleFunction: handlePrint,
            },
          ]}
        />
      </MoreButton>
    </>
  );
};

export default ExportPrintComponent;
