export const pathConfig = {
  applicants: { title: "All Applicants", isArrow: false },
  users: { title: "Users", isArrow: false },
  settings: { title: "Settings", isArrow: false },
  dashboard: { title: "Dashboard", isArrow: false },
  "users-assignment": { title: "Users Assignment", isArrow: false },
  email: { title: "Email", isArrow: false },
  "add-externalContact": { title: "Settings", isArrow: true },
  "edit-externalContact": { title: "Settings", isArrow: true },

  "new-email": { title: "Email Template", isArrow: true },
  campaigns: { title: "Campaigns", isArrow: false },
  "campaign-audience": { title: "Audience", isArrow: false },
  "campaign-templateEmail": {
    title: "Campaign Email Template",
    isArrow: false,
  },
  "campaign-details": { title: "Campaigns", isArrow: true },
  "contact-details": { title: "Contact Details", isArrow: true },
  "new-audience": { title: "Audience", isArrow: true },
  audienceDetails: { title: "Audience", isArrow: true },

  "new-campaign": { title: "Campaign Management", isArrow: true },
  default: { title: "My Profile", isArrow: false },
};
