import React from "react";
import Modal from "../../../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../../../Atoms/Modal/ModalChildrenContainer";
const EmailWorkFlowPopup = ({ handleOnClose, content, button }) => {
  return (
    <Modal onClose={handleOnClose}>
      <ModalChildrenContainer>
        {content}
        {button}
      </ModalChildrenContainer>
    </Modal>
  );
};

export default EmailWorkFlowPopup;
