import React, { useEffect, useState } from "react";
import gradeIcon from "../../../assets/Imagess/Contact/gradeIcon.svg";
import gpaIcon from "../../../assets/Imagess/Contact/gpaIcon.svg";
import houseicon from "../../../assets/Imagess/Contact/houseIcon.svg";
import blockIcon from "../../../assets/Imagess/Campaign/blockIcon.svg";
import unblockICon from "../../../assets/Imagess/Campaign/unblockIcon.svg";
import campaignsIncludedIcon from "../../../assets/Imagess/Contact/campaignsIncludedIcon.svg";
import receivedEmailIcon from "../../../assets/Imagess/Contact/campaignemailReceived.svg";
import SingleApplicantTopHeader from "../../Moecules/SingleApplicant/SingleApplicantTopHeader";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import SingleTabGroup from "../../Moecules/SingleApplicant/SingleTabGroup";
import CampaignsIncluded from "../../Moecules/Settings/ContactDetails/CampaignsIncluded/CampaignsIncluded";
import EmailsReceivedContainer from "../../Moecules/Settings/ContactDetails/EmailsReceived/EmailsReceivedContainer";
import ContactLogs from "../../Moecules/Settings/ContactDetails/ContactLogs/ContactLogs";
import DeletePopup from "../../Moecules/Custom/DeletePopup";
import { useParams } from "react-router-dom";
import {
  useBlockUnblockExternalContact,
  useFetchExternalContactById,
} from "../../../Hooks/Externalcontact";
import { toast } from "react-toastify";
import Loader from "../../Moecules/Loader/Loader";

const ExternalContactPage = () => {
  const params = useParams();
  const { id } = params;

  const [selected, setSelected] = useState(0);
  const [isBlock, setIsBlock] = useState(false);

  const {
    data: contactDetails,
    refetch,
    isLoading: isLoadingExternalContactById,
  } = useFetchExternalContactById({ id });
  const data = contactDetails?.data;

  const { mutate: blockUnblock, isLoading: isBlockingContact } =
    useBlockUnblockExternalContact();

  const bottomLeftComponent = [
    {
      icon: houseicon,
      text: data?.externalContact?.schoolName,
    },

    ...(data?.externalContact?.contactTypeName == "School Student"
      ? [
          {
            icon: gradeIcon,
            text: "Grade " + data?.externalContact?.gradeName,
          },
          {
            icon: gpaIcon,
            text: "GPA " + data?.externalContact?.highSchoolGPA,
          },
        ]
      : []),
  ];

  const tabContainer = [
    {
      text: "Campaigns Included",
      value: 0,
      icon: campaignsIncludedIcon,
    },
    {
      text: "Campaign Email Received",
      value: 1,
      icon: receivedEmailIcon,
    },
    // {
    //   text: "Contact Log",
    //   value: 2,
    //   icon: ContactLogIcon,
    // },
  ];

  const handleBlockUnBLock = () => {
    blockUnblock(
      {
        contactId: data?.externalContact?.externalContactId,
        isBlock: !data?.externalContact?.isBlocked,
      },
      {
        onSuccess: () => {
          refetch();
          toast.success(
            data?.externalContact?.isBlocked
              ? "Unblocked Contact"
              : "Blocked Contact"
          );
          setIsBlock(false);
        },
      }
    );
  };

  useEffect(() => {
    refetch({ id });
  }, [id]);

  return (
    <>
      <WrappingContainer>
        <SingleApplicantTopHeader
          bottomLeftComponent={bottomLeftComponent}
          rightComponent={{
            icon: data?.externalContact?.isBlocked ? unblockICon : blockIcon,
            text: data?.externalContact?.isBlocked
              ? "Unblock User"
              : "Block User",
            handleFunction: () => setIsBlock(!isBlock),
          }}
          data={{
            email: data?.externalContact?.email,
            firstName: data?.externalContact?.contactName,
            mobile: data?.externalContact?.mobile,
          }}
          isLoading={isLoadingExternalContactById}
          isStudent={data?.externalContact?.contactTypeName == "School Student"}
        />

        <SingleTabGroup
          tabContainer={tabContainer}
          selected={selected}
          setSelected={setSelected}
        />
        {isLoadingExternalContactById ? (
          <Loader />
        ) : selected === 0 ? (
          <CampaignsIncluded data={data?.campaigns} />
        ) : selected === 1 ? (
          <EmailsReceivedContainer notifications={data?.notifications} />
        ) : (
          <ContactLogs />
        )}
      </WrappingContainer>
      {isBlock && (
        <DeletePopup
          handleOnClose={() => setIsBlock(!isBlock)}
          mode={data?.externalContact?.isBlocked ? "Unblock" : "Block"}
          handleDelete={handleBlockUnBLock}
          text={
            data?.externalContact?.isBlocked
              ? "Are you sure you want to Unblock this"
              : "Are you sure you want to block this"
          }
          title='user'
          subText={
            data?.externalContact?.isBlocked
              ? "User will be included in any campaign."
              : "User will not be included in any campaign."
          }
          isLoading={isBlockingContact}
        />
      )}
    </>
  );
};

export default ExternalContactPage;
