import React from "react";
import styles from "./onlineForm.module.css";
import attachment from "../../../../assets/Imagess/attachmentIcon.svg";
import availableIcon from "../../../../assets/Imagess/availableICon.svg";
import UnavailableIcon from "../../../../assets/Imagess/notAvailableIcon.svg";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const AcademicTest = ({ applicant, applicationData }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Academic IELTS/TOEFL/EMSAT/PTEScore/SAT </h3>

      <div className={styles["personal-container2"]}>
        {applicationData?.applicantTests?.length > 0 ? (
          <>
            {" "}
            {applicationData?.applicantTests?.map((item, index) => {
              return (
                <div
                  key={item.applicantTestScore}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "5px",
                  }}
                >
                  <TextContainer
                    title={`Test ${index + 1}`}
                    data={item?.testType_Display}
                    required={true}
                  />
                  <TextContainer
                    title='Document'
                    data={item?.fileName}
                    required={true}
                    attachement={true}
                  />

                  <TextContainer
                    title='Date Taken'
                    data={item?.dateTaken.substring(0, 10)}
                    required={true}
                  />
                   <TextContainer
                    title='Registeration Number'
                    data={item?.registrationNumber}
                    required={true}
                  />
                   <TextContainer
                    title='Total Score'
                    data={item?.totalScore}
                    required={true}
                  />
                  <TextContainer
                    title='Registeration Number'
                    data={item?.registrationNumber}
                    required={true}
                  />
                </div>
              );
            })}
          </>
        ) : (
          <span className={styles["unfilled-text"]} style={{ color: "#F00" }}>
            Empty Test <img src={UnavailableIcon} alt='Unavailable' />
          </span>
        )}
      </div>
    </div>
  );
};

export default AcademicTest;
