import React from "react";
import { Handle, Position } from "reactflow";
import editIcon from "../../../../../../assets/Imagess/Edit (1).svg";
import styles from "./DiamonBox.module.css";
import AdditionalList from "./AdditionalList";

const handleStyle = { backgroundColor: "red", width: "11px", height: "11px" };

const StartNode = ({ id, isLastSourceNode, onAddNode, data, xPos, yPos ,...props}) => {


  return (
    <>
      <div  className={`${styles["edit_container"]} ${
          props.selected? styles.selected : ""
        }`}>
        <h2 className='nodrag'>Start </h2>
        <img src={editIcon} alt='editIcon' className='nodrag' />
      </div>
      {isLastSourceNode(id, null, xPos, yPos) ? (
        <AdditionalList
          onAddNode={(type, action) =>
            onAddNode(type, action, null, id, xPos, yPos)
          }
        />
      ) : (
        <Handle
          type='source'
          position={Position.Bottom}
          id='a'
          style={handleStyle}
        />
      )}
    </>
  );
};

export default StartNode;
