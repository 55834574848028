import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styles from "./Modal.module.css";
import closeICone from "../../../assets/Imagess/deleteIconPopup.svg";
import Draggable from "react-draggable";
import Tooltip from "../Tooltip/Tooltip";

const Modal = ({ children, onClose, title, width, height, isNotClose }) => {
  const draggableRef = useRef(null);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };

    window.addEventListener("keydown", handleEsc);

    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [onClose]);

  return ReactDOM.createPortal(
    <div className={styles["modal-container"]}>
      <div className={styles["modal-backdrop"]} onClick={onClose}></div>
      <Draggable handle='.handle' cancel='.cancel' nodeRef={draggableRef}>
        <div
          ref={draggableRef}
          className={`${styles["modal-innerContainer"]} handle`}
          style={{ width: width, height: height, cursor: "grab" }}
        >
          <div>
            <h2>{title}</h2>
            {isNotClose ? (
              ""
            ) : (
              <img
                src={closeICone}
                alt='close icon'
                className={styles["close-icon"]}
                onClick={onClose}
              />
            )}
          </div>
          <div
            className='cancel'
            style={{ cursor: "default", paddingTop: "5px" }}
          >
            {children}
          </div>
        </div>
      </Draggable>
    </div>,
    document.body
  );
};

export default Modal;
