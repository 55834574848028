import React, { useState } from "react";
import styles from "./TableSettings.module.css";
import editIcon from "../../../../assets/Imagess/Edit (1).svg";
import leftArrow from "../../../../assets/Imagess/downarrow.svg";
import deleteIcon from "../../../../assets/Imagess/deleteicon.svg";
import Loader from "../../Loader/Loader";
import moment from "moment";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";

const TableTerms = ({
  data,
  title,
  handleEditClick,
  setDeleteMode,
  setSelectedItem,
  isLoadingTerms,
  withBorders,
  orderDir,
  orderBy,
  setOrderBy,
  setOrderDir,
  refetch,
  setModalMode,
  setIsModalOpen,
}) => {
  const [rotatedColumn, setRotatedColumn] = useState(null);

  const isClintonFunction = (isClinton) => {
    return isClinton ? "Yes" : "No";
  };

  const handleOnViewMode = (e, item) => {
    e.stopPropagation();
    setModalMode("View");
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const tableClassNames = `${styles["table-container"]} ${
    withBorders ? styles["with-borders"] : ""
  }`;

  const orderByFunction = (item) => {
    const nameToUpperCase = item[0].toUpperCase() + item.slice(1);
    const newOrderDir = orderDir === "asc" ? "desc" : "asc";
    setOrderBy(nameToUpperCase);
    setOrderDir(newOrderDir);
    setRotatedColumn(item);

    refetch({
      orderDir: newOrderDir,
      orderBy: nameToUpperCase,
    });
  };

  if (!data || !data.data) {
    return <Loader />;
  }

  return (
    <div className={styles.container}>
      <table className={tableClassNames}>
        <thead>
          <tr>
            <th className={styles["green-separator"]}>
              Name
              <Tooltip content='Order By Name'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "termName" && orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "termName" && orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("termName")}
                />
              </Tooltip>
            </th>
            <th colSpan='2' className={styles["green-separator"]}>Term Dates</th>
            <th colSpan='2' className={styles["green-separator"]}>Application Submission Dates</th>
            <th colSpan='2' className={styles["green-separator"]}>Clinton</th>
            <th></th>
          </tr>
          <tr>
            <th className={styles["green-separator"]}></th>
            <th>
              From Date
              <Tooltip content='Sort By Term From Date'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "startDate" && orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "startDate" && orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("startDate")}
                />
              </Tooltip>
            </th>
            <th className={styles["green-separator"]}>
              Till Date
              <Tooltip content='Sort By Term End Date'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "endDate" && orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "endDate" && orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("endDate")}
                />
              </Tooltip>
            </th>
            <th>
              From Date
              <Tooltip content='Sort By Application Submission From Date'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "submissionStartDate" &&
                    orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "submissionStartDate" &&
                        orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("submissionStartDate")}
                />
              </Tooltip>
            </th>
            <th className={styles["green-separator"]}>
              Till Date
              <Tooltip content='Sort By Application Submission Till Date'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "submissionEndDate" && orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "submissionEndDate" &&
                        orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("submissionEndDate")}
                />
              </Tooltip>
            </th>
            <th>
              Scholar
              <Tooltip content='Sort By Clinton Schoolar'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "isClintonScholarTerm" &&
                    orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "isClintonScholarTerm" &&
                        orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("isClintonScholarTerm")}
                />
              </Tooltip>
            </th>
            <th className={styles["green-separator"]}>
              Deadline
              <Tooltip content='Sort By Clinton Deadline'>
                <img
                  src={leftArrow}
                  alt='icon'
                  className={`${styles["arrowIcon"]} ${
                    rotatedColumn === "clintonScholarDeadline" &&
                    orderDir === "asc"
                      ? styles["rotate-up"]
                      : rotatedColumn === "clintonScholarDeadline" &&
                        orderDir === "desc"
                      ? styles["rotate-down"]
                      : ""
                  }`}
                  onClick={() => orderByFunction("clintonScholarDeadline")}
                />
              </Tooltip>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.length > 0 ? (
            <>
              {data?.data?.map((item, index) => (
                <tr
                  key={item.value}
                  onClick={(e) => handleOnViewMode(e, item)}
                  style={{ cursor: "pointer" }}
                >
                  <td className={styles["green-separator"]}>{item.termName || "-"}</td>
                  <td>{moment(item.startDate).format("MMM D, YYYY") || "-"}</td>
                  <td className={styles["green-separator"]}>{moment(item.endDate).format("MMM D, YYYY") || "-"}</td>
                  <td>
                    {moment(item.submissionStartDate).format("MMM D, YYYY") ||
                      "-"}
                  </td>
                  <td className={styles["green-separator"]}>
                    {moment(item.submissionEndDate).format("MMM D, YYYY") ||
                      "-"}
                  </td>
                  <td>{isClintonFunction(item.isClintonScholarTerm) || "-"}</td>
                  <td className={styles["green-separator"]}>
                    {item.clintonScholarDeadline
                      ? moment(item.clintonScholarDeadline).format(
                          "MMM D, YYYY"
                        )
                      : ""}
                  </td>
                  <td className={styles["icon-cont-table"]}>
                    <Tooltip content='Edit'>
                      <img
                        src={editIcon}
                        onClick={(e) => (
                          handleEditClick(e, item), e.stopPropagation()
                        )}
                        alt='Edit Icon'
                      />
                    </Tooltip>
                    <Tooltip red content='Delete'>
                      <img
                        src={deleteIcon}
                        onClick={(e) => {
                          setDeleteMode(true);
                          setSelectedItem(item);
                          e.stopPropagation();
                        }}
                        alt='Delete Icon'
                      />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan='9'>No Data</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TableTerms;
