import React, { useState } from "react";
import MoreButton from "../Custom/PopOver/MoreButton";
import styles from "./Filter.module.css";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import Buttons from "../../Atoms/Buttons/Buttons";
import filter from "../../../assets/Imagess/filter.svg";
import emailICon from "../../../assets/Imagess/emaildropdownicon.svg";
import { useFetchEmailTypes } from "../../../Hooks/Notification";

const EmailFilter = ({ emailType, setEmailType }) => {
  const [emailTypeId, setEmailTypeID] = useState("");
  const { data: emailTypesData } = useFetchEmailTypes();

  const selectApplicantOptions = emailTypesData?.data?.map(
    ({ notificationTypeId, notificationType }) => ({
      value: notificationTypeId,
      text: notificationType,
    })
  );

  const handleReset = () => {
    setEmailType("");
    setEmailTypeID("");
  };
  const handleShowResult = () => {
    setEmailType(emailTypeId);
  };
  
  return (
    <MoreButton icon={filter} width='350px' toolTipText="Filter">
      <div className={styles["filter-container"]}>
        <h4> Filter</h4>
        <CustomizedSelect
          options={selectApplicantOptions}
          label='Email Type'
          icon={emailICon}
          placeholder='Select Type'
          value={emailTypeId}
          onChange={(selectedValue) => setEmailTypeID(selectedValue.value)}
        />
        <div className={styles["filterbutton-container"]}>
          <Buttons text='Reset Filter' handleOnClick={handleReset} />
          <Buttons
            text='Show Result'
            type='Submit'
            handleOnClick={handleShowResult}
          />
        </div>
      </div>
    </MoreButton>
  );
};

export default EmailFilter;
