import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//get campaign
const fetchCampaign = async (data) => {
  let url = `${API_URL}/api/Campaign/GetCampaigns`;
  const queryParams = [];

  if (data?.search) queryParams.push(`SearchBy=${data.search}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchCampaign = (data) => {
  return useQuery({
    queryKey: ["getCampaign", data],
    queryFn: () => fetchCampaign(data),
    enabled: true,
  });
};

//post stage1
const addCampaignstage1 = (data) => {
  return axios.post(API_URL + `/api/Campaign/CampaignStage1`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddCampaignstage1 = () => {
  return useMutation(addCampaignstage1);
};

//post stage2
const addCampaignstage2 = (data) => {
  return axios.post(API_URL + `/api/Campaign/CampaignStage2`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddCampaignstage2 = () => {
  return useMutation(addCampaignstage2);
};

//post stage3
const addCampaignstage3 = (data) => {
  return axios.post(API_URL + `/api/Campaign/CreateCampaignStage3`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddCampaignstage3 = () => {
  return useMutation(addCampaignstage3);
};

///get campaign by id

const fetchCampaignById = async (id) => {
  if (id) {
    return await axios.get(API_URL + `/api/Campaign/GetCampaignById/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};
export const useFetchCampaignById = (id) => {
  return useQuery({
    queryKey: ["getCampaignById", id],
    queryFn: () => fetchCampaignById(id),
    enabled: true,
  });
};

//stop campaign
const stopCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/StopCampaign?CampaignId=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useStopCampaign = () => {
  return useMutation(stopCampaign);
};

//onHold campaign
const onHoldCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/CampaignOnHold?CampaignId=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useOnHoldCampaign = () => {
  return useMutation(onHoldCampaign);
};

//launch campaign
const launchCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/LaunchCampaign/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useLaunchCampaign = () => {
  return useMutation(launchCampaign);
};

//duplicate campaign
const duplicateCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/DuplicateCampaign/${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useDuplicateCampaign = () => {
  return useMutation(duplicateCampaign);
};

//cancel campaign
const cancelCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/CancelCampaign?CampaignId=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useCancelCampaign = () => {
  return useMutation(cancelCampaign);
};

//delete campaign
const deleteCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/DeleteCampaign?CampaignId=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useDeleteCampaign = () => {
  return useMutation(deleteCampaign);
};

//resume campaign
const resumeCampaign = (id) => {
  if (id) {
    return axios.post(
      API_URL + `/api/Campaign/ResumeCampaign?CampaignId=${id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useResumeCampaign = () => {
  return useMutation(resumeCampaign);
};

////export campaign
const exportCampaign = async () => {
  return await axios.get(API_URL + `/api/Export/ExportCampaigns`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportCampaign = () => {
  return useMutation(() => exportCampaign());
};

//fetch node by id
const getNodeInformationbyId = (id) => {
  if (id) {
    return axios.get(API_URL + `/api/Campaign/GetNodeInformation/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};

export const useFetchNodeInformationbyId = (id) => {
  return useQuery({
    queryKey: ["nideInformation", id],
    queryFn: () => getNodeInformationbyId(id),
    enabled: true,
  });
};

////export campaign audience by id

const exportCampaignAudience = async (id) => {
  return await axios.post(
    `${API_URL}/api/Export/ExportCampaignAudience/${id}`,
    {},
    {
      responseType: "blob",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useExportCampaignAudience = () => {
  return useMutation(exportCampaignAudience);
};
