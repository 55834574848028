import React, { useRef, useState } from "react";
import styles from "./UserProfile.module.css";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import editIcon from "../../../assets/Imagess/editIconprofile.svg";
import editProfileIcon from "../../../assets/Imagess/profileImageEdit.png";
import passicon from "../../../assets/Imagess/ChangePasswordIcon.svg";
import USerProfileIcon from "../../../assets/Imagess/usreprofileicon.svg";
import HorizantalLine from "../../Atoms/Lines/HorizantalLine";
import signout from "../../../assets/Imagess/signout.svg";
import UserPasswordDetails from "../../Moecules/UserProfile/UserPasswordDetails";
import UserPersonalDetails from "../../Moecules/UserProfile/UserPersonalDetails";
import ChangePAsswordModal from "../../Moecules/UserProfile/ChangePAsswordModal";
import { useAuthenticatedUser } from "../../../Hooks/Profile";
import Loader from "../../Moecules/Loader/Loader";
import TitleImageComponent from "../../Moecules/Custom/Title_SubTitle/TitleImageComponent";
import email from "../../../assets/Imagess/emailicon.svg";
import phone from "../../../assets/Imagess/phoneicon.svg";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import WhiteBoxContainer from "../../HOC/WrappingContainer/WhiteBoxContainer";
import UserEditProfilePopup from "../../Moecules/UserProfile/UserEditProfilePopup";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUploadProfileImage } from "../../../Hooks/Users";
const UserProfile = () => {
  const editRef = useRef();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [editMode, setEditMode] = useState(false);
  const [editMobilMode, setEditMobilMode] = useState(false);
  const [isChangePass, setISChangePass] = useState(false);

  const {
    data: authenticatedUser,
    isLoading: isLoadingAuthenticatedUser,
    refetch: refetchUser,
  } = useAuthenticatedUser();
  const userDetails = authenticatedUser?.data;

  const { mutate: uploadProfileImage, isLoading: isUploading } =
    useUploadProfileImage();
  const handleUpdate = async () => {
    setEditMode(false);

    await editRef.current.submitForm();
  };
  const handleSignOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        const formData = new FormData();
        formData.append("file", file);
        uploadProfileImage(formData, {
          onSuccess: () => {
            toast.success("Image uplaoded succcessfully");
            refetchUser();
          },
        });
      } else {
        toast.error("File size exceeds the maximum limit of 5MB.");
        event.target.value = null;
      }
    }
  };
  const handleSelectImage = () => {
    fileInputRef.current.click();
  };

  const emailText = (
    <div className={styles["title-container"]}>
      <h3>
        {userDetails?.profile && userDetails?.profile[0]?.firstNameEn}{" "}
        {userDetails?.profile && userDetails?.profile[0]?.lastNameEn}
      </h3>
      <TitleImageComponent
        text={userDetails?.profile && userDetails?.profile[0]?.email}
        icon={email}
      />
      <TitleImageComponent
        text={userDetails?.profile && userDetails?.profile[0]?.mobile}
        icon={phone}
      />
    </div>
  );
  const buttons = <></>;

  if (isLoadingAuthenticatedUser) {
    return <Loader />;
  }

  return (
    <>
      <WrappingContainer>
        <div className={styles["first-container"]}>
          {isUploading ? (
            <Loader />
          ) : (
            <div className={styles["parent"]}>
              {userDetails?.userImage?.imageContent ? (
                <img
                  src={`data:image/jpeg;base64,${userDetails?.userImage?.imageContent}`}
                  className={styles["user-image"]}
                />
              ) : (
                <img
                  src={USerProfileIcon}
                  alt='icon'
                  className={styles["user-image"]}
                />
              )}
              <input
                type='file'
                accept='image/*'
                style={{ display: "none" }}
                ref={fileInputRef}
                onChange={handleImageChange}
              />
              <img
                src={editProfileIcon}
                alt='icon'
                className={styles["child"]}
                onClick={handleSelectImage}
              />
            </div>
          )}

          <div className={styles["emailMobile"]}>{emailText}</div>
          <div className={styles["subcontainer"]}>
            <div className={styles["title-container"]}>
              <h3>
                {userDetails?.profile && userDetails?.profile[0]?.firstNameEn}{" "}
                {userDetails?.profile && userDetails?.profile[0]?.lastNameEn}
              </h3>
              {editMode ? (
                <div>
                  <div
                    className={styles["edit-title"]}
                    onClick={() => setISChangePass(true)}
                  >
                    <img src={passicon} className='icon-img' alt='icon' />
                    Change Password
                  </div>{" "}
                  <div className={styles["edit-title"]} onClick={handleUpdate}>
                    <img src={editIcon} className='icon-img' alt='icon' />
                    Update Profile
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => setEditMode(true)}
                  className={styles["edit-title"]}
                >
                  <img src={editIcon} className='icon-img' alt='icon' />
                  Edit Profile
                </div>
              )}
            </div>

            <HorizantalLine />
            <div className={styles["details-subcontainer"]}>
              <h4>Role</h4>
              <h5>
                {userDetails?.profile && userDetails?.profile[0]?.roleName}
              </h5>
            </div>
          </div>
        </div>
        <div className={styles["mobile_role_container"]}>
          <WhiteBoxContainer>
            <div className={styles["details-subcontainer"]}>
              <h4>Role</h4>
              <h5>
                {userDetails?.profile && userDetails?.profile[0]?.roleName}
              </h5>
            </div>
            <HorizantalLine />
            <TitleImageComponent
              text='Change Password'
              icon={passicon}
              color='#F3223C'
              fontWeight='600'
              onClick={() => setISChangePass(true)}
            />
            <TitleImageComponent
              text='Update Profile'
              icon={editIcon}
              color='#F3223C'
              fontWeight='600'
              onClick={() => setEditMobilMode(true)}
            />
            <TitleImageComponent
              text='Sign Out'
              icon={signout}
              fontWeight='600'
              onClick={handleSignOut}
            />
          </WhiteBoxContainer>
        </div>
        <div className={styles["sub-container"]}>
          <UserPersonalDetails
            editMode={!editMode}
            userDetails={userDetails?.profile && userDetails?.profile[0]}
            refetchUser={refetchUser}
            ref={editRef}
          />
          {/* <UserPasswordDetails /> */}
        </div>
        {isChangePass ? (
          <ChangePAsswordModal
            handleOnClose={() => setISChangePass(false)}
            refetch={refetchUser}
          />
        ) : (
          ""
        )}
      </WrappingContainer>
      {editMobilMode && (
        <UserEditProfilePopup
          handleOnClose={() => setEditMobilMode(false)}
          userDetails={userDetails}
          refetchUser={refetchUser}
          setEditMode={setEditMobilMode}
          handleImageChange={handleImageChange}
          handleSelectImage={handleSelectImage}
          fileInputRef={fileInputRef}
        />
      )}
    </>
  );
};

export default UserProfile;
