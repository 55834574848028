import React, { useEffect, useState } from "react";
import WhiteContainer from "../../../../../HOC/WrappingContainer/WhiteContainer";
import LPCQuery from "../../../../Custom/QueryComponent/LeadsProspectContactQuery.jsx/LPCQuery";
import styles from "./AudienceList.module.css";
import { useFetchAudienceById } from "../../../../../../Hooks/Audience";
import { useFormikContext } from "formik";
import Loader from "../../../../Loader/Loader";
const FilterApplied = () => {
  const formik = useFormikContext();
  const {
    data: audienceByIdData,
    isLoading: isAudienceLoading,
    refetch,
  } = useFetchAudienceById(formik.values.audienceId);

  const [queryState, setQueryState] = useState({
    0: [],
    1: [],
    2: [],
  });

  useEffect(() => {
    refetch(formik.values.audienceId);
  }, [formik.values.audienceId]);

  useEffect(() => {
    if (formik.values.audienceId) {
      setQueryState({
        0: audienceByIdData?.data?.audienceDetails
          ?.filter((item) => item.audienceType == 0)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
        1: audienceByIdData?.data?.audienceDetails
          ?.filter((item) => item.audienceType == 1)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
        2: audienceByIdData?.data?.audienceDetails
          ?.filter((item) => item.audienceType == 2)
          ?.map((item) => {
            return {
              andOr: item.andOr == 0 ? false : true,
              audienceOptionId: item.optionId,
              inNotIn: item.operatorId == 0 ? false : true,
              valueIds: item.valueId,
            };
          }),
      });
    }
  }, [audienceByIdData]);
  return (
    <WhiteContainer white maxHeight>
      <p className={styles["title_filter"]}>Filter Applied</p>

      {isAudienceLoading ? (
        <Loader />
      ) : audienceByIdData?.data?.audienceDetails.length > 0 ? (
        <LPCQuery
          disabled
          color
          queryState={queryState}
          setQueryState={setQueryState}
        />
      ) : (
        "Choose an audience to display the filters"
      )}
    </WhiteContainer>
  );
};

export default FilterApplied;
