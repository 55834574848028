import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const Declaration = ({ applicationData }) => {
  const checkHealthChallenges = () => {
    const healthChallenges =
      applicationData?.applicantDetails &&
      applicationData?.applicantDetails[0]?.healthChalenges;

    return healthChallenges ? "Yes" : "No";
  };

  return (
    <div className={styles["personal-container"]}>
      <h3>Declaration</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title='Health Challenges'
            data={checkHealthChallenges()}
            required={true}
          />

          {checkHealthChallenges() === "Yes" && (
            <TextContainer
              title='Health Comments'
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.healthComments
              }
              required={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Declaration;
