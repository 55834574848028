import React from "react";
import styles from "./FilledUnFilledText.module.css";
import FilledUnFilledText from "./FilledUnFilledText";
import AttachmentContainer from "./AttachmentContainer";
const TextContainer = ({
  title,
  data,
  required,
  mobile,
  attachement,
  column,
}) => {
  return (
    <div
      className={`${styles["text-container"]} ${
        column ? styles["column"] : ""
      }`}
    >
      <span>{title ? title + " " + ":" : ""} </span>
      {attachement ? (
        <AttachmentContainer data={data} />
      ) : (
        <FilledUnFilledText data={data} required={required} mobile={mobile} />
      )}
    </div>
  );
};

export default TextContainer;
