import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

/*****************fetch all users */
const fetchUsers = async (data) => {
  let url = `${API_URL}/api/User/GetUsers/${data?.pageSize}/${data?.pageNumber}`;

  const queryParams = [];

  if (data?.search) queryParams.push(`SearchBy=${data.search}`);
  if (data?.orderBy) queryParams.push(`OrderBy=${data?.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useFetchUsers = (data) => {
  return useQuery({
    queryKey: ["users", data],
    queryFn: () => fetchUsers(data),
    enabled: !!data.selected,
  });
};

/*****************add user */

const addUser = (values) => {
  return axios.post(API_URL + `/api/User/CreateUser`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddUser = () => {
  return useMutation(addUser);
};

/***********Export table users */
const exportTableUsers = async () => {
  return await axios.get(API_URL + "/api/Export/ExportUsers", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportTableUsers = () => {
  return useMutation(() => exportTableUsers());
};

/******************Edit users */
const editUser = (values) => {
  return axios.post(API_URL + `/api/User/EditUser`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useEditUser = () => {
  return useMutation(editUser);
};

//delete user
const deleteUser = (userId) => {
  return axios.delete(API_URL + `/api/User/DeleteUser/${userId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteUser = () => {
  return useMutation(deleteUser);
};

//change password
const changePassword = (values) => {
  return axios.post(API_URL + `/api/User/ResetPassword`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useChangePassword = () => {
  return useMutation(changePassword);
};

//change password
const uploadProfileImage = (values) => {
  return axios.post(API_URL + `/api/User/UploadProfileImage`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useUploadProfileImage = () => {
  return useMutation(uploadProfileImage);
};
