import React, { useState } from "react";
import styles from "./BurgerSideBar.module.css";
import burgerIcon from "../../../assets/Imagess/SidebarIcons/burgerIcon.svg";
import closeIcon from "../../../assets/Imagess/SidebarIcons/collapseSidebarIcon.svg";
import logo from "../../../assets/Imagess/SidebarIcons/Layer_1.svg";
import NavLink from "../NavHead/Navlinks/NavLink";
const BurgerSideBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <img
        src={burgerIcon}
        alt='burger'
        onClick={toggleSidebar}
        className={styles["burgerImage"]}
      />
      <div className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
        <div className={styles.closeBtn}>
          <img src={logo} alt='logo' />
          <img src={closeIcon} alt='close' onClick={toggleSidebar} />
        </div>

        <NavLink isOpen mobile setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

export default BurgerSideBar;
