import React from "react";
import LogInForm from "../../Moecules/Login/LogInForm";
import styles from "./LoginPage.module.css";
import Logo from "../../../assets/Imagess/logo.png";
import LoginVideo from "../../../assets/videos/background-video.mp4";
import { useLogin } from "../../../Hooks/Authentication";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const LoginPage = () => {
  return (
    <>
      <div className={styles["login-container"]}>
        <video autoPlay loop muted id={styles["background-video"]}>
          <source src={LoginVideo} type='video/mp4' />
        </video>
        <div className={styles["login-subContainer"]}>
          <div className={styles["login-sub-Container"]}>
            <img src={Logo} alt='logo' className={styles["logo-login"]} />
            <LogInForm />
          </div>
        </div>
      </div>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='colored'
      />
    </>
  );
};

export default LoginPage;
