import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
import AttachmentContainer from "../../../Atoms/CustomizedAtoms/AttachmentContainer";

const ListOfAcademicRecords = ({ applicant, applicationData,column }) => {
  return (
    <div className={styles["personal-container"]}>
      <div className={styles["personalsub-container"]}>
        {applicant?.applications &&
        applicant?.applications[0]?.startYourApplication === 0 ? (
          <div className={styles["personalsubds-container"]}>
            <div>
              <TextContainer
                title='List of any Advanced Courses Such As ‘A’ Levels, Ap, Clep, Ib Diploma'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.listAdvancedCources
                }
                required={false}
                column={true}
              />
            </div>
            <div>
              <TextContainer
                title='If you are not currently enrolled in a school please describe your activities since you last attended'
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.activitiesNotEnrolled
                }
                required={false}
                column={true}
              />
            </div>
          </div>
        ) : null}

        <div>
          <TextContainer
            title='Diploma'
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.diplomaFileName
            }
            required={false}
            attachement={true}
          />

          {/* <div>
     <span>Academic Records</span>
     <div>
       {" "}
       <img src={attachment} alt='attachment' /> :{" "}
       {applicant?.academicRecords ? (
         <>
           {applicant.academicRecords}{" "}
           <img src={availableIcon} alt='available' />{" "}
         </>
       ) : (
         <>
           No Attachment{" "}
           <img src={UnavailableIcon} alt='unavailable' />
         </>
       )}
     </div>
   </div> */}
        </div>
      </div>
    </div>
  );
};

export default ListOfAcademicRecords;
