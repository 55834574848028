import React, { useState, useEffect } from "react";
import styles from "./DocumentUpload.module.css";
import uploadDocumentIcon from "../../../assets/Imagess/uploadDocumentIcon.svg"
const DocumentUpload = ({
  text,
  label,
  required,
  name,
  width,
  height,
  errors,
  touched,
  onChange,
  value,
  imageHeight,
  size,
  smallImage,
  disabled,
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
     
      setSelectedFile(file);
      onChange(name, file);
    } else if (value) {
      setSelectedFile(value);
      onChange(name, value);
    }
  };

  const handleRemoveFile = (event) => {
    event.preventDefault();
    setSelectedFile(null);
    onChange(name, undefined);
    // onChange(name, null);
  };
  const containerStyle = {
    width: width,
    height: height,
    border: errors && touched ? "1px solid red" : "1px solid #1b224c31",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  useEffect(() => {
    if (value) {
      setSelectedFile(value);
      onChange(name, value);
    }
  }, [value]);

  return (
    <div>
      <label>
        {label}
        {required && <span className={styles.required}>*</span>}
      </label>

      <label
        className={` ${
          selectedFile ? styles["file-input-container-upl"] : styles["file-input-container"]
        }`}
        style={containerStyle}
      >
        <input
          type='file'
          className={styles["file-input"]}
          onChange={handleFileChange}
          disabled={disabled}
        />
        <div className={styles["img-text-container"]}>
          <img
            src={uploadDocumentIcon}
            alt='layer'
            className={smallImage ? styles["smallImage"] : styles["bigImage"]}
          />
          <p>
            {errors && touched ? (
              <span className={styles["error-message"]}>{errors}</span>
            ) : selectedFile ? (
              selectedFile.name || selectedFile.fileName || text
            ) : (
              text
            )}
          </p>
        </div>
        <div className={styles["size-upload-dc"]}>
          <p>Max Size: {size} mb</p>
        </div>

        {/* {selectedFile ? (
          <AiFillCloseCircle
            onClick={handleRemoveFile}
            className={styles["close-document-icon"]}
          />
        ) : null} */}
      </label>
    </div>
  );
};

export default DocumentUpload;
