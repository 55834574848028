import React from "react";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
import Logs from "../../../Custom/Logs/Logs";

const ContactLogs = () => {
  const logs = [
    {
      applicantLogId: "1",
      logId: 2,
      logStatus: "NotesTaken",
      logDate: "2024-04-19T15:11:00",
    },
    {
      applicantLogId: "2",
      logId: 3,
      logStatus: "Applicant Created",
      logDate: "2024-04-19T15:11:00",
    },
  ];
  return (
    <WhiteContainer white>
      <h1>Logs</h1>
      <Logs logs={logs} />
    </WhiteContainer>
  );
};

export default ContactLogs;
