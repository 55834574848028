import React from "react";
import SingleTabGroup from "./SingleTabGroup";
import MoreButton from "../Custom/PopOver/MoreButton";
import PopOver from "../Custom/PopOver/PopOver";
import styles from "../../Template/SingleApplicantTemplate/SingleApplicantPage.module.css";
import moreicon from "../../../assets/Imagess/moreicon.svg";
import exportICon from "../../../assets/Imagess/exportIcon.svg";
import printicon from "../../../assets/Imagess/printIcon.svg";
import { useFetchGeneratePdf } from "../../../Hooks/SingleApplicant";
import { toast } from "react-toastify";
const SingleApplicantNav = ({
  applicant,
  tabContainer,
  selected,
  setSelected,
  handlePrint,
}) => {
  const generatePdf = useFetchGeneratePdf();

  const handleGeneratePdf = () => {
    const applicantId = applicant?.intersted?.applicantId;
    generatePdf
      .mutateAsync(applicantId)
      .then((data) => {
        const fileURL = data?.data?.fileURL;
        const newWindow = window.open(fileURL, "_blank");
        if (!newWindow) {
          console.error("Unable to open a new tab for printing.");
        }
        toast.success("PDF is Generating");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  return (
    <div className={styles["tab-singleapp-container"]}>
      <SingleTabGroup
        tabContainer={tabContainer}
        selected={selected}
        setSelected={setSelected}
      />

      <MoreButton icon={moreicon} toolTipText='More'>
        <PopOver
          stop
          info={[
            {
              title: "Export",
              icon: exportICon,
              handleFunction: handleGeneratePdf,
            },
            {
              title: "Print",
              icon: printicon,
              handleFunction: handlePrint,
            },
          ]}
        />
      </MoreButton>
    </div>
  );
};

export default SingleApplicantNav;
