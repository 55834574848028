import React from "react";
import styles from "./CAmpaignReflection.module.css";
import DashboardContainer from "../DashboardContainer";
import RateContainer from "../ApplicationAnalysis/Rate/RateChart/RateContainer/RateContainer";
import CampaignStatistics from "./CampaignStatistics/CampaignStatistics";
import StackedColumnGraph from "./StackedColumGraph/StackedColumnGraph";

const CampaignReflection = () => {
  const data = [
    {
      title: "Total Click Rate",
      text: "Percentage of applicants who complete the entire application process.",
      percentage: "75",
      color: "#3886BD",
    },
    {
      title: "Total Response Rate",
      text: "Average time taken to respond to an applications.",
      percentage: "35",
      color: "#F3223C",
    },
    {
      title: "Total Converted Rate",
      text: "not sure about it for now dummy",
      percentage: "45",
      color: "#FFB202",
    },
  ];

  return (
    <div className={styles["gridTwo"]}>
      <CampaignStatistics />
      <StackedColumnGraph />
    </div>
  );
};

export default CampaignReflection;
