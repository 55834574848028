import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

const fetchApplicantView = async (data) => {
  let url = `${API_URL}/api/ApplicantView/GetApplicants/${data?.pageSize}/${data?.pageNumber}`;

  const queryParams = [];
  if (data?.orderBy) queryParams.push(`OrderBy=${data.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);
  if (data?.search) queryParams.push(`SearchBy=${data.search}`);
  if (data?.filterByField && data?.filterBy)
    queryParams.push(`FilterByField=${data.filterByField}`);
  if (data?.filterBy) queryParams.push(`FitlerBy=${data.filterBy}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useFetchApplicantView = (data) => {
  return useQuery({
    queryKey: ["Applicant", data],
    queryFn: () => fetchApplicantView(data),
    enabled: !!data.selected,
  });
};

/****************fetch single applicant */
const fetchSingleApplicant = async (data) => {
  if (data?.id) {
    return await axios.get(
      API_URL + `/api/ApplicantView/GetApplicantById?ApplicantId=${data?.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};
export const useFetchSingleApplicant = (data) => {
  return useQuery({
    queryKey: ["ApplicantById", data],
    queryFn: () => fetchSingleApplicant(data),
    enabled: data?.isApplicant,
  });
};

/***************Update applicant status */

const changeApplicantStatus = (fields) => {
  return axios.post(
    API_URL + `/api/ApplicantView/ChangeApplicantStatus`,
    fields,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useChangeApplicantsStatus = () => {
  return useMutation(changeApplicantStatus);
};
/***************Update  status i ncase cancel */

const bulkCancelApplicant = (fields) => {
  return axios.post(
    API_URL + `/api/ApplicantView/BulkCancelApplicant`,
    fields,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useBulkCancelApplicant = () => {
  return useMutation(bulkCancelApplicant);
};

/*******************Get applicant status */
const fetchApplicantStatus = async () => {
  return await axios.get(API_URL + "/api/ApplicantView/GetApplicantStatuses", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchApplicantStatus = () => {
  return useQuery({
    queryKey: ["applicantStatus"],
    queryFn: () => fetchApplicantStatus(),
    enabled: true,
  });
};

//*********export applicants table */
const exportApplicants = async () => {
  return await axios.get(API_URL + "/api/Export/ExportApplicants", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportApplicantsTable = () => {
  return useMutation(() => exportApplicants());
};
