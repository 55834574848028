import React from "react";
import styles from "./onlineForm.module.css";
import attachment from "../../../../assets/Imagess/attachmentIcon.svg";
import availableIcon from "../../../../assets/Imagess/availableICon.svg";
import UnavailableIcon from "../../../../assets/Imagess/notAvailableIcon.svg";
import notFilled from "../../../../assets/Imagess/notFilledIcon.svg";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
const PersonalInformation = ({ applicant, isApplicant }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3> Personal Information </h3>
      <div className={styles["grid-container"]}>
        <div className={styles["personal-container2"]}>
          <TextContainer
            title='Title'
            data={applicant?.intersted?.title_Display}
          />
          <TextContainer
            title='First Name'
            data={applicant?.intersted?.firstName}
          />

          <TextContainer
            title='Middle Name'
            data={applicant?.intersted?.middleName}
          />

          <TextContainer
            title='Last Name'
            data={applicant?.intersted?.lastName}
          />
          <TextContainer title='Email' data={applicant?.intersted?.email} />
        </div>
        <div className={styles["personal-container2"]}>
          {isApplicant && (
            <TextContainer
              title='Date of Birth '
              data={applicant?.intersted?.dob?.substring(0, 10)}
            />
          )}

          {isApplicant && (
            <TextContainer
              title='Gender '
              data={applicant?.intersted?.gender_Display}
            />
          )}

          <TextContainer
            title='Nationality '
            data={applicant?.intersted?.nationality_Display}
          />

          <TextContainer
            title='Mobile '
            data={applicant?.intersted?.mobile}
            mobile
          />

          <TextContainer
            title='WhatsApp Number'
            data={applicant?.intersted?.whatsAppNumber}
            mobile
          />

          <TextContainer
            title='Telephone'
            mobile
            data={applicant?.intersted?.phoneNumber}
          />
        </div>
        {isApplicant ? (
          <>
            {" "}
            <div className={styles["personal-container2"]}>
              <TextContainer
                title='Passport No.'
                data={applicant?.intersted?.passportNumber}
              />
              {applicant?.intersted?.nationality_Display === "Emirate" && (
                <>
                  <TextContainer
                    title='Emirates ID No.'
                    data={applicant?.intersted?.emiratesId}
                  />
                  <TextContainer
                    title='Ethbara No.'
                    data={applicant?.intersted?.etibharaNo}
                  />
                </>
              )}
            </div>
            <div className={styles["personal-container2"]}>
              <TextContainer
                title='Family Book No.'
                data={applicant?.intersted?.familyBookNumber}
              />

              {/* <div>
                <span>Family Book</span>
                <div>
                  {" "}
                  <img src={attachment} alt='attachment' /> :{" "}
                  {applicant?.familyBook ? (
                    <>
                      {applicant.familyBook}{" "}
                      <img src={availableIcon} alt='available' />{" "}
                    </>
                  ) : (
                    <>
                      No Attachment{" "}
                      <img src={UnavailableIcon} alt='unavailable' />
                    </>
                  )}
                </div>
              </div> */}
              <TextContainer
                title='Family No.'
                data={applicant?.intersted?.familyNo}
              />
              <TextContainer
                title='City No.'
                data={applicant?.intersted?.cityNo}
              />
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default PersonalInformation;
