import React, { useRef, useState } from "react";
import TableTopHeader from "../TableTopHeader/TableTopHeader";
import ColumnVisibility from "../TableTopHeader/ColumnVisibility";
import * as tableHeader from "../TableHeader/NewAudienceTableHeader";
import TableComponent from "../TableComponent/TableComponent";
import WhiteBoxContainer from "../../HOC/WrappingContainer/WhiteBoxContainer";
import { useExportShowResult } from "../../../Hooks/UserAssignment";
import { useNavigate } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../Custom/ExportComponent/PrintComponent";
const NewAudienceTable = ({
  isLoading,
  refetch,
  audienceData,
  valuesToExport,
}) => {
  const printRef = useRef();

  const [visibleColumn, setVisibleColumn] = useState({
    firstName: true,
    contactType_Display: true,
    selectedTerm_Display: true,
    fieldOfInterest_Display: true,
    applicationStart_Display: true,
    nationality_Display: true,
    type: true,
    statusText: true,
  });
  const exportShowResult = useExportShowResult();

  const combinedData = audienceData
    ? [
        ...(Array.isArray(audienceData?.applicants)
          ? audienceData.applicants.map((applicant) => ({
              ...applicant,
              type: "applicant",
              id: applicant.applicantId,
            }))
          : []),
        ...(Array.isArray(audienceData?.externalContacts)
          ? audienceData.externalContacts.map((contact) => ({
              ...contact,
              type: "contact",
              id: contact.externalContactId,
              firstName: contact.contactName,
            }))
          : []),
        ...(Array.isArray(audienceData?.leads)
          ? audienceData.leads.map((lead) => ({
              ...lead,
              type: "lead",
              id: lead.studentId,
            }))
          : []),
      ]
    : [];
  const handleExport = async () => {
    try {
      const data = await exportShowResult.mutateAsync(valuesToExport);

      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "AudienceTable";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected Blob.", data);
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  const tableData = {
    data: {
      items: combinedData.map((item) => ({
        ...item,
        id: item.id,
      })),
    },
    refetch: refetch,
    isLoading: isLoading,
    tableHeader: tableHeader.newAudienceTableHeader,
  };
  const rightButtons = (
    <ColumnVisibility
      tableHeader={tableHeader.newAudienceTableHeader}
      visibleColumn={visibleColumn}
      setVisibleColumn={setVisibleColumn}
    />
  );

  return (
    <WhiteBoxContainer>
      <TableTopHeader
        leftComponent={
          <div>
            <h3 style={{ fontWeight: "500", fontSize: "14px" }}>
              {combinedData?.length} Audience
            </h3>
          </div>
        }
        rightButtons={rightButtons}
        handleExport={handleExport}
        handlePrint={handlePrint}
      />
      <TableComponent
        tableData={tableData}
        isLoading={false}
        // page={usersPage}
        // setPage={setUsersPage}
        // pageSize={usersPageSize}
        // setPageSize={setUsersPageSize}
        visibleColumn={visibleColumn}
        columnVisibility
      />
      <div style={{ display: "none" }}>
        <PrintComponent
          ref={printRef}
          title='Audience Table'
          data={tableData}
        />
      </div>
    </WhiteBoxContainer>
  );
};

export default NewAudienceTable;
