import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

/***************Get reference document by reference id */
const fetchReferenceDocumentById = async (referencDocumentId) => {
  return await axios.get(
    API_URL +
      `/api/ApplicantView/GetReferanceDocumentById/${referencDocumentId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      responseType: "blob",
    }
  );
};

export const useDownloadReferenceDocument = () => {
  return useMutation(fetchReferenceDocumentById);
};
