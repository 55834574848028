import React from "react";
import styles from "./onlineForm.module.css";
import attachment from "../../../../assets/Imagess/attachmentIcon.svg";
import availableIcon from "../../../../assets/Imagess/availableICon.svg";
import UnavailableIcon from "../../../../assets/Imagess/notAvailableIcon.svg";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";

const PersonalStatement = ({ applicant, applicationData }) => {
  const formatFunction = (data) => {
    if (data) {
      return <p dangerouslySetInnerHTML={{ __html: data }}></p>;
    } else {
      return null;
    }
  };
  return (
    <div className={styles["personal-container"]}>
      <h3>Personal Statement</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <div>
            <span className={styles["subtitle"]}>
              The Personal Statement is an opportunity to highlight aspect of
              your background to support your application:
            </span>{" "}
            <br />
            <FilledUnFilledText
              required={true}
              data={formatFunction(
                applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.personalStatement
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalStatement;
