import React from "react";
import ProgressBar from "@atlaskit/progress-bar";
import "./CustomizedBar.css";
const CustomizedProgressBar = ({value}) => {
  return (
    <div className='custom-progress-bar'>
      <ProgressBar ariaLabel='Done: 10 of 10 issues' value={value} />
    </div>
  );
};

export default CustomizedProgressBar;
