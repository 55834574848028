import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//bulk assign
const bulkassign = (values) => {
  return axios.post(API_URL + `/api/User/BulkAssignUser`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useBulkassign = () => {
  return useMutation(bulkassign);
};

//export tble
const exportShowResult = async (data) => {
  return await axios.post(API_URL + `/api/Export/ShowResultExport`, data, {
    responseType: "blob", 
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useExportShowResult = () => {
  return useMutation(exportShowResult);
};

//automatic assign
const automaticAssign = (values) => {
  return axios.post(API_URL + `/api/User/AutomaticAssignUser`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAutomaticAssign = () => {
  return useMutation(automaticAssign);
};
