import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import waitIcon from "../../../../../../assets/Imagess/calenderVector.svg";
import styles from "./RightSideComp.module.css";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../../../../Atoms/Inputs/TextInput";
import { useSearchParams } from "react-router-dom";

const WaitingForm = forwardRef(({ selectedNode }, ref) => {
  const waitingRef = useRef();
  const [init, setInit] = useState({});

  const validationSchema = Yup.object().shape({
    wait: Yup.number().required("actions is required"),
  });

  useEffect(() => {
    if (selectedNode) {
      const initialValues = {
        wait: selectedNode?.data?.label?.wait || "",
      };

      if (initialValues.wait !== init.wait) {
        setInit(initialValues);
      }
    }
  }, [selectedNode, init.wait]);

  useImperativeHandle(ref, () => ({
    submitForm: () => {
      waitingRef.current.submitForm();
    },
    get isValid() {
      return waitingRef.current.isValid;
    },
    get values() {
      return waitingRef.current.values;
    },
  }));

  useEffect(() => {
    ref.current = waitingRef.current;
  }, [ref]);
  return (
    <Formik
      initialValues={init}
      enableReinitialize={true}
      innerRef={waitingRef}
      validationSchema={validationSchema}
      onSubmit={(values) => {}}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <div className={styles["email_container"]}>
              <TextInput
                label='Waiting Days'
                placeholder='Enter'
                icon={waitIcon}
                name='wait'
                value={values.wait}
                required={true}
                // text={true}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
                errors={errors.wait}
                touched={touched.wait}
                type='number'
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
});
export default WaitingForm;
