import React from "react";
import styles from "./SinglaTab.module.css";

const SingleTab = ({ active, text, handleOnClick, icon, number, fontSize }) => {
  const tabClass = active ? "active-tab" : "inactive-tab";
  const tabImage = active ? "activeTabImage" : "";
  return (
    <div
      className={`${styles["single-tab"]} ${styles[tabClass]}`}
      onClick={handleOnClick}
    >
      {icon && <img src={icon} alt='icon' className={` ${styles[tabImage]}`} />}
      <h3 className={fontSize ? styles["text-style"] : ""}>{text}</h3>
    </div>
  );
};

export default SingleTab;
