import React from "react";
import Chart from "react-apexcharts";
import styles from "./CampaignCharts.module.css";

const StackedBarChart = ({ groupedInterested }) => {

  const interestedCount = groupedInterested?.[0]?.statusCount || 0;
  const notInterestedCount = groupedInterested?.[1]?.statusCount || 0;
  const options = {
    series: [
      {
        data: [interestedCount, notInterestedCount],
      },
    ],
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
              bar: {
                horizontal: false,
              },
            },
          },
        },
        {
          breakpoint: 600,
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
              },
            },
          },
        },
      ],
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        barHeight: "100%",
        distributed: true,
        horizontal: true,
        dataLabels: {
          position: "center",
          style: {
            colors: ["#FFF"],
          },
        },
      },
    },
    colors: ["#FF0000", "#015699"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1b224c"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Interested", "Not Interested"],
      labels: {
        show: true,
        style: {
          fontWeight: "700",
          colors: ["#1b224c"],
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  };

  return (
    <div className={styles["chart_cont"]}>
      <h2>Status Statistics</h2>
      <Chart
        options={options}
        series={options.series}
        type='bar'
        width='100%'
        height='100%'
      />
    </div>
  );
};

export default StackedBarChart;
