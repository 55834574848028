import React from "react";
import styles from "./DateContainer.module.css";
import calanderIcon from "../../../../assets/Imagess/Contact/calendaricon.svg";

const DateContainer = ({ date, label }) => {
  return (
    <div className={styles["date_container"]}>
      <img src={calanderIcon} alt='calendaricon' />{" "}
      <h4>
        {" "}
        {label}: {date}
      </h4>
    </div>
  );
};

export default DateContainer;
