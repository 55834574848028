import React from "react";
import ApplicantsTab from "./ApplicantsTab";

const ApplicantGroupTab = ({ tabContainer, selected, setSelected }) => {
 
  return tabContainer?.map((item, index) => {
    return (
      <ApplicantsTab
        key={index}
        text={item.text}
        handleOnClick={() => setSelected(item.value)}
        active={item.value === selected ? true : false}
        icon={item.icon}
        number={item.number}
      />
    );
  });
};

export default ApplicantGroupTab;
