import React from "react";
import styles from "./RateContainer.module.css";
import RateChart from "../RateChart";
const RateContainer = ({ data }) => {
  return (
    <div className={styles["chart_text_container"]}>
      <div className={styles["chart_container"]}>
        <RateChart percentage={data?.percentage} color={data?.color} />
      </div>

      <div className={styles["text_container"]}>
        <h1>{data?.title}</h1>
        <h2>{data?.text}</h2>
      </div>
    </div>
  );
};

export default RateContainer;
