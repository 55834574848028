export const userHeader = [
  { title: "First Name", key: "firstName" },
  { title: "Last Name", key: "lastName" },
  { title: "Program Type", key: "applicationStart_Display" },
  { title: "Faculty", key: "fieldOfInterest_Display" },
  { title: "Phone", key: "mobile", flag: "mobile" },
  { title: "Email", key: "email" },
  { title: "User Assign", key: "userAssigned" },
  { title: "Status", key: "statusText", flag: "status" },
  { title: "Type", key: "type" },
  { title: "Nationality", key: "nationality_Display" },
  { title: "Hear About Us", key: "howDoYouHear_Display" },
  { title: "Contact Type", key: "contactType_Display" },

];
