import React, { useState } from "react";
import styles from "./settingBurgurMenu.module.css";
import burgerIcon from "../../../../../assets/Imagess/SettingsSideBar/settingsCloseArrow.svg";
import exitIcon from "../../../../../assets/Imagess/SettingsSideBar/setiingsOpenArrow.svg";
import testIcon from "../../../../../assets/Imagess/SettingsSideBar/testIcon.svg";
import externalContactIcon from "../../../../../assets/Imagess/SettingsSideBar/externalContact.svg";
import termsIcon from "../../../../../assets/Imagess/SettingsSideBar/termsIcon.svg";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import { useFetchSettingsPages } from "../../../../../Hooks/Settings";
const SettingsBurger = ({
  setType,
  allTypes,
  setIsRelated,
  isTypesLoading,
  type,
  mobile,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState(type || 0);

  const { data: settingsPage } = useFetchSettingsPages();
  const handelOnCLick = (item) => {
    setType(parseInt(item.ddtypeId));
    setActiveLink(item.ddtypeId);
    if (mobile) {
      setIsOpen(false);
    }
    if (item.relatedTo !== null) {
      setIsRelated(item.relatedTo);
    } else {
      setIsRelated(null);
    }
    if (item.ddtypeId === 10 || item.ddtypeId === 11 || item.ddtypeId === 12) {
      toast.warning("Select a country to reveal the data");
    }
    if (item.ddtypeId === 5) {
      toast.warning("Select application type to reveal the data");
    }
  };

  if (isTypesLoading) {
    return <Loader />;
  }
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const links = settingsPage?.data?.map((item) => {
    return {
      ddtypeId: item.pageName,
      type: item.name,
      icon: item.icon,
      relatedTo: item.relatedToIndex,
    };
  });
  const sortedData = links?.sort((a, b) => {
    return Number(a.ddtypeId) - Number(b.ddtypeId);
  });
  // .concat(
  //   {
  //     ddtypeId: 19,
  //     type: "Terms",
  //     icon: termsIcon,
  //     relatedTo: null,
  //   },
  //   {
  //     ddtypeId: 20,
  //     type: "Academic Years",
  //     icon: testIcon,
  //     relatedTo: null,
  //   },
  //   {
  //     ddtypeId: 21,
  //     type: "External Contact",
  //     icon: externalContactIcon,
  //     relatedTo: null,
  //   },
  //   {
  //     ddtypeId: 24,
  //     type: "E-signature",
  //     icon: testIcon,
  //     relatedTo: null,
  //   }
  // );
  return (
    <div>
      {" "}
      <img
        src={burgerIcon}
        alt='burger'
        onClick={toggleSidebar}
        className={styles["closeIcon"]}
      />
      <div className={`${styles.sidebar} ${isOpen ? styles.open : ""}`}>
        <div className={styles.closeBtn} onClick={toggleSidebar}>
          <img
            src={exitIcon}
            alt='close'
            onClick={toggleSidebar}
            className={styles["closeIcon"]}
          />
        </div>
        <div style={{ marginBottom: "30px" }}>
          <ul className={styles["ul-cont"]}>
            {sortedData?.map((item, index) => (
              <li
                key={item.ddtypeId}
                className={`${
                  item.ddtypeId === activeLink ? styles.activeListItem : ""
                }
                ${index % 2 === 0 ? styles.listItem2 : styles.listItem} `}
                onClick={() => handelOnCLick(item)}
              >
                <img src={`../${item.icon}`} alt='icon' />
                {item.type}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SettingsBurger;
