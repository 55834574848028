import React from "react";
import styles from "./Rate.module.css";
import DashboardContainer from "../../DashboardContainer";
import RateChart from "./RateChart/RateChart";
import RateContainer from "./RateChart/RateContainer/RateContainer";
const Rate = () => {
  const data = [
    {
      title: "Applicants Retention",
      text: "Percentage of applicants who complete the entire application process",
      percentage: "75",
      color: "#3886BD",
    },
    {
      title: "Time Response",
      text: "Average time taken to respond to an applications",
      percentage: "35",
      color: "#F3223C",
    },
    {
      title: "Source Rate",
      text: "Effectiveness of communication and for folloe up strategies",
      percentage: "45",
      color: "#FFB202",
    },
  ];
  return (
    <DashboardContainer>
      {data?.map((item, index) => {
        return (
          <div
            className={styles["rateCont"]}
            key={index}
            style={{ borderBottom: index == data?.length - 1 ? "none" : "" }}
          >
            <RateContainer data={item} />
          </div>
        );
      })}
    </DashboardContainer>
  );
};

export default Rate;
