import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

/*****************Fetch type by id */
const fetchSidbarPages = async (data) => {
  return await axios.get(API_URL + `/api/User/GetSideBarPages`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useFetchSidbarPages = (data) => {
  return useQuery({
    queryKey: ["SidbarPages", data],
    queryFn: () => fetchSidbarPages(data),
    enabled: true,
  });
};
