import React, { useEffect, useState } from "react";
import styles from "./ReferenceMolecule.module.css";
import attachment from "../../../../assets/Imagess/attachmentIcon.svg";
import AttachmentContainer from "../../../Atoms/CustomizedAtoms/AttachmentContainer";
import { useFetchApplicationByApplicantAndApplication } from "../../../../Hooks/SingleApplicant";
import { useParams } from "react-router-dom";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
import MoreButton from "../../Custom/PopOver/MoreButton";
import ReferenceDocumentIcon from "../../../../assets/Imagess/refrenceDocumentIcon.svg";
import moreIcon from "../../../../assets/Imagess/more-vet-svgrepo-com 1.svg";
import deleteicon from "../../../../assets/Imagess/deleteicon.svg";
import printIcon from "../../../../assets/Imagess/printIcon.svg";
import downloadIcon from "../../../../assets/Imagess/downloadIcon.svg";
import PopOver from "../../Custom/PopOver/PopOver";
import { useDownloadReferenceDocument } from "../../../../Hooks/Reference";
import { toast } from "react-toastify";
const ReferenceMolecule = ({ data }) => {
  const { id } = useParams();
  const [applicationId, setApplicationId] = useState(
    data?.applications[0]?.applicationId
  );
  const { data: applicationDataById } =
    useFetchApplicationByApplicantAndApplication(id, applicationId);

  const downloadReferenceDocument = useDownloadReferenceDocument();
  useEffect(() => {
    {
      data?.applications ??
        setApplicationId(data?.applications[0]?.applicationId);
    }
  }, [data]);

  const handleDownload = async (doc) => {
    try {
      const data = await downloadReferenceDocument.mutateAsync(doc);
      const url = window.URL.createObjectURL(data.data);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Reference Document";
      link.click();
      window.URL.revokeObjectURL(url);
      toast.success("Document is Downloaded");
    } catch (error) {
      console.error("Error downloading file:", error);
      toast.error("File has not been submitted yet");
    }
  };
  return (
    <div className={styles["reference-container"]}>
      <div className={styles["reference"]}>
        <div className={styles["reference-subcontainer"]}>
          <TextContainer
            title='Title'
            data={
              applicationDataById?.data?.applicantReferance &&
              applicationDataById?.data?.applicantReferance[0]
                ?.referanceTitle_Display
            }
            required={false}
          />
          <TextContainer
            title='Name'
            data={
              applicationDataById?.data?.applicantReferance &&
              applicationDataById?.data?.applicantReferance[0]?.referanceName
            }
            required={false}
          />
          <TextContainer
            title='Email'
            data={
              applicationDataById?.data?.applicantReferance &&
              applicationDataById?.data?.applicantReferance[0]?.referanceEmail
            }
            required={false}
          />
        </div>

        <div className={styles["document-container"]}>
          <h2>Letter of Recomendation</h2>
          <div className={styles["document-subcontainer"]}>
            {applicationDataById?.data?.applicantReferance &&
            applicationDataById?.data?.applicantReferance[0]
              ?.applicantReferanceId &&
            applicationDataById?.data?.applicantReferance[0]?.referanceName !=
              null ? (
              <>
                {" "}
                <div className={styles["document"]}>
                  <img src={ReferenceDocumentIcon} alt='reference document' />
                  <div>
                    <h2>Reference.pdf</h2>
                    <h4>Reference</h4>
                  </div>
                </div>
                <MoreButton
                  icon={moreIcon}
                  profileImg='profile-img'
                  width='200px'
                  imgWidth='25px'
                  imgHeight='20px'
                  top='1rem'
                  toolTipText='More'
                >
                  <PopOver
                    stop
                    info={[
                      {
                        title: "Download",
                        icon: downloadIcon,
                        handleFunction: () =>
                          handleDownload(
                            applicationDataById?.data?.applicantReferance[0]
                              ?.applicantReferanceId
                          ),
                      },
                      {
                        title: "Print",
                        icon: printIcon,
                      },
                      {
                        title: "Delete",
                        icon: deleteicon,
                      },
                    ]}
                  />
                </MoreButton>
              </>
            ) : (
              <TextContainer attachement={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferenceMolecule;
