import React from "react";
import EmailWorkFlowPopup from "../EmailWorkFlowPopup";

const CreateEmailWorkFlowMobile = ({
  handleOnClose,
  content,
  button,
  showMobilePopup,
  start,
}) => {
  return (
    showMobilePopup &&
    !start && (
      <EmailWorkFlowPopup
        handleOnClose={handleOnClose}
        button={button}
        content={content}
      />
    )
  );
};

export default CreateEmailWorkFlowMobile;
