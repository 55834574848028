import React, { useEffect, useRef, useState } from "react";
import styles from "./SettingsPage.module.css";
import SettingsModal from "../../Moecules/Settings/SettingsModal/SettingsModal";
import DeletePopup from "../../Moecules/Custom/DeletePopup";
import {
  useDeleteSettingsOption,
  useFetchDropDownTypes,
  useFetchRelatedToType,
} from "../../../Hooks/Settings";
import FieldOfInterestModa from "../../Moecules/Settings/SettingsModal/FieldOfInterestModa";
import { toast } from "react-toastify";
import CustomizedSerachSelect from "../../Atoms/Inputs/CustomizedSerachSelect";
import * as tableHeader from "../../Moecules/TableHeader/SettingsHeader";
import TableComponent from "../../Moecules/TableComponent/TableComponent";
import SettingsTopHeader from "../../Moecules/Settings/SettingsMolecules/SettingsTopHeader";
import PrintComponent from "../../Moecules/Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";

const SettingsPage = ({
  title,
  header,
  data,
  type,
  refetchTypes,
  isRelated,
  isOptionsLoading,
  search,
  setSearch,
  orderDir,
  orderBy,
  setOrderBy,
  setOrderDir,
}) => {
  const printRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [deletMode, setDeleteMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedValueText, setSelectedValueText] = useState("");

  //Fetch data
  const { data: options, refetch: refetchType } = useFetchDropDownTypes({
    typeId: isRelated,
  });

  const {
    data: parentOptions,
    refetch: refetchParentOptions,
    isLoading: isOptionsParentLoading,
  } = useFetchRelatedToType({
    type: type,
    parentId: selectedValueText,
    search: search,
    orderBy: orderBy,
    orderDir: orderDir,
  });

  const { mutate: deleteSettingsOption, isLoading: isDeleteSettings } =
    useDeleteSettingsOption();

  const handleAddClick = () => {
    if (isRelated !== null) {
      if (!selectedValueText) {
        if (title === "Program") {
          toast.error(`Choose an Application type before adding a ${title}`);
        } else {
          toast.error(`Choose a Country before adding a ${title}`);
        }
      } else {
        setIsModalOpen(true);
        setModalMode("add");
      }
    } else {
      setIsModalOpen(true);
      setModalMode("add");
    }
  };

  const handleEditClick = (e, item) => {
    e.stopPropagation();
    setIsModalOpen(true);
    setModalMode("edit");
    setSelectedItem(item);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem({});
  };

  const handleDelete = () => {
    deleteSettingsOption(selectedItem?.ddid, {
      onSuccess: () => {
        setDeleteMode(false);
        setSelectedItem({});
        refetchTypes();
        toast.success("Deleted successfully");
        refetchParentOptions();
      },
      onError: (error) => {
        console.error("Error deleting email", error);
        toast.error(
          "This option has been used by an applicant and cannot be deleted."
        );
        setDeleteMode(false);
        setSelectedItem({});
      },
    });
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  useEffect(() => {
    setOrderBy("");
    setSelectedValueText("");
  }, [type]);

  const tableData =
    type === 8
      ? {
          data: {
            items: data?.map((item) => ({
              ...item,
              id: item.ddid,
            })),
          },
          refetch: null,
          isLoading: isOptionsLoading,
          tableHeader: tableHeader.fieldOfInterestTableHeader,
        }
      : {
          data:
            isRelated != null
              ? {
                  items: parentOptions?.data?.map((item) => ({
                    ...item,
                    id: item.ddid,
                  })),
                }
              : {
                  items: data?.map((item) => ({
                    ...item,
                    id: item.ddid,
                  })),
                },
          refetch: refetchTypes,
          isLoading: isOptionsLoading || isOptionsParentLoading,
          tableHeader: tableHeader.settingsTableHeader,
        };

  //customized dropdown in case settings type depends on a parent
  const customizedParentDropdown =
    isRelated !== null ? (
      <div className={styles["select-container"]}>
        <CustomizedSerachSelect
          key={type}
          options={options?.data}
          placeholder={
            isRelated == 0 ? "Select Application Type" : "Select a Country"
          }
          value={selectedValueText}
          onChange={setSelectedValueText}
        />
      </div>
    ) : (
      ""
    );

  return (
    <>
      <SettingsTopHeader
        title={title === "External Contact" ? "New Contact" : title}
        type={type}
        search={search}
        setSearch={setSearch}
        handleAddClick={handleAddClick}
        handlePrint={handlePrint}
        data={tableData}
        to={type === 21 ? "/home/add-externalContact" : null}
      />
      {customizedParentDropdown}
      <TableComponent
        tableData={tableData}
        handleEditClick={handleEditClick}
        handleDelete={(e, item) => (
          setDeleteMode(true), setSelectedItem(item), e.stopPropagation()
        )}
        orderBy={orderBy}
        orderDir={orderDir}
        setOrderBy={setOrderBy}
        setOrderDir={setOrderDir}
        editAdd={true}
      />
      {isModalOpen && type === 8 ? (
        <FieldOfInterestModa
          handleOnClose={handleCloseModal}
          type={type}
          mode={modalMode}
          refetchFieldOfInterest={refetchTypes}
          selectedItem={selectedItem}
        />
      ) : (
        ""
      )}{" "}
      {isModalOpen && type !== 8 ? (
        <SettingsModal
          title={title === "External Contact" ? "New Contact" : title}
          label1={title + " " + "ID"}
          handleOnClose={handleCloseModal}
          mode={modalMode}
          type={type}
          refetchTypes={refetchTypes}
          selectedValueText={selectedValueText}
          refetchParentOptions={refetchParentOptions}
          isRelated={isRelated}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      ) : (
        ""
      )}
      {deletMode && (
        <DeletePopup
          handleOnClose={() => (setDeleteMode(false), setSelectedItem({}))}
          title={title}
          handleDelete={handleDelete}
          isLoading={isDeleteSettings}
        />
      )}
      <div style={{ display: "none" }}>
        <PrintComponent ref={printRef} title={title} data={tableData} />
      </div>
    </>
  );
};

export default SettingsPage;
