import React, { useEffect, useState } from "react";
import Navhead from "../../Moecules/NavHead/Navhead";
import { Outlet, useLocation, useParams } from "react-router-dom";
import SideBar from "../../Moecules/SideBar/SideBar";
import styles from "./GeneralBodyContainer.module.css";
import { pathConfig } from "./PathConfig";
const GeneralBody = () => {
  const [pathName, setPathName] = useState("dashboard");
  const [isArrow, setIsArrow] = useState(false);
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    const pathURL = location.pathname.split("/")[2];
    const config = pathConfig[pathURL] || pathConfig.default;
  
    if (pathURL === "applicants" && id) {
      if (location?.state?.isApplicant) {
        setPathName("Prospect Details");
      } else {
        setPathName("Leads Details");
      }
      setIsArrow(true);
    } else {
      setPathName(config.title);
      setIsArrow(config.isArrow);
    }
  }, [location, id]);
  return (
    <div className={styles["generalBody"]}>
      <div className={styles["sidebar-subcont"]}>
        <SideBar />
      </div>
      <div className={styles["generalBody-subcont"]}>
        <Navhead title={pathName} isArrow={isArrow} />
        <Outlet />
      </div>
    </div>
  );
};

export default GeneralBody;
