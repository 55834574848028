import React from "react";
import attachment from "../../../assets/Imagess/attachmentIcon.svg";
import availableIcon from "../../../assets/Imagess/availableICon.svg";
import UnavailableIcon from "../../../assets/Imagess/notAvailableIcon.svg";
const AttachmentContainer = ({ data }) => {
  return (
    <p>
      {" "}
      <img src={attachment} alt='attachment' /> :{" "}
      {data ? (
        <>
          {data} <img src={availableIcon} alt='available' />{" "}
        </>
      ) : (
        <>
          No Attachment <img src={UnavailableIcon} alt='unavailable' />
        </>
      )}
    </p>
  );
};

export default AttachmentContainer;
