import React from "react";
import Modal from "../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../Atoms/Modal/ModalChildrenContainer";
import USerProfileIcon from "../../../assets/Imagess/usreprofileicon.svg";
import editProfileIcon from "../../../assets/Imagess/profileImageEdit.png";
import styles from "./UserEditProfilePopup.module.css";
import { Formik, Form, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import TextInput from "../../Atoms/Inputs/TextInput";
import PhoneNumber from "../../Atoms/Inputs/PhoneNumber";
import mobileICon from "../../../assets/Imagess/mobileInputIcon.png";
import user from "../../../assets/Imagess/userVector.svg";

import emailicon from "../../../assets/Imagess/emailloginicon.svg";
import { toast } from "react-toastify";
import { useEditUser } from "../../../Hooks/Users";
import Buttons from "../../Atoms/Buttons/Buttons";
import LoaderButton from "../Loader/LoaderButton";
const UserEditProfilePopup = ({
  handleOnClose,
  userDetails,
  refetchUser,
  setEditMode,
  fileInputRef,
  handleImageChange,
  handleSelectImage,
}) => {
  const { mutate: editUser, isLoading: isEdittingUser } = useEditUser();

  const initialValues = {
    firstNameEn:
      (userDetails?.profile && userDetails?.profile[0]?.firstNameEn) || "",
    lastNameEn:
      (userDetails?.profile && userDetails?.profile[0]?.lastNameEn) || "",
    email: (userDetails?.profile && userDetails?.profile[0]?.email) || "",
    mobile: (userDetails?.profile && userDetails?.profile[0]?.mobile) || "",
  };
  const validationSchema = Yup.object().shape({
    firstNameEn: Yup.string(),
    lastNameEn: Yup.string(),
    email: Yup.string(),
    mobile: Yup.string(),
  });

  const handleEdit = (values) => {
    const includedValues = {
      userId: userDetails?.profile && userDetails?.profile[0]?.userId,
      gender: userDetails?.profile && userDetails?.profile[0]?.gender,
      roleId: userDetails?.profile && userDetails?.profile[0]?.roleId,
      roleName: userDetails?.profile && userDetails?.profile[0]?.roleName,
      signature: userDetails?.profile && userDetails?.profile[0]?.signature,
      isRequirement:
        userDetails?.profile && userDetails?.profile[0]?.isRequirement,
      userName: userDetails?.profile && userDetails?.profile[0]?.userName,
    };
    const valuesTosend = { ...values, ...includedValues };
    editUser(valuesTosend, {
      onSuccess: () => {
        setEditMode(false);
        refetchUser();
        toast.success("Your profile is updated successfully");
      },
      onError: (error, data) => {
        console.error("An error occurred:", error?.response?.data?.message);
        toast.error("Email or username already exists");
      },
    });
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      handleEdit(values);
    },
  });
  return (
    <Modal width='40%' title='Update Profile' onClose={handleOnClose}>
      <ModalChildrenContainer>
        <div className={styles["cont"]}>
          <div className={styles["parent"]}>
            {userDetails?.userImage?.imageContent ? (
              <img
                src={`data:image/jpeg;base64,${userDetails?.userImage?.imageContent}`}
                className={styles["profile"]}
              />
            ) : (
              <img
                src={USerProfileIcon}
                alt='icon'
                className={styles["profile"]}
              />
            )}
            <input
              type='file'
              accept='image/*'
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleImageChange}
            />
            <img
              src={editProfileIcon}
              alt='icon'
              className={styles["child"]}
              onClick={handleSelectImage}
            />
          </div>
        </div>
        <FormikProvider value={formik}>
          <TextInput
            icon={user}
            label='First Name'
            placeholder='First Name'
            name='firstNameEn'
            value={formik.values.firstNameEn}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            errors={formik.errors.firstNameEn}
            touched={formik.touched.firstNameEn}
          />
          <TextInput
            icon={user}
            label='Last Name'
            placeholder='Last Name'
            name='lastNameEn'
            value={formik.values.lastNameEn}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            errors={formik.errors.lastNameEn}
            touched={formik.touched.lastNameEn}
          />
          <TextInput
            icon={emailicon}
            label='Email'
            placeholder='Email'
            name='email'
            value={formik.values.email}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            errors={formik.errors.email}
            touched={formik.touched.email}
          />
          <PhoneNumber
            icon={mobileICon}
            label='Mobile'
            placeholder='Mobile'
            name='mobile'
            value={formik.values.mobile}
            onChange={(name, value) => {
              formik.setFieldValue(name, value);
            }}
            errors={formik.errors.mobile}
            touched={formik.touched.mobile}
          />
          {isEdittingUser ? (
            <LoaderButton />
          ) : (
            <Buttons
              type='submit'
              handleOnClick={formik.handleSubmit}
              text='Save'
            />
          )}
        </FormikProvider>
      </ModalChildrenContainer>
    </Modal>
  );
};

export default UserEditProfilePopup;
