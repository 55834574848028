export const settingsTableHeader = [
  { title: "Name", key: "text" },
  { title: "Created By", key: "userName" },
  { title: "Created on", key: "createdOn", flag: "dateTime" },
];

export const fieldOfInterestTableHeader = [
  { title: "Name", key: "text" },
  { title: "Brochure URL Link", key: "brochureLink", flag: "link" },
  { title: "Application Type", key: "applicationsCS", flag: "appType" },
  { title: "Created By", key: "userName" },
  { title: "Created on", key: "createdOn", flag: "dateTime" },
];
export const academicYearTableHeader = [
  { title: "Name", key: "academicName" },
  { title: "Start Date", key: "startDate", flag: "date" },
  { title: "End Date", key: "endDate", flag: "date" },
  { title: "Active", key: "active", flag: "bolean" },
  { title: "Created By", key: "log", secondKey: "createdByUser" },
  { title: "Created on", key: "log", secondKey: "createdOn", flag: "dateTime" },
];

export const externalContactsTableHeader = [
  { title: "Name", key: "contactName" },
  { title: "Mobile", key: "mobile" },
  { title: "Contact Type", key: "contactType_Display" },
  { title: "Priority", key: "priority_Display" },
];
export const eSignatureHeader = [
  { title: "Name", key: "signatureName" },
  { title: "Created By", key: "log", secondKey: "createdByUser" },
  { title: "Created on", key: "log", secondKey: "createdOn", flag: "dateTime" },
];
