import React, { useEffect, useRef, useState } from "react";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import styles from "../../../Template/SettingsTemplate/SettingsPage.module.css";
import uploadbulk from "../../../../assets/Imagess/bulkicon.svg";
import DeletePopup from "../../Custom/DeletePopup";
import SettingsTopHeader from "../SettingsMolecules/SettingsTopHeader";
import ColoredButton from "../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import TitlePageText from "../../../Atoms/Texts/TitlePageText";
import * as tableHeader from "../../TableHeader/SettingsHeader";

import {
  useDeleteAcademicYear,
  useFetchAcademicYears,
} from "../../../../Hooks/Settings";
import { toast } from "react-toastify";
import AcademicYearsModal from "../SettingsModal/AcademicYearsModal";
import TableComponent from "../../TableComponent/TableComponent";
import PrintComponent from "../../Custom/ExportComponent/PrintComponent";
import { useReactToPrint } from "react-to-print";
const AcademicYearSettings = ({
  title,
  type,
  search,
  setSearch,
  orderDir,
  orderBy,
  setOrderBy,
  setOrderDir,
}) => {
  const printRef = useRef();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [deletMode, setDeleteMode] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const {
    data: academicYears,
    refetch: refetchAcademicYear,
    isLoading: isLoadingAcademicYears,
  } = useFetchAcademicYears({
    search: search,
    orderBy: orderBy,
    orderDir: orderDir,
    type: 20,
  });
  const { mutate: deleteAcademicYear, isLoading: isDeleteAcademicYear } =
    useDeleteAcademicYear();

  const handleAddClick = () => {
    setIsModalOpen(true);
    setModalMode("add");
  };

  const handleEditClick = (e, item) => {
    e.stopPropagation();

    setIsModalOpen(true);
    setModalMode("edit");
    setSelectedItem(item);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedItem({});
  };

  const handleDelete = () => {
    deleteAcademicYear(selectedItem?.acadamicYearId, {
      onSuccess: () => {
        setDeleteMode(false);
        refetchAcademicYear();
        toast.success("Deleted successfully");
        setSelectedItem({});
      },
      onError: (error) => {
        console.error("Error deleting email", error);
        toast.error(
          "This option has been used by an applicant and cannot be deleted."
        );
        setDeleteMode(false);
        setSelectedItem({});
      },
    });
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });
  
  useEffect(() => {
    refetchAcademicYear({
      orderBy: orderBy,
      orderDir: orderDir,
      search: search,
    });
  }, [orderBy, orderDir]);

  const tableData = {
    data: {
      items: academicYears?.data?.map((item) => ({
        ...item,
        id: item.acadamicYearId,
      })),
    },
    refetch: refetchAcademicYear,
    isLoading: isLoadingAcademicYears,
    tableHeader: tableHeader.academicYearTableHeader,
  };

  return (
    <>
      <SettingsTopHeader
        title={title === "External Contact" ? "New Contact" : title}
        type={type}
        search={search}
        data={tableData}
        setSearch={setSearch}
        handleAddClick={handleAddClick}
        handlePrint={handlePrint}
      />

      <TableComponent
        tableData={tableData}
        handleEditClick={handleEditClick}
        handleDelete={(e, item) => (
          setDeleteMode(true), setSelectedItem(item), e.stopPropagation()
        )}
        orderBy={orderBy}
        orderDir={orderDir}
        setOrderBy={setOrderBy}
        setOrderDir={setOrderDir}
        editAdd={true}
      />

      {isModalOpen ? (
        <AcademicYearsModal
          handleOnClose={handleCloseModal}
          type={type}
          title={title}
          mode={modalMode}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          refetch={refetchAcademicYear}

        />
      ) : (
        ""
      )}
      {deletMode && (
        <DeletePopup
          handleOnClose={() => (setDeleteMode(false), setSelectedItem({}))}
          title={title}
          handleDelete={handleDelete}
          isLoading={isDeleteAcademicYear}
        />
      )}
        <div style={{ display: "none" }}>
        <PrintComponent ref={printRef} title={title} data={tableData} />
      </div>
    </>
  );
};

export default AcademicYearSettings;
