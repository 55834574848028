import React from "react";
import styles from "./SourceType.module.css";
import ApexCharts from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";
const SourceTypeChart = () => {
  const chartOptions = {
    chart: {
      type: "pie",
    },
    labels: ["Source of Inquiry", "Applicant/Lead Types"],
    colors: ["#235594", "#358BF0"],
    legend: {
      position: "bottom",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 500,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const chartSeries = [55.6, 44.4];

  return (
    <DashboardContainer>
      <h2>Source vs Type</h2>
      <div className={styles.chart}>
        <ApexCharts
          options={chartOptions}
          series={chartSeries}
          type='pie'
          width='400'
        />
      </div>
    </DashboardContainer>
  );
};

export default SourceTypeChart;
