import React from "react";
import CampaignProgressBarBox from "./CampaignProgressBarBox";
import styles from "./CampaignProgressBarBox.module.css";
import WhiteBoxContainer from "../../../../HOC/WrappingContainer/WhiteBoxContainer";
const CampaignProgressContainer = ({ campaignAudience }) => {
  const progressDetails = [
    {
      type: 0,
      total: campaignAudience?.length,
      firstValue: campaignAudience?.length,
      secondValue: 0,
    },
    {
      type: 1,
      total: 0,
      firstValue: 0,
      secondValue: "",
    },
    {
      type: 2,
      total: 0,
      firstValue: 0,
      secondValue: 0,
    },
    {
      type: 3,
      total: 0,
      firstValue: 0,
      secondValue: 0,
    },
    {
      type: 4,
      total: 0,
    },
  ];
  return (
    <div className={styles["progressBox_container"]}>
      {progressDetails?.map((item) => {
        return (
          <div key={item.type}>
            <CampaignProgressBarBox data={item} />
          </div>
        );
      })}
    </div>
  );
};

export default CampaignProgressContainer;
