import React from "react";
import styles from "./ColoredButton.module.css";

const ColoredButton = ({
  color,
  title,
  handleOnClick,
  icon,
  rounded,
  backgroundColor,
  blue,
  red,
  unfilled,
}) => {
  const cssClassname = blue
    ? "blue_button"
    : red
    ? "red_button"
    : unfilled
    ? "unfilled_button"
    : "";
  return (
    <div
      style={{
        color: color,
        borderRadius: rounded ? "25px" : "0px",
        backgroundColor: backgroundColor,
      }}
      className={`${styles["colored-button"]} ${styles[cssClassname]}`}
      onClick={handleOnClick}
    >
      {icon && <img src={icon} alt='icon' className={styles["image"]} />}
      <h3>{title}</h3>
    </div>
  );
};

export default ColoredButton;
