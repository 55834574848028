import React from "react";
import styles from "./DashboardContainer.module.css";
const DashboardContainer = ({ children, none }) => {
  return (
    <div
      className={styles["pieChart-cont"]}
      style={{
        backgroundColor: none ? "transparent" : "",
        padding: none ? "0px" : "",
      }}
    >
      {children}
    </div>
  );
};

export default DashboardContainer;
