import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

/****************fetch interested */
const fetchInterested = async (data) => {
  let url = `${API_URL}/api/ApplicantView/GetShowingInterest/${data?.pageSize}/${data?.pageNumber}`;

  const queryParams = [];
  if (data?.orderBy) queryParams.push(`OrderBy=${data?.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);
  if (data?.FieldOfInterest)
    queryParams.push(`FieldOfInterest=${data.FieldOfInterest}`);
  if (data?.status || data?.status === 0)
    queryParams.push(`status=${data.status}`);
  if (data?.program || data?.program === 0)
    queryParams.push(`program=${data.program}`);
  if (data?.search) queryParams.push(`SearchBy=${data.search}`);
  if (data?.filterByField && data?.filterBy)
    queryParams.push(`FilterByField=${data.filterByField}`);
  if (data?.filterBy) queryParams.push(`FitlerBy=${data.filterBy}`);
  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }
  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useFetchInterested = (data) => {
  return useQuery({
    queryKey: ["Interested", data],
    queryFn: () => fetchInterested(data),
    enabled: !!data.selected,
  });
};

/****************fetch single interested */

const fetchSingleInterested = async (data) => {
  if (data?.id) {
    return await axios.get(
      API_URL +
        `/api/ApplicantView/GetIntrestedById?ShowingInterestId=${data?.id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};
export const useFetchSingleInterested = (data) => {
  return useQuery({
    queryKey: ["InterestedbyId", data],
    queryFn: () => fetchSingleInterested(data),
    enabled: data?.isInterested,
  });
};

/*******************Get applicant status */
const fetchInterestedStatus = async () => {
  return await axios.get(API_URL + "/api/ApplicantView/GetIntrestedStatuses", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchInterestedStatus = () => {
  return useQuery({
    queryKey: ["interestedtStatus"],
    queryFn: () => fetchInterestedStatus(),
    enabled: true,
  });
};

/***********Export table */
const exportShowingInterest = async () => {
  return await axios.get(API_URL + "/api/Export/ExportShowingInterest", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportShowingInterestTable = () => {
  return useMutation(() => exportShowingInterest());
};
