import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const Consent = ({ applicant, applicationData }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Consent to release Education Records</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title="Authorized Guardian Name"
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.authorize_GuardianName
            }
            required={true}
          />
          <TextContainer
            title="Authorized Guardian Relation"
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]
                ?.authorize_GuardianRelation_Display
            }
            required={true}
          />
          <TextContainer
            title="Address"
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.authorize_Address
            }
            required={true}
          />
          <TextContainer
            title="Telephone"
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.authorize_Telephone
            }
            required={true}
            mobile={true}
          />
        </div>
      </div>
    </div>
  );
};

export default Consent;
