import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//fetch Roles
const fetchRoles = async (data) => {
  let url = `${API_URL}/api/Role/GetRoles`;
  const queryParams = [];

  if (data?.search) queryParams.push(`SearchBy=${data.search}`);
  if (data?.orderBy) queryParams.push(`OrderBy=${data?.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchRoles = (data) => {
  return useQuery({
    queryKey: ["Rolesdata", data],
    queryFn: () => fetchRoles(data),
    enabled: !!data.selected,
  });
};

//fetch Role by id
const fetchRolesById = async (id) => {
  if (id) {
    return axios.get(API_URL + `/api/Role/GetRolesById/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};
export const useFetchRoleById = (id) => {
  return useQuery({
    queryKey: ["rolesById"],
    queryFn: () => fetchRolesById(id),
    enabled: true,
  });
};

//fetch Roles   : page actions
const fetchPageActions = async () => {
  return axios.get(API_URL + `/api/Role/GetPageActions`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchPageActions = () => {
  return useQuery({
    queryKey: ["actionPages"],
    queryFn: () => fetchPageActions(),
    enabled: true,
  });
};

//post an roles
const addRoles = (values) => {
  return axios.post(API_URL + `/api/role/PostRoles`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddRoles = () => {
  return useMutation(addRoles);
};

//assign users to roles
const assignUsersToRoles = (values) => {
  return axios.post(API_URL + `/api/Role/AssignRoleToUsers`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAssignUsersToRolels = () => {
  return useMutation(assignUsersToRoles);
};
//delete roles
const deleteRoles = (roleId) => {
  return axios.delete(API_URL + `/api/Role/DeleteRole/${roleId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteRoles = () => {
  return useMutation(deleteRoles);
};

/***********Export table Roles */
const exportTableRoles = async () => {
  return await axios.get(API_URL + "/api/Export/ExportRoles", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportTableRoles = () => {
  return useMutation(() => exportTableRoles());
};
