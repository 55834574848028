import React from "react";
import CampaignProgressContainer from "./CampaignProgressBarBox/CampaignProgressContainer";
import styles from "./CampaignDashboard.module.css";
import CampaignCharts from "./CampaignCharts/CampaignCharts";
import CampaignNote from "./CampaignNote";
const CampaignDashboard = ({campaignDetails}) => {
  return (
    <div className={styles["dashboard_container"]}>
      <CampaignProgressContainer campaignAudience={campaignDetails?.campaignAudience} />
      <CampaignCharts campaignDetails={campaignDetails}/>
      <CampaignNote campaignDetails={campaignDetails?.campaign && campaignDetails?.campaign[0]} />
    </div>
  );
};

export default CampaignDashboard;
