import React from "react";
import styles from "./TableTopHeader.module.css";
const SelectedNumberComponent = ({ selected }) => {

  return (
    <div className={styles["circle_icon_container"]}>
      <p className={styles["circle_icon"]}>{selected}</p>
      <h5>Selected Applicants</h5>
    </div>
  );
};

export default SelectedNumberComponent;
