import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";

const MailingInfo = ({
  applicant,
  isApplicant,
  applicationData,
  isLoading,
}) => {
  return (
    <div className={styles["personal-container"]}>
      <h3> Mailing Address</h3>
      <div className={styles["personalsub-container"]}>
        <div className={styles["personal-container2"]}>
          <TextContainer
            title="Address"
            data={
              applicant?.addressInfo &&
              applicant?.addressInfo[0]?.address1
            }
            required={true}
          />
          <TextContainer
            title="Country"
            data={
              applicant?.addressInfo &&
              applicant?.addressInfo[0]?.country_Display
            }
            required={true}
          />
          <TextContainer
            title="City/State"
            data={
              applicant?.addressInfo &&
              applicant?.addressInfo[0]?.cityState_Display
            }
            required={true}
          />
          <TextContainer
            title="P.O. Box"
            data={applicant?.addressInfo && applicant?.addressInfo[0]?.pobox}
            required={false}
          />
          <TextContainer
            title="Zip Code"
            data={
              applicant?.addressInfo && applicant?.addressInfo[0]?.zipCode
            }
            required={false}
          />
        </div>
        {applicant?.applications &&
        applicant?.applications[0]?.startYourApplication == 2 ? (
          <div className={styles["personal-container2"]}>
            <TextContainer
              title="Requires Visa"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.studentVisa === true
                  ? "Yes"
                  : "No"
              }
              required={false}
            />
            <TextContainer
              title="UAE/GCC National or UAE Resident"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.uaE_GCC_Resident ===
                  true
                  ? "Yes"
                  : "No"
              }
              required={false}
            />
            <TextContainer
              title="Number of Semesters"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.country_Display
              }
              required={true}
            />
            <TextContainer
              title="On-Campus Housing"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.onHouseCampus === true
                  ? "Yes"
                  : "No"
              }
              required={false}
            />
            <TextContainer
              title="Middle Eastern Studies Certificate"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.middleEasternStudies ===
                  true
                  ? "Yes"
                  : "No"
              }
              required={false}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MailingInfo;
