import React, { useState } from "react";
import NavLink from "../NavHead/Navlinks/NavLink";
import Logo from "../../../assets/Imagess/SidebarIcons/logo.svg";
import expandedLogo from "../../../assets/Imagess/SidebarIcons/expandedLogo.svg";
import styles from "./SideBar.module.css";
import closeIcon from "../../../assets/Imagess/SidebarIcons/collapseSidebarIcon.svg";
import openIcon from "../../../assets/Imagess/SidebarIcons/expandSidebarIcon.svg";
import Tooltip from "../../Atoms/Tooltip/Tooltip";

const SideBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleExpandCollapseSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div
      className={`${styles["sidebar-container"]} ${isOpen ? styles.open : ""}`}
    >
      <div
        className={styles["coll-exp-container"]}
        onClick={handleExpandCollapseSidebar}
      >
        <Tooltip content={isOpen ? "Close" : "Open"} placement='right'>
          <img
            src={isOpen ? closeIcon : openIcon}
            alt='logo'
            className={styles["closeIcon"]}
          />
        </Tooltip>
      </div>
      <img src={isOpen ? expandedLogo : Logo} alt='logo' />
      <NavLink isOpen={isOpen} />
    </div>
  );
};

export default SideBar;
