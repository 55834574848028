import React from "react";
import DashboardContainer from "../../DashboardContainer";
import ApexCharts from "react-apexcharts";

const AboutAudChart = () => {
  const chartOptions = {
    chart: {
      type: "pie",
    },
    labels: [
      "Linkedin",
      "Internet",
      "School Events",
      "Word of Mouth",
      "Linkedin",
      "Alumni Connection",
    ],
    colors: ["#3E36F4", "#7BB1F6", "#FFAE00", "#F3223C", "#358BF0", "#00A900"],
    legend: {
      position: "right",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 400,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const chartSeries = [20, 30, 40, 10, 20, 8];

  return (
    <DashboardContainer>
      <h2>How you heard about AUD?</h2>
      <div style={{ paddingTop: "50px" }}>
        <ApexCharts options={chartOptions} series={chartSeries} type='pie' />
      </div>
    </DashboardContainer>
  );
};

export default AboutAudChart;
