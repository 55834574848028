import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//get field options
const getFieldOptions = async (type) => {
  if (type != undefined) {
    return axios.get(API_URL + `/api/Audience/GetFieldOptions/${type}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};
export const useGetFieldOption = (type) => {
  return useQuery({
    queryKey: ["fieldOptionsByType", type],
    queryFn: () => getFieldOptions(type),
    enabled: true,
  });
};

//get valueAPI
const getValueAPI = async (apiUrl) => {
  if (apiUrl != undefined) {
    return axios.get(`${API_URL}/${apiUrl}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};
export const useGetValueAPI = (apiUrl) => {
  return useQuery({
    queryKey: ["apiUrl", apiUrl],
    queryFn: () => getValueAPI(apiUrl),
    enabled: true,
  });
};

//show results
const showAudienceResult = (fields) => {
  return axios.post(API_URL + `/api/Audience/ShowResult`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useShowAudienceResult = () => {
  return useMutation(showAudienceResult);
};

///post audience
const addNewAudience = (fields) => {
  return axios.post(API_URL + `/api/Audience/Audience`, fields, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddNewAudience = () => {
  return useMutation(addNewAudience);
};

//fetch audience
const fetchAudience = async (data) => {
  let url = `${API_URL}/api/Audience/GetAudience`;

  const queryParams = [];
  if (data?.search) queryParams.push(`SearchBy=${data.search}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchAudience = (data) => {
  return useQuery({
    queryKey: ["audiencedata", data],
    queryFn: () => fetchAudience(data),
    enabled: true,
  });
};

//fetch audience by id
const fechAudienceById = async (id) => {
  if (id) {
    return axios.get(API_URL + `/api/Audience/GetAudienceById/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
  }
};
export const useFetchAudienceById = (id) => {
  return useQuery({
    queryKey: ["audiencedataById", id],
    queryFn: () => fechAudienceById(id),
    enabled: true,
  });
};

//delete audience by id
const deleteAudiencebyId = (audeieceId) => {
  return axios.delete(API_URL + `/api/Audience/DeleteAudience/${audeieceId}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useDeleteAudiencebyId = () => {
  return useMutation(deleteAudiencebyId);
};

//*********export audience table */
const exportAudience = async () => {
  return await axios.get(API_URL + "/api/Export/ExportAudience", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportAudienceTable = () => {
  return useMutation(() => exportAudience());
};
