import React from "react";
import styles from "../Custom/StatusContainer.module.css";

const StatusContainer = ({ status }) => {
  const calssStatus = (status) => {
    switch (status) {
      case "Prospect":
        return "prospect";
      case "Not Interested":
        return "not-interested";
      case "Canceled":
        return "not-interested";
      case "Stopped":
        return "not-interested";
      case "Interested":
        return "interested";
      case "active":
        return "interested";
      case "Converted":
      case "4":
        return "converted";
      case "Applicant":
        return "applicant";
      case "All Statuses":
        return "allStatuses";
      case "Cancelled":
        return "cancelled";
      case "Under Process":
        return "underProcess";
      case "On Hold":
        return "underProcess";
      case "Ready to Launch":
        return "Launch";
      case "Launched":
        return "interested";
      case "UnSubscribed":
        return "UnSubscribed";
      default:
        return "";
    }
  };
  const classCircle = (status) => {
    switch (status) {
      case "Prospect":
        return "prospectCircle";
      case "Not Interested":
        return "not-interestedCircle";
      case "Canceled":
        return "not-interestedCircle";
      case "Stopped":
        return "not-interestedCircle";
      case "Interested":
        return "interestedCircle";
      case "active":
        return "interestedCircle";
      case "Converted":
      case "4":
        return "convertedCircle";
      case "All Statuses":
        return "allStatusesCircle";
      case "Applicant":
        return "applicantCircle";
      case "Cancelled":
        return "cancelledCircle";
      case "Under Process":
        return "underProcessCircle";
      case "On Hold":
        return "underProcessCircle";
      case "Ready to Launch":
        return "LaunchCircle";
      case "Launched":
        return "interestedCircle";
      case "UnSubscribed":
        return "unSubscribedCircle";
      default:
        return "";
    }
  };
  const capitalizedStatus =
    status?.charAt(0)?.toUpperCase() + status?.slice(1) || null;
  return (
    <span className={`${styles["status"]} ${styles[calssStatus(status)]}`}>
      <span
        className={`${styles["circle"]} ${styles[classCircle(status)]}`}
      ></span>
      {capitalizedStatus}
    </span>
  );
};

export default StatusContainer;
