import React, { forwardRef } from "react";
import TableComponent from "../../TableComponent/TableComponent";
import styles from "./ExportComponent.module.css";

const PrintComponent = forwardRef(({ title, data, children }, ref) => {
  return (
    <div ref={ref} className={styles.export_container}>
      {data ? (
        <>
          {" "}
          <h1>{title}</h1>
          <TableComponent tableData={data} noScroll />
        </>
      ) : (
        children
      )}

      {/* {data?.data?.pageSize ? (
        <>
          <p>
            Page size: <span>{data?.data?.pageSize}</span>
          </p>
          <p>
            Total: <span>{data?.data?.totalCount}</span>
          </p>
          <p>
            Total Pages:{" "}
            <span>
              {Math.ceil(data?.data?.totalCount / data?.data?.pageSize)}
            </span>
          </p>
        </>
      ) : (
        ""
      )} */}
    </div>
  );
});

export default PrintComponent;
