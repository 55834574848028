export const audience = [
  {
    title: "Audience Name",
    key: "audienceName",
  },
  {
    title: "Campaign Name",
    key: "campaignNames",
  },
];
