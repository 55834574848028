import React from "react";
import { Quill } from "react-quill";
import { useFetchNotificationOptions } from "../../../Hooks/Notification";

const Size = Quill.import("formats/size");
Size.whitelist = ["extra-small", "small", "medium", "large"];
Quill.register(Size, true);

const Font = Quill.import("formats/font");
Font.whitelist = [
  "arial",
  "comic-sans",
  "courier-new",
  "georgia",
  "helvetica",
  "lucida",
];
Quill.register(Font, true);

function insertCustomTags(args) {
  const value = args;
  const cursorPosition = this.quill.getSelection().index;
  this.quill.insertText(cursorPosition, value);
  this.quill.setSelection(cursorPosition + value.length);
}

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
      insertCustomTags: insertCustomTags,
    },
  },
};

export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
];

// Quill Toolbar component
export const QuillToolbar = ({ setVariable, variable }) => {
  const { data: notificationOptions } = useFetchNotificationOptions();
  console.log("sdvjksdhnvkdsh", notificationOptions);
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    if (selectedValue) {
      setVariable(selectedValue);

      const range = window.quill.getSelection();
      if (range) {
        window.quill.insertText(range.index, selectedValue);
        window.quill.setSelection(range.index + selectedValue.length);
      }
    }
  };

  return (
    <div id='toolbar'>
      <span className='ql-formats'>
        <select className='ql-font'>
          <option value='arial' selected>
            Arial
          </option>
          <option value='comic-sans'>Comic Sans</option>
          <option value='courier-new'>Courier New</option>
          <option value='georgia'>Georgia</option>
          <option value='helvetica'>Helvetica</option>
          <option value='lucida'>Lucida</option>
        </select>
        <select className='ql-size' defaultValue='medium'>
          <option value='extra-small'>Small</option>
          <option value='small'>Normal</option>
          <option value='medium'>Medium</option>
          <option value='large'>Large</option>
          <option value='huge'>Huge</option>
        </select>
        {/* <select className='ql-header' defaultValue='3'>
          <option value='1'>Heading</option>
          <option value='2'>Subheading</option>
          <option value='3'>Normal</option>
        </select> */}
      </span>

      <span className='ql-formats'></span>
      <span className='ql-formats'>
        <button className='ql-bold' />
        <button className='ql-italic' />
        <button className='ql-underline' />
        <button className='ql-strike' />
      </span>
      <span className='ql-formats'>
        <button className='ql-link' />
        <button className='ql-image' />
      </span>
      <span className='ql-formats'>
        <button className='ql-list' value='ordered' />
        <button className='ql-list' value='bullet' />
        <button className='ql-indent' value='-1' />
        <button className='ql-indent' value='+1' />
      </span>
      <span className='ql-formats'>
        <button className='ql-script' value='super' />
        <button className='ql-script' value='sub' />
      </span>
      <span className='ql-formats'>
        <select className='ql-align' />
      </span>
      <span className='ql-formats'>
        <select
          className='ql-insertCustomTags'
          defaultValue='Add Variable'
          onChange={(e) => handleSelectChange(e)}
          value={variable}
          placeholder='Add Variable'
        >
          <option value='Add Variable'>Add Variable</option>
          {notificationOptions?.data?.map((item) => {
            return (
              <option key={item.value} value={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
      </span>
    </div>
  );
};
