// QueryBar.js
import React, { useEffect, useState } from "react";
import { useGetFieldOption } from "../../../../Hooks/Audience";
import Queries from "./Queries";

const QueryBar = ({
  disabled,
  queryType,
  setQueryState,
  fetchedQueries,
  isReset,
}) => {
  const [queries, setQueries] = useState([
    { andOr: true, audienceOptionId: "", inNotIn: "", valueIds: "" },
  ]);
  const { data: fieldsOptionsById, refetch: refetchFieldsOptionsById } =
    useGetFieldOption(queryType);

  useEffect(() => {
    if (fetchedQueries?.length > 0) {
      setQueries(fetchedQueries);
    }
  }, [fetchedQueries, queryType]);

  useEffect(() => {
    if (queryType != null && !isReset) {
      setQueryState((prevState) => ({
        ...prevState,
        [queryType]: queries,
      }));
    }
  }, [queries]);

  useEffect(() => {
    if (isReset) {
      setQueryState((prevState) => ({
        ...prevState,
        [queryType]: [],
      }));
    }
  }, [isReset]);

  useEffect(() => {
    if (isReset) {
      setQueries([
        { andOr: true, audienceOptionId: "", inNotIn: "", valueIds: "" },
      ]);
    }
  }, [isReset]);
  const handleUpdateQuery = (index, field, value) => {
    const updatedQueries = [...queries];
    updatedQueries[index][field] = value.value;
    if (field === "audienceOptionId") {
      updatedQueries[index].valueIds = "";
    }
    setQueries(updatedQueries);
  };
  const handleAddQuery = () => {
    setQueries((prevQueries) => [
      ...prevQueries,
      { andOr: "", audienceOptionId: "", inNotIn: "", valueIds: "" },
    ]);
  };

  const handleRemoveQuery = (index) => {
    const updatedQueries = queries.filter((_, i) => i !== index);
    setQueries(updatedQueries);
  };

  const handleValueIdsChange = (selectedValues, index) => {
    const formattedIds = selectedValues
      ?.map((value) => `'${value.value}'`)
      .join(", ");
    const updatedQueries = [...queries];
    updatedQueries[index].valueIds = formattedIds;
    setQueries(updatedQueries);
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      {queries.map((query, index) => {
        return (
          <Queries
            key={index}
            index={index}
            query={query}
            fieldsOptionsById={fieldsOptionsById}
            handleRemoveQuery={handleRemoveQuery}
            handleValueIdsChange={handleValueIdsChange}
            handleAddQuery={handleAddQuery}
            handleUpdateQuery={handleUpdateQuery}
            disabled={disabled}
            queries={queries}
          />
        );
      })}
    </div>
  );
};

export default QueryBar;
