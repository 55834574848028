import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styles from "./Input.module.css";
import dropdownicon from "../../../assets/Imagess/dropdownicon.svg";
import CheckBox from "./CheckBox";
import { toast } from "react-toastify";
import Spinner from "../../Moecules/Loader/Spinner";

const CustomizedSelectwithCheckbox = ({
  options,
  onChange,
  value,
  name,
  placeholder,
  icon,
  label,
  errors,
  touched,
  required,
  height,
  disabled,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [selectedValues, setSelectedValues] = useState(value || []);
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  const handleOpenDropDowOptions = () => {
    if (disabled) {
      setIsOpen(false);
      toast.warning("Please choose a field before");
    } else {
      setIsOpen(!isOpen);
    }
  };

  const handleToggleMenu = (e) => {
    if (options && !options.length) {
      return false;
    }
    e.stopPropagation();
    setIsOpen(!isOpen);
    document.body.style.overflow = !isOpen ? "hidden" : "";
  };
  const handleCheckboxClick = (e, option) => {
    setChecked(
      selectedValues?.some(
        (selectedOption) => selectedOption.value === option.value
      )
    );
    e.stopPropagation();
  };

  const toggleCheckUser = (option) => {
    const updatedValues = selectedValues.some(
      (selectedOption) => selectedOption.value === option.value
    )
      ? selectedValues.filter(
          (selectedOption) => selectedOption.value !== option.value
        )
      : [...selectedValues, option];

    setChecked(
      updatedValues.some(
        (selectedOption) => selectedOption.value === option.value
      )
    );
    setSelectedValues(updatedValues);
    if (name) {
      onChange(name, updatedValues);
    } else {
      onChange(updatedValues);
    }
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (selectRef.current && !selectRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (options) {
      if (typeof value === "string" && value.length > 0) {
        const selected = value?.split(",").map((val) => {
          const option = options?.find(
            (opt) => opt.value === val.trim().replace(/'/g, "")
          );
          return {
            value: option ? option.value : val.trim().replace(/'/g, ""),
            text: option ? option.text : val.trim().replace(/'/g, ""),
          };
        });
        setSelectedValues(selected);
      }
    }
  }, [value, options]);

  useEffect(() => {
    if (typeof value === "string" && value !== undefined) {
      setSelectedValues([]);
    }
  }, [value]);

  const renderDropdown = () => (
    <>
      {" "}
      <div className={`${styles.backdrop}`} onClick={handleToggleMenu}></div>
      <div
        className={styles.dropdown}
        style={{
          position: "absolute",
          top: dropdownRef.current?.getBoundingClientRect().bottom || 0,
          left: dropdownRef.current?.getBoundingClientRect().left || 0,
          width: dropdownRef.current?.offsetWidth || "auto",
        }}
      >
        {options && options.length > 0 ? (
          options.map((option, index) => (
            <div
              key={index}
              className={
                index % 2 === 0 ? styles.dropdownItem : styles.dropdownItemOdd
              }
            >
              <div
                className={styles.dropdowncontainer}
                onClick={(e) => handleCheckboxClick(e, option)}
              >
                <CheckBox
                  value={selectedValues
                    ?.map((item) => item.value)
                    .includes(option.value)}
                  onChange={() => toggleCheckUser(option)}
                />
                {option.text}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noData}>No available data</div>
        )}
      </div>
    </>
  );

  return (
    <div className={styles.selectContainer}>
      {label ? (
        <label>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      ) : (
        ""
      )}
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          ref={(el) => {
            selectRef.current = el;
            dropdownRef.current = el;
          }}
          className={`${
            errors && touched ? styles.error : styles.selectInput
          } ${isOpen ? styles.open : ""}`}
          onClick={handleOpenDropDowOptions}
          style={{
            height: height ? height : "",
          }}
        >
          {Array.isArray(selectedValues) && selectedValues.length > 0 ? (
            <span
              className={styles.selectedValues}
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                maxWidth: "200px",
              }}
            >
              {selectedValues &&
                selectedValues.length > 0 &&
                selectedValues?.map((item) => item.text).join(", ")}
            </span>
          ) : (
            <div className={styles.selectedValueicont}>
              <span className={styles.placeholder}>
                {icon && <img src={icon} alt='icon' />}
                {placeholder}
              </span>
              {!disabled && (
                <img
                  src={dropdownicon}
                  alt='dropdown icon'
                  className={styles.arrowIcon}
                />
              )}
            </div>
          )}
        </div>
      )}
      {isOpen && ReactDOM.createPortal(renderDropdown(), document.body)}
    </div>
  );
};

export default CustomizedSelectwithCheckbox;
