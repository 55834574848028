import React, { useState, useEffect, useRef } from "react";
import styles from "./Input.module.css";
import dropdownicon from "../../../assets/Imagess/dropdownicon.svg";
import StatusContainer from "../../Moecules/Custom/StatusContainer";

const CustomizedSerachSelect = ({
  options,
  value,
  onChange,
  placeholder,
  icon,
  noborder,
  label,
  status,
  errors,
  touched,
  name,
  required,
  grey,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedText, setSelectedText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedIndex, setSelectedIndex] = useState(0);
  const selectRef = useRef(null);
  const dropdownRef = useRef(null);

  const filteredOptions = options
    ? options.filter((option) =>
        option.text.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : [];

  useEffect(() => {
    const handleDocumentKeyDown = (e) => {
      if (isOpen && e.key !== "Escape") {
        switch (e.key) {
          case "ArrowUp":
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
              prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
            );
            dropdownRef.current.scrollTop -= 32;
            break;
          case "ArrowDown":
            e.preventDefault();
            setSelectedIndex((prevIndex) =>
              prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
            );
            dropdownRef.current.scrollTop += 32;
            break;
          case "Enter":
            e.preventDefault();
            handleSelect(e, filteredOptions[selectedIndex]);
            break;
          case "Backspace":
            e.preventDefault();
            setSearchQuery((prev) => prev.slice(0, -1));
            break;
          default:
            if (
              [
                "Shift",
                "Control",
                "Meta",
                "Tab",
                "CapsLock",
                "ArrowLeft",
                "ArrowRight",
                "NumLock",
                "Alt",
                "Delete",
              ].includes(e.key)
            ) {
              return;
            }

            setSearchQuery((prev) => prev + e.key);
            break;
        }
      }
    };

    document.addEventListener("keydown", handleDocumentKeyDown);

    return () => {
      document.removeEventListener("keydown", handleDocumentKeyDown);
    };
  }, [isOpen, filteredOptions, selectedIndex]);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (selectRef.current && !selectRef.current?.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleDocumentClick);

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleSelect = (e, selectedValue) => {
    e.stopPropagation();
    setSelectedIndex(0);
    setSelectedValue(selectedValue);
    setSelectedText(selectedValue?.text);
    if (name) {
      onChange(name, selectedValue.ddid);
    } else {
      onChange(selectedValue.ddid);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    setSearchQuery("");
  }, [isOpen]);

  return (
    <div className={styles.selectContainer}>
      {label ? (
        <label>
          {label} {required && <span className={styles.required}>*</span>}
        </label>
      ) : (
        ""
      )}
      <div
        ref={selectRef}
        className={`${
          noborder
            ? styles.selectInpuNoBorders
            : errors && touched
            ? styles.error
            : styles.selectInput
        } ${isOpen ? styles.open : ""} `}
        onClick={() => setIsOpen(!isOpen)}
        style={{
          borderColor:
            errors && touched ? "#F3223C" : grey ? "#1b224c3b" : "#1b224c",
        }}
      >
        <div className={styles.selectedValueicont}>
          {isOpen ? (
            <input
              type='text'
              placeholder='Search...'
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className={styles.searchInput}
            />
          ) : (
            <span className={styles.placeholder}>
              {icon && <img src={icon} alt='icon' />}
              {selectedText ? selectedText : placeholder}
            </span>
          )}

          <img
            src={dropdownicon}
            alt='dropdown icon'
            className={styles.arrowIcon}
          />
        </div>
      </div>
      {isOpen && (
        <div
          className={styles.dropdown}
          ref={dropdownRef}
          style={{ position: "absolute" }}
        >
          {filteredOptions.length > 0 ? (
            <>
              {" "}
              {filteredOptions.map((option, index) => (
                <div
                  key={option.value}
                  className={
                    index % 2 === 0
                      ? styles.dropdownItem
                      : styles.dropdownItemOdd
                  }
                  onClick={(e) => handleSelect(e, option)}
                  // Highlight the selected index
                  style={{
                    backgroundColor:
                      index === selectedIndex ? "#f0f0f0" : "transparent",
                  }}
                >
                  {status ? (
                    <StatusContainer status={option?.text} />
                  ) : (
                    <div className={styles.dropdowncontainer}>
                      {option?.text}
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div className={styles.dropdownItemOdd}>No available data</div>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomizedSerachSelect;
