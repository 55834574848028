import React from "react";
import WhiteBoxContainer from "../../../HOC/WrappingContainer/WhiteBoxContainer";

const CampaignNote = ({ campaignDetails }) => {
  return (
    <WhiteBoxContainer>
      <h1>Note</h1>
      <h2>{campaignDetails?.notes}</h2>
    </WhiteBoxContainer>
  );
};

export default CampaignNote;
