import React from "react";
import styles from "./Input.module.css";
const CheckBox = ({
  text,
  value,
  onChange,
  errors,
  touched,
  disabled,
  fontWeight,
}) => {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        gap: "10px",
        // paddingTop: "5px",
      }}
    >
      <label
        className={styles["checkbox-container"]}
        style={{
          marginTop: "-16px",
          fontWeight: "400",
        }}
      >
        <input
          type='checkbox'
          checked={value}
          disabled={disabled}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span
          className={
            styles[`${errors && touched ? "red-checkmark " : "checkmark"}`]
          }
        ></span>
        <p
          style={{
            fontWeight: fontWeight ? fontWeight : "600",
          }}
        >
          {text}
        </p>
      </label>
    </div>
  );
};

export default CheckBox;
