import React from "react";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
import styles from "./GenderGraph.module.css";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";

const GenderGraph = () => {
  const options = {
    chart: {
      type: "donut",
    },
    plotOptions: {
      pie: {
        startAngle: -90,
        endAngle: 90,
        offsetY: 10,
        donut: {
          size: "50%",
        },
      },
    },
    colors: ["#007FD9", "#F3223C"],
    dataLabels: {
      enabled: true,
    },
    fill: {
      // type: "gradient",
    },
    // stroke: {
    //   lineCap: "butt",
    // },
    legend: {
      position: "bottom",
    },
    labels: ["Male", "Female"],
  };

  const series = [44, 55];

  return (
    <DashboardContainer>
      <h2>Gender</h2>
      <div>
        <Chart options={options} series={series} type='donut' />
      </div>
    </DashboardContainer>
  );
};

export default GenderGraph;
