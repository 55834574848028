import React, { useEffect, useState } from "react";
import Buttons from "../../Atoms/Buttons/Buttons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Modal from "../../Atoms/Modal/Modal";
import ModalChildrenContainer from "../../Atoms/Modal/ModalChildrenContainer";
import TextInput from "../../Atoms/Inputs/TextInput";
import folder from "../../../assets/Imagess/folderICon.svg";
import {
  useAddNewAudience,
  useFetchAudienceById,
} from "../../../Hooks/Audience";
import { toast } from "react-toastify";
import LoaderButton from "../Loader/LoaderButton";
import Loader from "../Loader/Loader";
import { useNavigate, useParams } from "react-router-dom";

const DuplicateModal = ({
  handleOnClose,
  selectedAudience,
  setSelectedAudience = null,
  refetchAudience = null,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [mappedQuerry, setMappedQuery] = useState({
    prospectFilters: [],
    leadFilters: [],
    externalContactFilters: [],
  });
  const { data: audienceByIdData, isLoading: isAudienceLoading } =
    useFetchAudienceById(selectedAudience?.campaignAudienceId);

  const { mutate: addNewAudience, isLoading: isAddingAudience } =
    useAddNewAudience();

  useEffect(() => {
    const mappedQueryState = {
      prospectFilters: audienceByIdData?.data?.audienceDetails
        ?.filter((item) => item.audienceType == 0)
        ?.map((item) => {
          return {
            andOr: item.andOr == 0 ? true : false,
            audienceOptionId: item.optionId,
            inNotIn: item.operatorId == 0 ? true : false,
            valueIds: item.valueId,
          };
        }),
      leadFilters: audienceByIdData?.data?.audienceDetails
        ?.filter((item) => item.audienceType == 1)
        ?.map((item) => {
          return {
            andOr: item.andOr == 0 ? true : false,
            audienceOptionId: item.optionId,
            inNotIn: item.operatorId == 0 ? true : false,
            valueIds: item.valueId,
          };
        }),
      externalContactFilters: audienceByIdData?.data?.audienceDetails
        ?.filter((item) => item.audienceType == 2)
        ?.map((item) => {
          return {
            andOr: item.andOr == 0 ? true : false,
            audienceOptionId: item.optionId,
            inNotIn: item.operatorId == 0 ? true : false,
            valueIds: item.valueId,
          };
        }),
    };

    setMappedQuery(mappedQueryState);
  }, [audienceByIdData]);

  const initialValues = {
    audienceTitle: "",
  };

  const validationSchema = Yup.object().shape({
    audienceTitle: Yup.string().required("audienceTitle is required"),
  });

  const handleAddAudience = (values) => {
    addNewAudience(
      {
        campaignAudienceId: "00000000-0000-0000-0000-000000000000",
        audienceName: values.audienceTitle,
        audienceFilters: mappedQuerry,
      },
      {
        onSuccess: () => {
          toast.success(`${values.audienceTitle} is added successfully`);
          if (id) {
            navigate("/home/campaign-audience");
          }
          handleOnClose();
          setSelectedAudience({});
          refetchAudience();
        },
      }
    );
  };

  if (isAudienceLoading) {
    return <Loader />;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleAddAudience(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <Modal
              title='Duplicate Audience'
              width='40%'
              onClose={handleOnClose}
            >
              <ModalChildrenContainer>
                <TextInput
                  label='Audience Title'
                  placeholder='Enter Title'
                  icon={folder}
                  name='audienceTitle'
                  value={values.audienceTitle}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.audienceTitle}
                  touched={touched.audienceTitle}
                />
                {isAddingAudience ? (
                  <LoaderButton />
                ) : (
                  <Buttons
                    text='Save'
                    type='submit'
                    handleOnClick={handleSubmit}
                  />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default DuplicateModal;
