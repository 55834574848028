import React from "react";
import styles from "./HorizantalLine.module.css";
const HorizantalLine = () => {
  return (
    <div className={styles["horizontal-container"]}>
      <div className={styles["horizontal"]}></div>
    </div>
  );
};

export default HorizantalLine;
