import React, { useState } from "react";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import SingleCampaignHeader from "../../../Moecules/SingleCampaign/SingleCampaignHeader";
import SingleTabGroup from "../../../Moecules/SingleApplicant/SingleTabGroup";
import CampaignDashboard from "../../../Moecules/SingleCampaign/CampaignDashboard/CampaignDashboard";
import CampaignAudience from "../../../Moecules/SingleCampaign/CampaignAudience/CampaignAudience";
import EmailWorkflow from "../../../Moecules/SingleCampaign/EmailWorkflow/EmailWorkflow";
import CampaignLogs from "../../../Moecules/SingleCampaign/CampaignLogs/CampaignLogs";
import { useParams } from "react-router-dom";
import { useFetchCampaignById } from "../../../../Hooks/Campaign";
import Loader from "../../../Moecules/Loader/Loader";

const SingleCampaignPage = () => {
  const { id } = useParams();

  const [selected, setSelected] = useState(0);

  const {
    data: campaignById,
    isLoading: isLoadingCampaignById,
    refetch: refetchCampaign,
  } = useFetchCampaignById(id);

  const tabContainer = [
    {
      text: "Details",
      value: 0,
      component: <CampaignDashboard campaignDetails={campaignById?.data} />,
    },
    {
      text: "Audience",
      value: 1,
      component: (
        <CampaignAudience
          campaignAudience={campaignById?.data?.campaignAudience}
          id={
            campaignById?.data?.campaign &&
            campaignById?.data?.campaign[0]?.campaignId
          }
        />
      ),
    },
    {
      text: "Email Workflow",
      value: 2,
      component: <EmailWorkflow campaignDetails={campaignById?.data} />,
    },
    {
      text: "Logs",
      value: 3,
      component: (
        <CampaignLogs campaignDetails={campaignById?.data?.campaignLogs} />
      ),
    },
  ];

  if (isLoadingCampaignById) {
    return <Loader />;
  }
  return (
    <WrappingContainer>
      <SingleCampaignHeader
        campaignDetails={
          campaignById?.data?.campaign && campaignById?.data?.campaign[0]
        }
        refetch={refetchCampaign}
      />
      <SingleTabGroup
        tabContainer={tabContainer}
        selected={selected}
        setSelected={setSelected}
      />

      {tabContainer.find((tab) => tab.value === selected)?.component}
    </WrappingContainer>
  );
};

export default SingleCampaignPage;
