import React, { useEffect, useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "./Input.module.css";
import mobileIcon from "../../../assets/Imagess/mobileInputIcon.png";
const PhoneNumber = ({
  label,
  required,
  name,
  value,
  onChange,
  width,
  errors,
  touched,
  validate,
  disabled,
  initialCountry,
  icon,
  placeholder,
}) => {
  const [initialValue, setInitialValue] = useState(value);
  const [initialCode, setInitialCode] = useState(initialCountry || "ae");
  const inputStyle = {
    width: "100%",
    border: errors && touched ? "1px solid red" : "",
  };

  const handlePhoneChange = (phone) => {
    setInitialValue(phone);
    onChange(name, phone);
  };

  const handleValidate = (value) => {
    const isValid = isValidPhoneNumber(value);

    return isValid;
  };

  useEffect(() => {
    setInitialValue(value);
  
  }, [value]);

  useEffect(() => {
    setInitialCode(initialCountry);
  }, [initialCountry]);



  return (
    <div className={styles["mobile-container"]}>
      {label ? (
        <label
          htmlFor={name}
          style={{ color: "#1B224C", opacity: disabled ? "0.5" : "1" }}
        >
          {label}
          {required ? <span className={styles["required"]}>*</span> : ""}
        </label>
      ) : (
        ""
      )}

      <div
        className={styles["mobile-subcontainer"]}
        style={{
          borderColor: disabled
            ? "#1b224c3b"
            : errors && touched
            ? "#F3223C"
            : "#1b224c",
        }}
      >
        <img src={mobileIcon} alt='mobile' />
        <div style={{ color: disabled ? "#1b224c3f" : "#1B224C" }}>
          <PhoneInput
            country={"ae"}
            placeholder={placeholder}
            countryCodeEditable={true}
            value={initialValue}
            onChange={handlePhoneChange}
            autoComplete='off'
            required={required}
            inputStyle={inputStyle}
            // isValid={(value, country) => {
            //   return validate(value, country);
            // }}
            disabled={disabled ? true : false}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneNumber;
