import React from "react";
import styles from "./TitlePageText.module.css";
import toLeft from "../../../assets/Imagess/toLeft.svg";
const TitlePageText = ({ title, arrow, handleOnClick }) => {
  return (
    <div className={styles["title-page"]}>
      {arrow && <img src={toLeft} alt='toleft' onClick={handleOnClick} />}
      <h2>{title}</h2>
    </div>
  );
};

export default TitlePageText;
