import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

const login = (values) => {
  return axios.post(API_URL + `/api/Auth/login`,values);
};

export const useLogin = () => {
  return useMutation(login);
};
