import React from "react";
import styles from "./WrappingContainer.module.css";
const WhiteContainer = ({
  children,
  white,
  noPadding,
  center,
  maxHeight,
  height,
}) => {
  return (
    <div
      className={`${styles["white-container"]} ${
        maxHeight ? styles.maxHeight : ""
      }`}
      style={{
        backgroundColor: white ? "#fff" : "",
        padding: noPadding ? "0" : "",
        alignItems: center ? "center" : "",
        height: height ? height : "",
      }}
    >
      {children}
    </div>
  );
};

export default WhiteContainer;
