import React from "react";
import styles from "./ApplicantsTab.module.css";

const ApplicantsTab = ({ active, text, handleOnClick, icon, number }) => {
  const tabClass = active ? "active-tab" : "inactive-tab";
  const classFont = active ? "active-circle" : "inactive-circle";
  const tabImage = active ? "activeTabImage" : "";

  return (
    <div
      className={`${styles["applicant-tab"]} ${styles[tabClass]}`}
      onClick={handleOnClick}
    >
      {icon && <img src={icon} alt='icon' className={` ${styles[tabImage]}`} />}
      <h4>{text}</h4>
      {number >= 0 && active && (
        <div className={`${styles["circle"]} ${styles[classFont]}`}>
          <p>{number > 999 ? "1k+" : number}</p>
        </div>
      )}
    </div>
  );
};

export default ApplicantsTab;
