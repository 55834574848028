import React from "react";
import Chart from "react-apexcharts";
const RateChart = ({ percentage, color }) => {
  const options = {
    chart: {
      height: 100,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 15,
          size: "40%",
        },
        dataLabels: {
          showOn: "always",
          name: {
            // offsetY: -10,
            show: false,
            color: "#888",
            fontSize: "13px",
          },
          value: {
            color: "#111",
            fontSize: "15px",
            show: true,
            offsetY: 6,
          },
        },
      },
    },
    stroke: {
      lineCap: "round",
    },
    colors: [color],
  };

  const series = [percentage];

  return (
    <div id='chart'>
      <Chart options={options} series={series} type='radialBar' height={130} />
    </div>
  );
};

export default RateChart;
