import React from "react";
import styles from "./WrappingContainer.module.css";
const WrappingContainer = ({ children, none }) => {
  return (
    <div
      className={styles["wrapping-container"]}
      style={{ padding: none ? "0px" : "" }}
    >
      {children}
    </div>
  );
};

export default WrappingContainer;
