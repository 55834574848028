import React from "react";
import WhiteBoxContainer from "../../../../HOC/WrappingContainer/WhiteBoxContainer";
import TitlePageHeader from "../../../Settings/SettingsMolecules/TitlePageHeader";
import runIcon from "../../../../../assets/Imagess/Users/UsersAssignment/runIcon.svg";
import saveIcon from "../../../../../assets/Imagess/Users/UsersAssignment/save.svg";
import ColoredButton from "../../../../Atoms/Buttons/ColoredButtons/ColoredButton";
import Stepper from "../Stepper/Stepper";
import { useNavigate } from "react-router-dom";
import styles from "./CampaignHeader.module.css";
const Header = ({ steps, activeStep, setActiveStep, handleNavigation }) => {
  const navigate = useNavigate();
  const secondButton = (
    <ColoredButton blue title='Save & Continue' icon={saveIcon} rounded />
  );

  return (
    <WhiteBoxContainer>
      <div className={styles["mobile_header"]}>
        {" "}
        <TitlePageHeader
          title='Create New Campaign'
          buttonText={activeStep == 2 ? "Save" : "Next"}
          handleOnClick={handleNavigation}
          icon={runIcon}
          // secondButton={secondButton}
        />
      </div>

      <Stepper steps={steps} activeStep={activeStep} />
    </WhiteBoxContainer>
  );
};

export default Header;
