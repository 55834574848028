import React from "react";
import styles from "./CampaignCharts.module.css";
import ColumnChart from "./ColumnChart";
import StackedBarChart from "./StackedBarChart";
const CampaignCharts = ({ campaignDetails }) => {
  return (
    <div className={styles["chart-div"]}>
      <ColumnChart campaignDetails={campaignDetails} />
      <StackedBarChart groupedInterested={campaignDetails?.groupedInterested} />
    </div>
  );
};

export default CampaignCharts;
