import React, { useState, useEffect } from "react";
import styles from "./EmailContent.module.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import { QuillToolbar, modules, formats } from "./QuillToolBar";
const EmailTextEditor = ({
  name,
  value,
  onChange,
  errors,
  touched,
  label = "Email Content",
}) => {
  const [editorContent, setEditorContent] = useState(value || "");
  const [variable, setVariable] = useState("");

  const handleEditorChange = (content) => {
    setEditorContent(content);
    onChange(name, content);
    setVariable("");
  };

  useEffect(() => {
    setEditorContent((prev) => prev + variable);
  }, [variable]);

  // const modules = {
  //   toolbar: [
  //     [{ font: [] }],
  //     [{ align: [] }],
  //     [{ size: [] }],
  //     ["bold", "italic", "underline", "strike"],
  //     [{ color: [] }, { background: [] }],
  //     ["blockquote"],
  //     [
  //       { list: "ordered" },
  //       { list: "bullet" },
  //       { indent: "-1" },
  //       { indent: "+1" },
  //     ],
  //     ["link"],
  //     [{ "custom-button": "Add Item" }],
  //   ],
  // };
  const options = [
    { value: "[Student Name]", text: "Student Name" },
    { value: "[Last Name]", text: "Last Name" },
    { value: "[email]", text: "email" },
  ];

  return (
    <div className={styles["email-content"]}>
      <h4>
        {label} <span>{errors && touched ? errors : ""}</span>
      </h4>
      {/* <div style={{ width: "25%" }}>
        <CustomizedSelect
          options={options}
          value={variable}
          placeholder='Select a variable'
          onChange={(selectedValue) => setVariable(() => selectedValue.value)}
        />
      </div> */}
      <div>
        <QuillToolbar setVariable={setVariable} variable={variable} />
        <ReactQuill
          theme='snow'
          value={editorContent}
          onChange={handleEditorChange}
          modules={modules}
          formats={formats}
        />
      </div>
    </div>
  );
};

export default EmailTextEditor;
