import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//add external contact
const addExternalContact = (values) => {
  return axios.post(API_URL + `/api/ExternalContact/ExternalContact`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddExternalContact = () => {
  return useMutation(addExternalContact);
};

//get External Contact
const fetchExternalContact = async (data) => {
  let url = `${API_URL}/api/ExternalContact/GetExternalContacts/${data?.pageSize}/${data?.pageNumber}/${data?.isBlock}`;
  const queryParams = [];
  if (data?.orderBy) queryParams.push(`OrderBy=${data.orderBy}`);
  if (data?.orderDir) queryParams.push(`orderDir=${data.orderDir}`);
  if (data?.search) queryParams.push(`SearchBy=${data.search}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchExternalContact = (data) => {
  return useQuery({
    queryKey: ["externalContacts", data],
    queryFn: () => fetchExternalContact(data),
    enabled: true,
  });
};

//get external contact by id
const fetchExternalContactById = async ({ id }) => {
  if (id) {
    return await axios.get(
      API_URL + `/api/ExternalContact/GetExternalContactsById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};

export const useFetchExternalContactById = ({ id }) => {
  return useQuery({
    queryKey: [`edit-externalContact-${id}`],
    queryFn: () => fetchExternalContactById({ id }),
    enabled: false,
  });
};

//delete by id external contact by id
const deleteExternalContactById = (contactId) => {
  return axios.post(
    API_URL + `/api/ExternalContact/DeleteExternalContact/${contactId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDeleteExternalContactById = () => {
  return useMutation(deleteExternalContactById);
};

//block and unblock
const blockUnblockExternalContact = ({ contactId, isBlock }) => {
  return axios.post(
    API_URL +
      `/api/ExternalContact/BlockExternalContact/${contactId}/${isBlock}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useBlockUnblockExternalContact = () => {
  return useMutation(blockUnblockExternalContact);
};

////export external contact options
const exportExternalContacts = async () => {
  return await axios.get(API_URL + `/api/Export/ExportExternalContact`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportExternalContact = () => {
  return useMutation(() => exportExternalContacts());
};
