import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import styles from "./CreateOverview.module.css";
import TextInput from "../../../../Atoms/Inputs/TextInput";
import CustomizedSelect from "../../../../Atoms/Inputs/CustomizedSelect";
import DateInput from "../../../../Atoms/Inputs/DateInput";
import TextArea from "../../../../Atoms/Inputs/TextArea";
import folderIcon from "../../../../../assets/Imagess/folderICon.svg";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
import * as Yup from "yup";
import noteIcon from "../../../../../assets/Imagess/Campaign/NewCampaign/noteIcon.svg";
import { Form, Formik, FormikProvider, useFormik } from "formik";
import {
  useFetchDropDownTypes,
  useFetchTerms,
} from "../../../../../Hooks/Settings";
import { useAddCampaignstage1 } from "../../../../../Hooks/Campaign";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

const CreateOverview = forwardRef(({ campaignDetails, isLoadingdata }, ref) => {
  const [init, setInit] = useState({});

  const { data: termData } = useFetchTerms({ type: 19 });
  const { data: languageData } = useFetchDropDownTypes({ typeId: 22 });
  const { mutate: addstage1, isLoading: isAddingStage1 } =
    useAddCampaignstage1();

  useEffect(() => {
    const initialValues = {
      campaignName: campaignDetails?.campaignName || "",
      termId: campaignDetails?.termId || "",
      languageId: campaignDetails?.languageId || "",
      startDate: campaignDetails?.startDate.substring(0, 10) || "",
      endDate: campaignDetails?.endDate.substring(0, 10) || "",
      notes: campaignDetails?.notes || "",
    };
    setInit(initialValues);
  }, [campaignDetails]);
  const validationSchema = Yup.object().shape({
    campaignName: Yup.string().required("Campaign Name is required"),
    termId: Yup.string().required("Term is required"),
    languageId: Yup.string().required("Language is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End date must be after start date"),
    notes: Yup.string().required("Notes is required"),
  });

  const formik = useFormik({
    initialValues: init,
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const id =
        campaignDetails?.campaignId || "00000000-0000-0000-0000-000000000000";
      const valuesToAdd = { ...values, campaignId: id };

      addstage1(valuesToAdd, {
        onSuccess: (data) => {
          toast.success("Overview step is done");
          localStorage.setItem("campaignId", data?.data?.campaignId);
        },
        onError: (error) => {
          console.error("Error", error);
          toast.error("Error occurred while saving campaign");
        },
      });
    },
  });
  useImperativeHandle(ref, () => ({
    submitForm: () => {
      formik.submitForm();
    },
  }));

  useEffect(() => {
    ref.current = formik;
  }, [ref, formik]);

  const getNextDay = () => {
    const today = new Date();
    const nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    const year = nextDay.getFullYear();
    const month = String(nextDay.getMonth() + 1).padStart(2, "0");
    const day = String(nextDay.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  if (isLoadingdata) {
    return <Loader />;
  }
  return (
    <FormikProvider value={formik} innerRef={ref}>
      <WhiteContainer center white>
        {isAddingStage1 ? (
          <Loader />
        ) : (
          <>
            <div className={styles["overview_form"]}>
              <TextInput
                label='Campaign Name'
                placeholder='Enter Name'
                icon={folderIcon}
                name='campaignName'
                value={formik.values.campaignName}
                onChange={(name, value) => formik.setFieldValue(name, value)}
                errors={formik.errors.campaignName}
                touched={formik.touched.campaignName}
              />
              <div className={styles["form"]}>
                <CustomizedSelect
                  label='Term'
                  options={termData?.data?.map(
                    ({ academicTermId, termName }) => ({
                      value: academicTermId,
                      text: termName,
                    })
                  )}
                  icon={folderIcon}
                  placeholder='Select Term'
                  name='termId'
                  value={formik.values.termId}
                  onChange={(name, value) => formik.setFieldValue(name, value)}
                  errors={formik.errors.termId}
                  touched={formik.touched.termId}
                />
                <CustomizedSelect
                  label='Language'
                  options={languageData?.data?.map(({ ddid, text }) => ({
                    value: ddid,
                    text: text,
                  }))}
                  icon={folderIcon}
                  placeholder='Select Language'
                  name='languageId'
                  value={formik.values.languageId}
                  onChange={(name, value) => formik.setFieldValue(name, value)}
                  errors={formik.errors.languageId}
                  touched={formik.touched.languageId}
                />
              </div>
              <div className={styles["form"]}>
                <DateInput
                  label='Start Date'
                  name='startDate'
                  value={formik.values.startDate}
                  onChange={(name, value) => formik.setFieldValue(name, value)}
                  errors={formik.errors.startDate}
                  touched={formik.touched.startDate}
                  startDate={getNextDay()}
                />
                <DateInput
                  label='End Date'
                  name='endDate'
                  value={formik.values.endDate}
                  onChange={(name, value) => formik.setFieldValue(name, value)}
                  errors={formik.errors.endDate}
                  touched={formik.touched.endDate}
                  startDate={formik.values.startDate}
                />
              </div>
              <TextArea
                label='Notes'
                placeholder='Enter Notes'
                rows='8'
                icon={noteIcon}
                name='notes'
                value={formik.values.notes}
                onChange={(name, value) => formik.setFieldValue(name, value)}
                errors={formik.errors.notes}
                touched={formik.touched.notes}
              />
            </div>
          </>
        )}
      </WhiteContainer>
    </FormikProvider>
  );
});

export default CreateOverview;
