import React from "react";
import styles from "./onlineForm.module.css";
import Loader from "../../Loader/Loader";
import PersonalInformation from "./PersonalInformation";
import MailingInfo from "./MailingInfo";
import ProgramInformation from "./ProgramInformation";
import ParentOfGuardian from "./ParentOfGuardian";
import Consent from "./Consent";
import AcademicInfo from "./AcademicInfo";
import ListOfAcademicRecords from "./ListOfAcademicRecords";
import AcademicTest from "./AcademicTest";
import PersonalStatement from "./PersonalStatement";
import WorkExperience from "./WorkExperience";
import ReferenceInformation from "./ReferenceInformation";
import TranscriptMailingAddress from "./TranscriptMailingAddress";
import Declaration from "./Declaration";
const OnlineForm = ({ applicant, isApplicant, applicationData, isLoading }) => {
  return (
    <div className={styles["onlinform-container"]}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className={styles["sub-container"]} style={{gridTemplateColumns:isApplicant ? "" :"1fr"}}>
            <PersonalInformation
              applicant={applicant}
              isApplicant={isApplicant}
            />
            {isApplicant && (
              <MailingInfo
                applicant={applicant}
                applicationData={applicationData}
              />
            )}
          </div>
          {isApplicant && (
            <>
              {" "}
              <div className={styles["sub-container"]}>
                <ProgramInformation
                  applicant={applicant}
                  applicationData={applicationData}
                />
                <ParentOfGuardian
                  applicant={applicant}
                  applicationData={applicationData}
                />
              </div>
              <div className={styles["sub-container"]}>
                <Consent applicationData={applicationData} />
                {applicant?.applications &&
                applicant?.applications[0]?.applyingAs !== 2 ? (
                  <AcademicInfo
                    applicationData={applicationData}
                    applicant={applicant}
                  />
                ) : (
                  ""
                )}
              </div>
              {applicant?.applications &&
              applicant?.applications[0]?.applyingAs !== 2 ? (
                <ListOfAcademicRecords applicationData={applicationData}  applicant={applicant} />
              ) : (
                ""
              )}
              {applicant?.applications &&
              applicant?.applications[0]?.applyingAs !== 2 ? (
                <div className={styles["sub-container"]}>
                  {applicant?.applications &&
                  (applicant?.applications[0]?.applyingAs !== 0 &&
                    applicant?.applications[0]?.applyingAs !== 7) ? (
                    <PersonalStatement applicationData={applicationData} />
                  ) : (
                    ""
                  )}

                  <AcademicTest applicationData={applicationData} />
                </div>
              ) : (
                ""
              )}
            </>
          )}
          {isApplicant ? (
            <>
              {applicant?.applications &&
              applicant?.applications[0]?.applyingAs !== 2 ? (
                <>
                  <div className={styles["sub-container"]}>
                    {applicant?.applications &&
                    applicant?.applications[0]?.startYourApplication == 1 ? (
                      <WorkExperience applicationData={applicationData} />
                    ) : (
                      ""
                    )}
                    {applicant?.applications &&
                    (applicant?.applications[0]?.startYourApplication == 1 ||
                      applicant?.applications[0]?.applyingAs == 8) ? (
                      <ReferenceInformation applicationData={applicationData} applicant={applicant} />
                    ) : (
                      ""
                    )}
                  </div>

                  <div className={styles["sub-container"]}>
                    {applicant?.applications &&
                    (applicant?.applications[0]?.applyingAs == 6 ||
                      applicant?.applications[0]?.applyingAs == 8) ? (
                      <TranscriptMailingAddress
                        applicationData={applicationData}
                      />
                    ) : (
                      ""
                    )}
                    <Declaration applicationData={applicationData} />
                  </div>
                </>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default OnlineForm;
