import React from "react";
import RadioButton from "./RadioButton";
import styles from "../Input.module.css";
import { displayName } from "react-quill";
const RadioButtonGroup = ({
  label,
  required,
  options,
  selectedValue,
  name,
  onRadioChange,
  disabled,
  fontWeight,
  red,
}) => {
  return (
    <div className={styles["radio-buttons-container"]}>
      <label
        htmlFor={label}
        className={styles["radioBtn-label"]}
        style={{ fontWeight: fontWeight }}
      >
        {label}
        {required && <span className={styles["required"]}>*</span>}
      </label>
      <div style={{ display: "flex", gap: "2rem", paddingLeft: "15px" }}>
        {options?.map((option) => (
          <RadioButton
            red={red}
            key={option.value}
            label={option.label}
            value={option.value}
            checked={selectedValue === option.value}
            handleOnChange={() => onRadioChange(name, option.value)}
            disabled={disabled}
          />
        ))}
      </div>
    </div>
  );
};

export default RadioButtonGroup;
