import React, { useEffect, useState } from "react";
import styles from "./RecomendationPage.module.css";
import recomendationLogo from "../../../assets/Imagess/audRecomendationLogo.png";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import DocumentUpload from "../../Atoms/Documents/DocumentUpload";
import TextInput from "../../Atoms/Inputs/TextInput";
import Buttons from "../../Atoms/Buttons/Buttons";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  useFEtchReferenceById,
  useFetchDropDownbyType,
  usePostReferance,
} from "../../../Hooks/Recomendation";
import { toast } from "react-toastify";
import LoaderButton from "../../Moecules/Loader/LoaderButton";
import Loader from "../../Moecules/Loader/Loader";

const RecomemdationPage = () => {
  let search = window.location.search;
  let params = new URLSearchParams(search);
  let referenceId = params.get("ReferenceId");

  const {
    data: referenceByIdData,
    refetch: refetchReferenceData,
    isLoading: isReferenceDAtaLoading,
  } = useFEtchReferenceById(referenceId);
  const { mutate: postReferance, isLoading: isPostLoading } =
    usePostReferance();
  const { data: dropDownTypeData } = useFetchDropDownbyType("1");
  const [init, setInit] = useState({
    title: referenceByIdData?.data
      ? referenceByIdData?.data[0]?.referanceTitle
      : "",
    fullName: referenceByIdData?.data
      ? referenceByIdData?.data[0]?.referanceName
      : "",
    email: referenceByIdData?.data
      ? referenceByIdData?.data[0]?.referanceEmail
      : "",
    documentUpload: "",
  });

  const validationSchema = Yup.object().shape({
    title: Yup.string().required("title is required"),
    fullName: Yup.string().required("fullName is required"),
    email: Yup.string().email("Invalid Email").required("email is required"),
    documentUpload: Yup.string().required("documentUpload is required"),
  });

  useEffect(() => {
    setInit({
      title: referenceByIdData?.data
        ? referenceByIdData?.data[0]?.referanceTitle
        : "",
      fullName: referenceByIdData?.data
        ? referenceByIdData?.data[0]?.referanceName
        : "",
      email: referenceByIdData?.data
        ? referenceByIdData?.data[0]?.referanceEmail
        : "",
      documentUpload: "",
    });
  }, [referenceByIdData]);

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("ApplicantReferanceId", referenceId);
    formData.append("ReferanceFile", values.documentUpload);
    postReferance(formData, {
      onSuccess: () => {
        toast.success("Email is sent ");
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    });
  };
  const formattedOptions = dropDownTypeData?.data
    ? dropDownTypeData?.data?.map((option) => ({
        value: option.ddid,
        text: option.text,
      }))
    : [];

  return (
    <Formik
      initialValues={init}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <Form>
            <div className={styles["recomendation-container"]}>
              <div className={styles["recomendation-subContainer"]}>
                <img
                  src={recomendationLogo}
                  alt='recomendation logo'
                  className={styles["recomendation-img"]}
                />

                <h2>
                  <span>
                    {referenceByIdData?.data[0]?.applicantFirstName}{" "}
                    {referenceByIdData?.data[0]?.applicantLastName}
                  </span>{" "}
                  has requested a letter of recommendation from you to support{" "}
                  <span>
                    {referenceByIdData?.data[0]?.applicantFirstName}'s
                  </span>{" "}
                  application for the graduate program at the American
                  University in Dubai. 
                </h2>
                <div className={styles["text-cont"]}>
                  <CustomizedSelect
                    label='Title'
                    options={formattedOptions}
                    grey={true}
                    name='title'
                    value={values.title}
                    // text={true}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.title}
                    touched={touched.title}
                  />
                  <TextInput
                    label='Full Name'
                    grey={true}
                    name='fullName'
                    value={values.fullName}
                    // text={true}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.fullName}
                    touched={touched.fullName}
                  />
                </div>
                <TextInput
                  label='Email'
                  name='email'
                  grey={true}
                  value={values.email}
                  // text={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.email}
                  touched={touched.email}
                />
                <DocumentUpload
                  text='Upload Letter of Recommendation'
                  name='documentUpload'
                  value={values.documentUpload}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.documentUpload}
                  touched={touched.documentUpload}
                />
                {isPostLoading ? (
                  <LoaderButton />
                ) : (
                  <Buttons
                    text='Submit'
                    type='submit'
                    handleOnClick={handleSubmit}
                  />
                )}
              </div>
            </div>{" "}
          </Form>
        );
      }}
    </Formik>
  );
};

export default RecomemdationPage;
