import React from "react";
import CampaignDetailedBox from "../../../Campaign/CampaignDetails/CampaignDetailedBox";
import EmptyText from "../../../Custom/EmptyText";
import WhiteContainer from "../../../../HOC/WrappingContainer/WhiteContainer";
const CampaignsIncluded = ({ data }) => {
  return (
    <WhiteContainer white>
      {data?.length > 0 ? (
        data?.map((item) => {
          return (
            <div key={item.campaignId}>
              {" "}
              <CampaignDetailedBox data={item} contact />{" "}
            </div>
          );
        })
      ) : (
        <EmptyText text='Campaigns' />
      )}
    </WhiteContainer>
  );
};

export default CampaignsIncluded;
