import React from "react";
import UnavailableIcon from "../../../assets/Imagess/notAvailableIcon.svg";
import notFilled from "../../../assets/Imagess/notFilledIcon.svg";
import styles from "./FilledUnFilledText.module.css";

const FilledUnFilledText = ({ required, data, mobile, email }) => {
  const FilledUnFilledFunction = (required) => {
    if (required) {
      return (
        <span className={styles["unfilled-text"]} style={{ color: "#F00" }}>
          NA <img src={UnavailableIcon} alt='Unavailable' />
        </span>
      );
    } else {
      return (
        <span className={styles["unfilled-text"]} style={{ color: "#FC7900" }}>
          NA <img src={notFilled} alt='Not Filled' />
        </span>
      );
    }
  };
  const renderData = () => {
    if (mobile && data) {
      return <a href={`tel:${data}`}>+{data}</a>;
    } else if (email && data) {
      return <a href={`mailto:${data}`}>{data}</a>;
    } else if (!mobile && data) {
      return data;
    } else {
      return FilledUnFilledFunction(required);
    }
  };

  return <span className={styles["filled-text"]}>{renderData()}</span>;
};

export default FilledUnFilledText;
