import React from "react";
import styles from "./ApplicationAnalysis.module.css";
import Rate from "./Rate/Rate";
import SourceTypeChart from "./SourceTypePieChart/SourceTypeChart";
import SourceChannels from "./SourceChannels/SourceChannels";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import AboutAudChart from "./AboutAud/AboutAudChart";
import ApplicationCategories from "./ApplicationCategories/ApplicationCategories";
import DashboardCategories from "./Categories/DashboardCategories";
const ApplicationAnalysis = () => {
  return (
    <WrappingContainer none>
      <div className={styles["gridThree"]}>
        <Rate />
        <SourceTypeChart />
        <SourceChannels />
      </div>
      <div className={styles["gridThreeOfThree"]}>
        <AboutAudChart />
        <ApplicationCategories />
        <DashboardCategories />
      </div>
    </WrappingContainer>
  );
};

export default ApplicationAnalysis;
