import React, { useState } from "react";
import leadsIcon from "../../../../../assets/Imagess/Users/UsersAssignment/leads.svg";
import prospectICon from "../../../../../assets/Imagess/Users/UsersAssignment/prospect.svg";
import externalContact from "../../../../../assets/Imagess/Users/UsersAssignment/externalContact.svg";
import QueryComponent from "../QueryComponent";

const LPCQuery = ({ disabled, color, setQueryState, queryState ,isReset}) => {
  const [openIndex, setOpenIndex] = useState(null);
  const [queryType, setQueryType] = useState(null);

  const data = [
    {
      icon: leadsIcon,
      title: "Leads",
      type: 1,
      queries: queryState[1],
    },
    {
      icon: prospectICon,
      title: "Prospects",
      type: 0,
      queries: queryState[0],
    },
    {
      icon: externalContact,
      title: "External Contact -  Students",
      type: 2,
      queries: queryState[2],
    },
  ];

  const handleOpenQuery = (index) => {
    setQueryType(data[index].type);
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: color ? "0" : "1rem",
      }}
    >
      {data?.map((item, index) => {
        if (disabled && (!item.queries || item.queries.length === 0)) {
          return null;
        }
        return (
          <QueryComponent
            key={index}
            data={item}
            isOpen={openIndex === index}
            onClick={() => handleOpenQuery(index)}
            disabled={disabled}
            color={color}
            queryType={queryType}
            setQueryState={setQueryState}
            queries={item.queries}
            isReset={isReset}
          />
        );
      })}
    </div>
  );
};

export default LPCQuery;
