import React, { useEffect, useState, useRef } from "react";
import styles from "./NewTemplate.module.css";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import subjectICon from "../../../assets/Imagess/subjectIcon.svg";
import emailICon from "../../../assets/Imagess/emaildropdownicon.svg";
import TextInput from "../../Atoms/Inputs/TextInput";
import * as Yup from "yup";
import CustomizedSelect from "../../Atoms/Inputs/CustomizedSelect";
import { Formik, Form } from "formik";
import {
  useAddNotification,
  useFetchEmailTypes,
  useFetchNotificationsById,
} from "../../../Hooks/Notification";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEmailContext } from "../../../Context/EmailContext";
import EmailTextEditor from "../../Moecules/Email/EmailTextEditor";
import TitlePageHeader from "../../Moecules/Settings/SettingsMolecules/TitlePageHeader";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import Attachments from "../../Atoms/Documents/Attachments";
import { useFetchESignature } from "../../../Hooks/ESignature";
import Loader from "../../Moecules/Loader/Loader";

const NewTemplateEmail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [isCampaign, setIsCampaign] = useState(
    location?.state?.isCampaign || false
  );
  const { mutate: addEmail, isLoading: isAdding } = useAddNotification();
  const {
    data: notificationsById,
    isLoading,
    refetch: refetchNotificationById,
  } = useFetchNotificationsById(id);
  const { selectedEmail, setNewSelectedEmail } = useEmailContext();
  const { data: emailTypesData } = useFetchEmailTypes();
  const { data: eSignature } = useFetchESignature();

  const notificationData =
    notificationsById?.data && notificationsById?.data[0];
  const initialAttachments = useRef(notificationData?.attachments || []);

  const initialValues = {
    NotificationName: notificationData?.notificationName || "",
    NotificationId:
      notificationData?.notificationId ||
      "00000000-0000-0000-0000-000000000000",
    NotificationType: isCampaign ? 4 : notificationData?.notificationType || "",
    NotificationContent: notificationData?.notificationContent || "",
    Subject: notificationData?.subject || "",
    EmailSignatureId: notificationData?.emailSignatureId || "",
    Attachments: notificationData?.attachments || [],
  };

  const validationSchema = Yup.object().shape({
    NotificationName: Yup.string().required(),
    NotificationId: Yup.string(),
    NotificationType: Yup.number().required(),
    NotificationContent: Yup.string().required("Required"),
    Subject: Yup.string().required(),
    EmailSignatureId: Yup.string().required(),
    Attachments: Yup.array().notRequired(),
  });

  const selectApplicantOptions = emailTypesData?.data?.map(
    ({ notificationTypeId, notificationType }) => ({
      value: notificationTypeId,
      text: notificationType,
    })
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <WrappingContainer>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const formData = new FormData();

          // Check if attachments have changed
          const attachmentsChanged =
            initialAttachments.current.length !== values.Attachments.length ||
            initialAttachments.current.some((att, index) => {
              return (
                att.fileName !== values.Attachments[index].fileName ||
                att.content !== values.Attachments[index].content ||
                att.contentType !== values.Attachments[index].contentType
              );
            });

          formData.append("EditAttachments", attachmentsChanged.toString());

    
          Object.keys(values).forEach((key) => {
            if (key === "Attachments") {
              values[key].forEach((file) => {
                console.log("vfdsknvkdfv", file);
                if (file.fileName) {
                  const byteCharacters = atob(file.content);
                  const byteNumbers = new Array(byteCharacters.length);
                  for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  const blob = new Blob([byteArray], {
                    type: file.contentType,
                  });

                  console.log("gteygty", blob);
                  formData.append("Attachments", blob);
                } else {
                  formData.append("Attachments", file);
                }
              });
            } else {
              formData.append(key, values[key]);
            }
          });

          formData.append("IsCampaign", isCampaign);

          addEmail(formData, {
            onSuccess: () => {
              if (isCampaign) {
                navigate("/home/campaign-templateEmail");
              } else {
                navigate("/home/email");
              }
              setNewSelectedEmail({});
              refetchNotificationById();
            },
            onError: (error) => {
              console.error("Failed to add email:", error);
            },
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => {
          console.log("Form values:", values);
          console.log("Form errors:", errors);

          return (
            <Form>
              <WhiteContainer white>
                <TitlePageHeader
                  title={
                    notificationData?.notificationName != null
                      ? "Update Email Template"
                      : "Create New Email Template"
                  }
                  buttonText={
                    notificationData?.notificationName != null
                      ? "Update"
                      : "Save"
                  }
                  handleOnClick={handleSubmit}
                  isLoading={isAdding}
                />
                <div className={styles["new-email-cont"]}>
                  <TextInput
                    label='Name'
                    placeholder='Enter Email Name'
                    icon={emailICon}
                    name='NotificationName'
                    value={values.NotificationName}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.NotificationName}
                    touched={touched.NotificationName}
                  />
                  {!isCampaign && (
                    <CustomizedSelect
                      label='Email Type'
                      placeholder='Select Type'
                      options={selectApplicantOptions}
                      icon={emailICon}
                      name='NotificationType'
                      value={values.NotificationType}
                      onChange={(name, value) => {
                        setFieldValue(name, value);
                      }}
                      errors={errors.NotificationType}
                      touched={touched.NotificationType}
                    />
                  )}
                  <TextInput
                    label='Subject'
                    placeholder='Enter Email Subject'
                    icon={subjectICon}
                    name='Subject'
                    value={values.Subject}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.Subject}
                    touched={touched.Subject}
                  />

                  <Attachments
                    name='Attachments'
                    value={values.Attachments}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.Attachments}
                    touched={touched.Attachments}
                  />
                </div>
                <EmailTextEditor
                  name='NotificationContent'
                  value={values.NotificationContent}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.NotificationContent}
                  touched={touched.NotificationContent}
                />

                <div className={styles["new-email-cont"]}>
                  <CustomizedSelect
                    label='Email Signature'
                    placeholder='Select E-signature'
                    options={eSignature?.data?.map(
                      ({ emailSignatureId, signatureName }) => ({
                        value: emailSignatureId,
                        text: signatureName,
                      })
                    )}
                    icon={emailICon}
                    name='EmailSignatureId'
                    value={values.EmailSignatureId}
                    onChange={(name, value) => {
                      setFieldValue(name, value);
                    }}
                    errors={errors.EmailSignatureId}
                    touched={touched.EmailSignatureId}
                  />
                </div>
              </WhiteContainer>
            </Form>
          );
        }}
      </Formik>
    </WrappingContainer>
  );
};

export default NewTemplateEmail;
