import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import styles from "../../Template/UserProfileTemplate/UserProfile.module.css";
import email from "../../../assets/Imagess/emailicon.svg";
import phone from "../../../assets/Imagess/phoneicon.svg";
import faxIcon from "../../../assets/Imagess/faxIcon.svg";
import TextInput from "../../Atoms/Inputs/TextInput";
import PhoneNumber from "../../Atoms/Inputs/PhoneNumber";
import mobileICon from "../../../assets/Imagess/mobileInputIcon.png";
import emailicon from "../../../assets/Imagess/emailloginicon.svg";
import user from "../../../assets/Imagess/userVector.svg";
import { Formik, Form, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useEditUser } from "../../../Hooks/Users";
import { toast } from "react-toastify";
const UserPersonalDetails = forwardRef(
  ({ editMode, userDetails, refetchUser }, ref) => {
    const { mutate: editUser, isLoading: isEdittingUser } = useEditUser();

    const initialValues = {
      firstNameEn: userDetails?.firstNameEn || "",
      lastNameEn: userDetails?.lastNameEn || "",
      email: userDetails?.email || "",
      mobile: userDetails?.mobile || "",
    };
    const validationSchema = Yup.object().shape({
      firstNameEn: Yup.string(),
      lastNameEn: Yup.string(),
      email: Yup.string(),
      mobile: Yup.string(),
    });

    const handleEdit = (values) => {
      const includedValues = {
        userId: userDetails?.userId,
        gender: userDetails?.gender,
        roleId: userDetails?.roleId,
        roleName: userDetails?.roleName,
        signature: userDetails?.signature,
        isRequirement: userDetails?.isRequirement,
        userName: userDetails?.userName,
      };
      const valuesTosend = { ...values, ...includedValues };
      editUser(valuesTosend, {
        onSuccess: () => {
          refetchUser();
          toast.success("Your profile is updated successfully");
        },
        onError: (error, data) => {
          console.error("An error occurred:", error?.response?.data?.message);
          toast.error("Email or username already exists");
        },
      });
    };
    const formik = useFormik({
      initialValues: initialValues,
      validationSchema: validationSchema,
      enableReinitialize: true,
      onSubmit: (values) => {
        handleEdit(values);
      },
    });

    useImperativeHandle(ref, () => ({
      submitForm: () => {
        formik.submitForm();
      },
    }));

    useEffect(() => {
      ref.current = formik;
    }, [ref, formik]);

    return (
      <FormikProvider value={formik} innerRef={ref}>
        <div className={styles["personal-container"]}>
          <h3>Personal Information </h3>
          <div className={styles["personal-container2"]}>
            <TextInput
              icon={user}
              label='First Name'
              disabled={editMode}
              placeholder='First Name'
              name='firstNameEn'
              value={formik.values.firstNameEn}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.firstNameEn}
              touched={formik.touched.firstNameEn}
            />
            <TextInput
              icon={user}
              label='Last Name'
              disabled={editMode}
              placeholder='Last Name'
              name='lastNameEn'
              value={formik.values.lastNameEn}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.lastNameEn}
              touched={formik.touched.lastNameEn}
            />
            <TextInput
              icon={emailicon}
              label='Email'
              disabled={editMode}
              placeholder='Email'
              name='email'
              value={formik.values.email}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.email}
              touched={formik.touched.email}
            />
            <PhoneNumber
              icon={mobileICon}
              label='Mobile'
              disabled={editMode}
              placeholder='Mobile'
              name='mobile'
              value={formik.values.mobile}
              onChange={(name, value) => {
                formik.setFieldValue(name, value);
              }}
              errors={formik.errors.mobile}
              touched={formik.touched.mobile}
            />
          </div>
        </div>
      </FormikProvider>
    );
  }
);

export default UserPersonalDetails;
