import React from "react";
import styles from "./EmailsReceivedList.module.css";

const ReceivedEmailsList = ({ data, selectedEmail, setSelectedEmail }) => {

  return (
    <div className={styles["receivedEmailList_container"]}>
      {data?.map((item, index) => {
        const isActive =
          item.notificationLogId === selectedEmail?.notificationLogId;
        return (
          <div
            key={item?.notificationLogId}
            className={`${styles["received_List"]} ${
              isActive ? styles["active"] : ""
            }`}
            onClick={() => setSelectedEmail(item)}
          >
            <div
              className={`${styles["leftBorder"]} ${
                isActive ? styles["activeLeftBorder"] : ""
              }`}
            >
              <p></p>
            </div>
            <div className={styles["List"]}>
              <h2>{item?.subject}</h2>
              <h3>{item.text}</h3>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReceivedEmailsList;
