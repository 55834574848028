import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const WorkExperience = ({ applicant, applicationData }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Work Experience</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title='Employment Status'
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.employmentStatus_Display
            }
            required={true}
          />

          <TextContainer
            title='Employment Sector'
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.employmentSector_Display
            }
            required={true}
          />

          <TextContainer
            title='Company Name'
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.companyName
            }
            required={true}
          />

          <TextContainer
            title='Job Title'
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.jobTitle
            }
            required={true}
          />

          <TextContainer
            title='Years of Experience'
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.yearsOfExperience
            }
            required={true}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
