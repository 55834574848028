import React from "react";
import TitleImageComponent from "../../../Custom/Title_SubTitle/TitleImageComponent";
import CustomizedProgressBar from "../../../Custom/ProgressBar/CustomizedProgressBar";
import deliveredICon from "../../../../../assets/Imagess/Campaign/singleCampaign/deliveredIcon.svg";
import clickedIcon from "../../../../../assets/Imagess/Campaign/singleCampaign/clickedIcon.svg";
import responseIcon from "../../../../../assets/Imagess/Campaign/singleCampaign/responseIcon.svg";
import urlCLicked from "../../../../../assets/Imagess/Campaign/singleCampaign/urlClickedIcon.svg";
import bounceIcon from "../../../../../assets/Imagess/Campaign/singleCampaign/bounceIcon.svg";
import Title_Subtitle from "../../../Custom/Title_SubTitle/Title_Subtitle";
import styles from "./CampaignProgressBarBox.module.css";

const CampaignProgressBarBox = ({ data }) => {
  const dataType = [
    {
      type: 0,
      title: "Delivered",
      icon: deliveredICon,
      firstSubtext: "Total Audience:",
      secondSubtext: "Failed",
    },
    {
      type: 1,
      title: "Clicked",
      icon: clickedIcon,
      firstSubtext: "Unique Clicked:",
      secondSubtext: "Last Clicked:",
    },
    {
      type: 2,
      title: "Responses",
      icon: responseIcon,
      firstSubtext: "Response Time Analysis:",
      secondSubtext: "Last Response Received:",
    },
    {
      type: 3,
      title: "URL Clicked",
      icon: urlCLicked,
      firstSubtext: "Click-through Rates:",
      secondSubtext: "Registration Rates:",
    },
    {
      type: 4,
      title: "Bounce",
      icon: bounceIcon,
    },
  ];

  const mappedData = dataType?.find((item) => item.type == data.type);

  return (
    <div className={styles["details"]}>
      <TitleImageComponent
        fontSize='14px'
        fontWeight='700'
        width='21px'
        height='21px'
        icon={mappedData?.icon}
        text={mappedData?.title}
      />
      <div className={styles["subDetails"]}>
        <div>
          <h3 className={styles["details_total_number"]}> {data?.total}</h3>
          <CustomizedProgressBar value={data?.total} />
        </div>

        <Title_Subtitle
          subTitle={mappedData?.firstSubtext}
          title={data?.firstValue}
          opacity={0.5}
          row
          fontWeight='500'
        />
        <Title_Subtitle
          subTitle={mappedData?.secondSubtext}
          title={data?.secondValue}
          opacity={0.5}
          row
          fontWeight='500'
        />
      </div>
    </div>
  );
};

export default CampaignProgressBarBox;
