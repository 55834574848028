import React, { useState } from "react";
import styles from "./SettingsNavbar.module.css";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import closeIcon from "../../../../assets/Imagess/SettingsSideBar/settingsCloseArrow.svg";
import openIcon from "../../../../assets/Imagess/SettingsSideBar/setiingsOpenArrow.svg";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import { useFetchSettingsPages } from "../../../../Hooks/Settings";

const SettingsNavbar = ({
  setType,
  allTypes,
  setIsRelated,
  isTypesLoading,
  type,
}) => {
  const [activeLink, setActiveLink] = useState(type || 0);
  const [isOpen, setIsOpen] = useState(false);
  const { data: settingsPage } = useFetchSettingsPages();
  const handelOnCLick = (item) => {
    setType(parseInt(item.ddtypeId));
    setActiveLink(item.ddtypeId);
    if (item.relatedTo !== null) {
      setIsRelated(item.relatedTo);
    } else {
      setIsRelated(null);
    }
    if (
      parseInt(item.ddtypeId) === 10 ||
      parseInt(item.ddtypeId) === 11 ||
      parseInt(item.ddtypeId) === 12
    ) {
      toast.warning("Select a country to reveal the data");
    }
    if (parseInt(item.ddtypeId) === 5) {
      toast.warning("Select application type to reveal the data");
    }
  };

  if (isTypesLoading) {
    return <Loader />;
  }
  const links = settingsPage?.data?.map((item) => {
    return {
      ddtypeId: item.pageName,
      type: item.name,
      icon: item.icon,
      relatedTo: item.relatedToIndex,
    };
  });
  const sortedData = links?.sort((a, b) => {
    return Number(a.ddtypeId) - Number(b.ddtypeId);
  });
  console.log("Vsdvsd", settingsPage);

  return (
    <div
      className={` ${styles["navbar-container"]} ${
        isOpen ? styles["open"] : ""
      } `}
    >
      <Tooltip content={isOpen ? "Close" : "Open"} placement='right'>
        <div
          className={styles["attributes"]}
          onClick={() => setIsOpen((prev) => !prev)}
        >
          <img src={isOpen ? closeIcon : openIcon} />
          {isOpen ? <p style={{color:"#FFF",fontWeight:"600"}}>Close</p> : ""}
        </div>
      </Tooltip>
      <ul className={styles["ul-cont"]}>
        {sortedData?.map((item, index) => (
          <li
            key={item.ddtypeId}
            className={`${
              item.ddtypeId === activeLink ? styles.activeListItem : ""
            }
                ${index % 2 === 0 ? styles.listItem2 : styles.listItem} ${
              isOpen ? "" : styles.openlistItem
            } `}
            onClick={() => handelOnCLick(item)}
          >
            {isOpen ? (
              <img src={`../${item.icon}`} alt='icon' />
            ) : (
              <Tooltip content={item.type} placement='right'>
                <img src={`../${item.icon}`} alt='icon' />
              </Tooltip>
            )}
            {isOpen ? (
              <h1
                className={`${styles["navlink-title"]} ${
                  isOpen ? styles["navlink-title-opened"] : ""
                } ${item.ddtypeId === activeLink ? styles.activeListItem : ""}`}
              >
                {item.type}
              </h1>
            ) : (
              ""
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SettingsNavbar;
