import React from "react";
import Modal from "../../Atoms/Modal/Modal";
import deleteIcon from "../../../assets/Imagess/caution.svg";
import Buttons from "../../Atoms/Buttons/Buttons";
import styles from "../../Atoms/Modal/Modal.module.css";
import Loader from "../Loader/Loader";
import LoaderButton from "../Loader/LoaderButton";

const DeletePopup = ({
  handleOnClose,
  title,
  handleDelete,
  isLoading,
  mode = "Delete",
  text = "Are you want to delete this",
  subText,
}) => {
  return (
    <Modal onClose={handleOnClose} width='30%'>
      <div className={styles["delete-container"]}>
        <img src={deleteIcon} />
        <h3> {mode + " " + title}</h3>
        {subText && <p>{subText}</p>}
        <p> {text + " " + title + " " + "?"}</p>

        {isLoading ? (
          <LoaderButton />
        ) : (
          <div className={styles["button-input-container"]}>
            <Buttons
              text='Cancel'
              color='#D9D9D9'
              handleOnClick={handleOnClose}
            />{" "}
            <Buttons text={mode} color='#D72222' handleOnClick={handleDelete} />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DeletePopup;
