import React from "react";
import DashboardContainer from "../../DashboardContainer";
import Chart from "react-apexcharts";

const ApplicationCategories = () => {
  const options = {
    series: [
      {
        data: [65, 100, 85],
      },
    ],
    chart: {
      type: "bar",
      height: 300,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        barHeight: "90%",
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#F3223C", "#015699", "#7BB1F6"],
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#fff"],
      },
      formatter: function (val, opt) {
        return val;
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: ["#1b224c"],
      },
      dropShadow: {
        enabled: false,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Undergraduate", "Graduate", "Visiting"],
      labels: {
        show: true,
        style: {
          fontWeight: "600",
          colors: ["#1b224c"],
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      categories: ["Undergraduate", "Graduate", "Visiting"],
    },
    legend: {
      show: false,
    },
  };

  return (
    <DashboardContainer>
      <h2>Application Categories</h2>
      <Chart
        options={options}
        series={options.series}
        type='bar'
        height={380}
      />
    </DashboardContainer>
  );
};

export default ApplicationCategories;
