import React, { useEffect, useState } from "react";
import styles from "./ApplicantLogs.module.css";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import SingleTab from "../../SingleApplicant/SingleTab";
import Logs from "../../Custom/Logs/Logs";

const ApplicantLogs = ({ logs, isProspectLead, applicantLeadLogs }) => {
  const [selected, setSelected] = useState("Prospect");
  const [logsToMap, setLogsToMap] = useState(logs);



  useEffect(() => {
    if (selected == "Prospect") {
      setLogsToMap(logs);
    } else {
      setLogsToMap(applicantLeadLogs);
    }
  }, [selected]);

  return (
    <div className={styles["logs-cont"]}>
      <div className={styles["logsTimeline-cont"]}>
        <div className={styles["history"]}>
          {" "}
          <h4>History Logs</h4>
          <CustomizedSelect noborder={true} placeholder='Most Recent' />
        </div>
        {isProspectLead && (
          <div className={styles["tab-singleapp-container"]}>
            <SingleTab
              text='Prospect'
              handleOnClick={() => setSelected("Prospect")}
              active={"Prospect" === selected ? true : false}
              fontSize={true}
            />
            <SingleTab
              text='Lead'
              handleOnClick={() => setSelected("Lead")}
              active={"Lead" === selected ? true : false}
              fontSize={true}
            />
          </div>
        )}

        <Logs logs={logsToMap} />
      </div>

      {/* <div className={styles["logsEmail-cont"]}>
        {selectedLog?.logStatus !== "Notes Taken" ? (
          <EmailSent selectedEmail={selectedLog} />
        ) : (
          <NotesLog selectedLog={selectedLog} />
        )}
      </div> */}
    </div>
  );
};

export default ApplicantLogs;
