import React from 'react'
import styles from "./Spinere.module.css"
const Spinner = () => {
  return (
  <div className={styles["loader"]}></div>
  
  )
}

export default Spinner
