import React from "react";
import styles from "./onlineForm.module.css";
import attachment from "../../../../assets/Imagess/attachmentIcon.svg";
import availableIcon from "../../../../assets/Imagess/availableICon.svg";
import UnavailableIcon from "../../../../assets/Imagess/notAvailableIcon.svg";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";
const ReferenceInformation = ({ applicant, applicationData }) => {
  return (
    <div className={styles["personal-container"]}>
      <h3>Reference</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title='Title'
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.referanceTitle_Display
            }
            required={
              applicant?.applications[0]?.applyingAs == 8 ? true : false
            }
          />
          <TextContainer
            title='Name'
            required={
              applicant?.applications[0]?.applyingAs == 8 ? true : false
            }
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.referanceName
            }
          />
          <TextContainer
            title='Email'
            required={
              applicant?.applications[0]?.applyingAs == 8 ? true : false
            }
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.referanceEmail
            }
          />
          <TextContainer
            title='How do you know the referance'
            required={false}
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.knowTheReferance
            }
          />
          <TextContainer
            title=' A request of Letter of Recommendationwill be sent via email to
            the reference you provided us'
            required={false}
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]
                ?.sendTheLetterRecomendation === true
                ? "Yes"
                : "No"
            }
          />
          <TextContainer
            title='I have read and understand the above information'
            required={false}
            data={
              applicationData?.applicantReferance &&
              applicationData?.applicantReferance[0]?.readAndUnderstand === true
                ? "Yes"
                : "No"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ReferenceInformation;
