import React, { useEffect, useState } from "react";
import styles from "./TablePagination.module.css";
import leftArrow from "../../../assets/Imagess/leftarrowPagination.svg";
import rightArrow from "../../../assets/Imagess/RightarrowPagination.svg";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
const TablePagination = ({
  page,
  itemsPerPage,
  totalPages,
  setPage,
  setPageSize,
}) => {
  const [innerPageSized, setInnerPageSize] = useState(itemsPerPage);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setPageSize(innerPageSized);
    }, 1000);
    return () => clearTimeout(delayDebounceFn);
  }, [innerPageSized]);

  const MAX_DISPLAY_PAGES = 1;
  const getPageNumbers = () => {
    const pages = [];

    if (totalPages <= MAX_DISPLAY_PAGES) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const halfMaxDisplay = Math.floor(MAX_DISPLAY_PAGES / 2);
      const startPage =
        page <= halfMaxDisplay
          ? 1
          : Math.min(page - halfMaxDisplay, totalPages - MAX_DISPLAY_PAGES + 1);

      const endPage = Math.min(startPage + MAX_DISPLAY_PAGES - 1, totalPages);

      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) {
          pages.push("...");
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pages.push("...");
        }
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value, 10);
    if (!isNaN(newSize) && newSize >= 1) {
      setInnerPageSize(newSize);
    }
  };
  const options = [
    { value: 5, text: "5" },
    { value: 10, text: "10" },
    { value: 15, text: "15" },
    { value: 20, text: "20" },
  ];
  return (
    <div className={styles.tablePagination}>
      <div className={styles.tablePaginationCont}>
        <h4>Show: </h4>
        <div className={styles.pageSizeContainer}>
          <input
            type='number'
            onChange={handlePageSizeChange}
            value={innerPageSized}
          />
        </div>
        {/* <CustomizedSelect
          options={options}
          value={itemsPerPage}
          onChange={(e) => setPageSize(e.target.value)}
        /> */}
      </div>

      <ul className={styles.tablePaginationiul}>
        <Tooltip content='Previous'>
          <li
            onClick={() => page > 1 && setPage(page - 1)}
            className={`${styles.pageItem} ${
              page === 1 ? styles.disabled : ""
            }`}
          >
            <img src={leftArrow} alt='left' />
          </li>
        </Tooltip>
        {getPageNumbers().map((p, index) => {
          return (
            <li
              onClick={() => (typeof p === "number" ? setPage(p) : null)}
              className={`${page === p ? styles.active : styles.inactive}`}
              key={index}
            >
              <a>{p}</a>
            </li>
          );
        })}
        <Tooltip content='Next'>
          <li
            className={`${
              page === totalPages ? styles.disabled : styles.pageItem
            }`}
            onClick={() => page < totalPages && setPage(page + 1)}
          >
            <img src={rightArrow} alt='right' />
          </li>
        </Tooltip>
      </ul>
    </div>
  );
};

export default TablePagination;
