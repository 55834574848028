import React, { useEffect, useRef, useState } from "react";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import TableTopHeader from "../../../Moecules/TableTopHeader/TableTopHeader";
import Buttons from "../../../Atoms/Buttons/Buttons";
import plus from "../../../../assets/Imagess/plusvector.svg";
import styles from "./EmailCampaign.module.css";
import EmailTable from "../../../Moecules/Email/EmailTable";
import {
  useDeleteEmail,
  useDuplicateEmailTemplate,
  useExportNotificationTable,
  useFetchNotifications,
} from "../../../../Hooks/Notification";
import { useLocation, useNavigate } from "react-router-dom";
import { useEmailContext } from "../../../../Context/EmailContext";
import { toast } from "react-toastify";
import DeletePopup from "../../../Moecules/Custom/DeletePopup";
import { useReactToPrint } from "react-to-print";
import PrintComponent from "../../../Moecules/Custom/ExportComponent/PrintComponent";
import Tooltip from "../../../Atoms/Tooltip/Tooltip";
import dublicateIcon from "../../../../assets/Imagess/Audience/dublicate.svg";

const EmailTemCampaignPage = () => {
  const navigate = useNavigate();
  const printRef = useRef();

  const [search, setSearch] = useState("");
  const [emailType, setEmailType] = useState("");
  const [selectedEmail, setSelectedEmail] = useState({});
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const {
    data: emails,
    refetch: refetchEmails,
    isLoading: isEmailLoading,
  } = useFetchNotifications({
    emailType: emailType,
    search: search,
    isCampaign: true,
  });
  const { mutate: deleteEmail, isLoading: isDeletingEmail } = useDeleteEmail();
  const { mutate: duplicateEmail, isLoading: isDuplicating } =
    useDuplicateEmailTemplate();
  const exporNotifcationTable = useExportNotificationTable(1);
  const { setNewSelectedEmail } = useEmailContext();

  const handleEdit = (email) => {
    setNewSelectedEmail(email);
    navigate(`/home/new-email/${email.notificationId}`, {
      state: { isCampaign: true },
    });
  };
  const handleDelete = () => {
    if (selectedEmail && selectedEmail.notificationId) {
      deleteEmail(selectedEmail.notificationId, {
        onSuccess: () => {
          setShowDeleteModal(false);
          refetchEmails();
        },
        onError: (error) => {
          // console.error("Error deleting email", error);
          toast.error("You can't delete an active email");
          setShowDeleteModal(false);
        },
      });
    }
  };
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handleExport = async () => {
    try {
      const data = await exporNotifcationTable.mutateAsync();
      if (data.data instanceof Blob) {
        const blob = new Blob([data.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "EmailCampaignTable";
        link.click();

        window.URL.revokeObjectURL(link.href);
      } else {
        console.error("Unexpected data type. Expected string.");
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };
  const handleDuplicate = () => {
    duplicateEmail(selectedEmail.notificationId, {
      onSuccess: () => {
        setIsDuplicate(false);
        refetchEmails();
        setSelectedEmail({});
        toast.success("Email is duplicated  successfully");
      },
      onError: (error) => {
        // console.error("Error deleting email", error);
        setIsDuplicate(false);
        setSelectedEmail({});
      },
    });
  };
  const additionalButtons = (
    <div className={styles["icon-subcontainer"]}>
      <Buttons
        text='New Template'
        icon={plus}
        rounded={true}
        color='#F3223C'
        // to='/home/new-email'
        handleOnClick={() =>
          navigate("/home/new-email", { state: { isCampaign: true } })
        }
      />
    </div>
  );
  const additionaIcon = (item) => (
    <Tooltip content='Duplicate Icon'>
      <img
        src={dublicateIcon}
        alt='dublicate'
        onClick={(e) => (
          setIsDuplicate((prev) => !prev),
          setSelectedEmail(item),
          e.stopPropagation()
        )}
      />
    </Tooltip>
  );
  return (
    <>
      <WrappingContainer>
        <div className={styles["email-container"]}>
          <TableTopHeader
            isSearch
            search={search}
            setSearch={setSearch}
            additionalButtons={additionalButtons}
            handleExport={handleExport}
            handlePrint={handlePrint}
          />
          <PrintComponent ref={printRef}>
            <EmailTable
              data={emails?.data}
              isEmailLoading={isEmailLoading}
              refetchEmails={refetchEmails}
              handleEdit={handleEdit}
              setShowDeleteModal={setShowDeleteModal}
              setSelectedEmail={setSelectedEmail}
              isCampaign
              additionalIcon={additionaIcon}
            />
          </PrintComponent>
        </div>
      </WrappingContainer>
      {showDeleteModal && (
        <DeletePopup
          handleOnClose={() => (
            setShowDeleteModal(false), setSelectedEmail({})
          )}
          title={selectedEmail?.notificationName}
          handleDelete={handleDelete}
          isLoading={isDeletingEmail}
        />
      )}{" "}
      {isDuplicate && (
        <DeletePopup
          mode='Duplicate'
          handleOnClose={() => (setIsDuplicate(false), setSelectedEmail({}))}
          title={selectedEmail?.notificationName}
          handleDelete={handleDuplicate}
          text='Are you want to duplicate this'
          isLoading={isDuplicating}
        />
      )}
    </>
  );
};

export default EmailTemCampaignPage;
