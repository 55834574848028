export const userHeader = [
  { title: "First Name", key: "firstNameEn", flag: "recruitment" },
  { title: "Last Name", key: "lastNameEn" },
  { title: "username", key: "userName" },
  { title: "Email", key: "email" },
  { title: "Role", key: "roleName" },
];
export const rolesHeader = [
  { title: "Role Name", key: "roleName" },
  { title: "Number Of Users", key: "userCount" },
];
