import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

const getAuthenticatedUser = () => {
  return axios.get(API_URL + `/api/User/GetAuthenticatedUser`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAuthenticatedUser = () => {
  return useQuery({
    queryKey: ["authenticatedUser"],
    queryFn: () => getAuthenticatedUser(),
    enabled: true,
  });
};
