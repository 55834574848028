import React, { useState } from "react";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import styles from "./Dashboard.module.css";
import filter from "../../../assets/Imagess/filter.svg";
import userIcon from "../../../assets/Imagess/userIConDashboard.png";
import passedIcon from "../../../assets/Imagess/passedIconDashboard.png";
import appliedIcon from "../../../assets/Imagess/interestedApplyDashboard.png";
import registerIcon from "../../../assets/Imagess/registericonDashboard.png";
import applyIcon from "../../../assets/Imagess/applyIconDashboard.png";
import PieChart from "../../Moecules/Dashboard/PieChart";
import BarGraph from "../../Moecules/Dashboard/BarGraph";
import LineChart from "../../Moecules/Dashboard/LineChart";
import Filter from "../../Moecules/TableTopHeader/Filter";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import SingleTabGroup from "../../Moecules/SingleApplicant/SingleTabGroup";
import AgeGraph from "../../Moecules/Dashboard/ApplicantDemographics/AgeGraph/AgeGraph";
import GenderGraph from "../../Moecules/Dashboard/ApplicantDemographics/GenderGraph/GenderGraph";
import LevelOfEducation from "../../Moecules/Dashboard/ApplicantDemographics/LevelOfEducation/LevelOfEducation";
import ApplicantDemographics from "../../Moecules/Dashboard/ApplicantDemographics/ApplicantDemographics";
import ApplicationAnalysis from "../../Moecules/Dashboard/ApplicationAnalysis/ApplicationAnalysis";
import CampaignReflection from "../../Moecules/Dashboard/CampaignReflection/CampaignReflection";
const DashboardPage = () => {
  const [selected, setSelected] = useState(0);

  const statistics = [
    {
      title: "Total Applicant",
      icon: userIcon,
      number: "300K",
    },
    {
      title: "Applicant Passed",
      icon: passedIcon,
      number: "150K",
    },
    {
      title: "Interest to Applied",
      icon: appliedIcon,
      number: "20K",
    },
    {
      title: "Applicant who Register",
      icon: registerIcon,
      number: "500K",
    },
    {
      title: "Direct Apply",
      icon: applyIcon,
      number: "9K",
    },
  ];

  const tabContainer = [
    {
      value: 0,
      text: "Applicant Demographics",
      component: <ApplicantDemographics />,
    },
    {
      value: 1,
      text: "Application Analysis",
      component: <ApplicationAnalysis />,
    },
    {
      value: 2,
      text: "Campaign Reflection",
      component: <CampaignReflection />,
    },
  ];
  return (
    <WrappingContainer>
      <div className={styles["welcome-div"]}>
        <h2>Welcome To AUD CRM!</h2>
        <Tooltip content='Filter'>
          <img src={filter} alt='filter' />
        </Tooltip>
        {/* <Filter/> */}
      </div>
      <div className={styles["statistical-div"]}>
        {statistics.map((item, index) => {
          return (
            <div
              className={`${styles["statistical-sub-cont"]} ${
                index == 0 ? styles["item1"] : ""
              }`}
              key={index}
            >
              <h4>{item.title}</h4>
              <div className={styles["stat-img-sub-cont"]}>
                <img
                  src={item.icon}
                  alt='icon'
                  className={styles["stat-img"]}
                />
                <h3> {item.number}</h3>
              </div>
            </div>
          );
        })}
      </div>
      <SingleTabGroup
        tabContainer={tabContainer}
        selected={selected}
        setSelected={setSelected}
      />
      {/* <div className={styles["chart-div"]}>
        <BarGraph />
        <PieChart />
      </div>
      <div className={styles["graph-div"]}>
        <LineChart />
      </div> */}
      {tabContainer.find((tab) => tab.value === selected)?.component}
    </WrappingContainer>
  );
};

export default DashboardPage;
