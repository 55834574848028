import React from "react";
import FilledUnFilledText from "../../Atoms/CustomizedAtoms/FilledUnFilledText";
import HorizantalLine from "../../Atoms/Lines/HorizantalLine";
import email from "../../../assets/Imagess/emailicon.svg";
import phone from "../../../assets/Imagess/phoneicon.svg";
import styles from "../../Template/SingleApplicantTemplate/SingleApplicantPage.module.css";
import CautionIcon from "../../../assets/Imagess/cautionIconApplicant.png";
import USerProfileIcon from "../../../assets/Imagess/usreprofileicon.svg";
import Loader from "../Loader/Loader";
import schoolStudent from "../../../assets/Imagess/Contact/schoolStudentIcon.svg";
const SingleApplicantTopHeader = ({
  data,
  isProspectLead,
  rightComponent = {
    icon: "",
    text: "",
    handleFunction: null,
  },
  bottomLeftComponent,
  isLoading,
  isStudent = false,
}) => {
  return (
    <div className={styles["singleApplicantTopHeader_container"]}>
      <div className={styles["first-container"]}>
        <img
          src={USerProfileIcon}
          alt='icon'
          className={styles["userProfile"]}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <div className={styles["subcontainer"]}>
            <div className={styles["title-subcontainer-div"]}>
              <h3>
                {data?.firstName} {data?.lastName}
              </h3>
              {isStudent && (
                <span className={styles["recruitment_type"]}>
                  <img src={schoolStudent} alt='recruitment icon' />
                  <h5>School Student</h5>
                </span>
              )}
            </div>
            <div className={styles["title-subcontainer"]}>
              <div className={styles["title-subcontainer-div"]}>
                <div className={styles["emailContainer"]}>
                  <img src={email} className='icon-img' alt='icon' />{" "}
                  <a href={`mailto:${data?.email}`}>{data?.email}</a>
                </div>
                <div className={styles["emailContainer"]}>
                  <img src={phone} className='icon-img' alt='icon' />{" "}
                  <FilledUnFilledText
                    required={true}
                    mobile={true}
                    data={data?.mobile}
                  />
                </div>
              </div>
              <div className={styles["title-pop-subcontainer"]}>
                <div
                  onClick={rightComponent.handleFunction}
                  style={{
                    color:
                      rightComponent.text == "Unblock User" ? "#2BA62C" : "",
                  }}
                >
                  <img src={rightComponent.icon} className='icon-img' />{" "}
                  {rightComponent.text}
                </div>
              </div>
            </div>
            <div className={styles["desktop"]}>
              <HorizantalLine />
              {bottomLeftComponent && (
                <div className={styles["subTitle-cont"]}>
                  {bottomLeftComponent?.map((item, index) => {
                    return (
                      <div key={index} className={styles["subTitle-img-cont"]}>
                        <img src={item.icon} />
                        <p> {item.text}</p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className={styles["details-container"]}>
              {isProspectLead ? (
                <div className={styles["caution-container"]}>
                  <img src={CautionIcon} alt='cautionIcon' />
                  <p>
                    Existing multi-application as <strong>"Interest"</strong>{" "}
                    for <strong> {data?.firstName}</strong>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
      <div className={styles["mobile"]}>
        <HorizantalLine />
        {bottomLeftComponent && (
          <div className={styles["subTitle-cont"]}>
            {bottomLeftComponent?.map((item, index) => {
              return (
                <div key={index} className={styles["subTitle-img-cont"]}>
                  <img src={item.icon} />
                  <p> {item.text}</p>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleApplicantTopHeader;
