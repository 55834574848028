import React, { useEffect, useState } from "react";
import Styles from "./Input.module.css";

const TextArea = ({
  label,
  required,
  value,
  onChange,
  rows,
  cols,
  placeholder,
  name,
  errors,
  touched,
  disabled,
}) => {
  const [initialValue, setInitialValue] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInitialValue(newValue);
    if (onChange) {
      onChange(name, newValue);
    }
  };

  useEffect(() => {
    setInitialValue(value);
  }, [value]);

  return (
    <div
      className={`${Styles["textBox-container"]} ${
        errors && touched ? Styles["textBox-container-error"] : ""
      }`}
      style={{ cursor: disabled ? "not-allowed" : "pointer" }}
    >
      <label htmlFor={label}>
        {label}
        {required && <span className={Styles.required}>*</span>}
      </label>
      <textarea
        id={label}
        required={required}
        onChange={handleChange}
        value={initialValue}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        disabled={disabled}
      />
    </div>
  );
};

export default TextArea;
