import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import WrappingContainer from "../../HOC/WrappingContainer/WrappingContainer";
import styles from "./SingleAudiencePage.module.css";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import WhiteBoxContainer from "../../HOC/WrappingContainer/WhiteBoxContainer";
import Buttons from "../../Atoms/Buttons/Buttons";
import ColoredButton from "../../Atoms/Buttons/ColoredButtons/ColoredButton";
import SingleTabGroup from "../../Moecules/SingleApplicant/SingleTabGroup";
import SingleAudience from "../../Moecules/SingleAudience/SingleAudience";
import SingleCampaignAudience from "../../Moecules/SingleAudience/SingleCampaignAudience";
import {
  useDeleteAudiencebyId,
  useFetchAudienceById,
} from "../../../Hooks/Audience";
import DeletePopup from "../../Moecules/Custom/DeletePopup";
import { toast } from "react-toastify";
import DuplicateModal from "../../Moecules/Audience/DuplicateModal";
import Loader from "../../Moecules/Loader/Loader";
const SingleAudiencePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [selected, setSelected] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const [isDuplicate, setISDuplicate] = useState(false);

  const { mutate: deleteAudience, isLoading: isDeletingAudience } =
    useDeleteAudiencebyId();
  const { data: audienceByIdData, isLoading: isAudienceLoading } =
    useFetchAudienceById(id);
  const tabContainer = [
    {
      text: "Audience List",
      value: 0,
    },
    {
      text: "Related Campaigns",
      value: 1,
    },
  ];

  const handleDelete = () => {
    deleteAudience(id, {
      onSuccess: () => {
        toast.success(` Audience is deleted Successfully`);
        setIsDelete(false);
        navigate("/home/campaign-audience");
      },
      onError: (error) => {
        toast.error(`${error.response.data}`);
        setIsDelete(false);
      },
    });
  };

  const handleEdit = () => {
    navigate(`/home/new-audience/${id}`);
  };

  const handleDuplicate = () => {
    setISDuplicate((prev) => !prev);
  };

  if (isAudienceLoading) {
    return <Loader />;
  }

  return (
    <>
      <WrappingContainer>
        <WhiteBoxContainer>
          <div className={styles["header_container"]}>
            <h1>{audienceByIdData?.data?.audience?.audienceName}</h1>
            <div className={styles["buttons_container"]}>
              <ColoredButton
                title='Duplicate'
                blue
                rounded
                handleOnClick={handleDuplicate}
              />
              <ColoredButton
                title='Edit'
                blue
                rounded
                handleOnClick={handleEdit}
              />
              <ColoredButton
                title='Delete'
                red
                rounded
                handleOnClick={() => setIsDelete((prev) => !prev)}
              />
            </div>
          </div>
        </WhiteBoxContainer>
        <SingleTabGroup
          tabContainer={tabContainer}
          selected={selected}
          setSelected={setSelected}
        />
        {selected == 0 ? (
          <SingleAudience
            audienceDetails={audienceByIdData?.data?.audienceDetails}
          />
        ) : (
          <SingleCampaignAudience
            campaigns={audienceByIdData?.data?.campaigns}
          />
        )}
      </WrappingContainer>
      {isDelete && (
        <DeletePopup
          handleOnClose={() => setIsDelete((prev) => !prev)}
          handleDelete={handleDelete}
          title={`Audience`}
          isLoading={isDeletingAudience}
        />
      )}
      {isDuplicate && (
        <DuplicateModal
          handleOnClose={() => setISDuplicate((prev) => !prev)}
          selectedAudience={{ campaignAudienceId: id }}
        />
      )}
    </>
  );
};

export default SingleAudiencePage;
