import React, { useEffect, useState } from "react";
import ApplicantsTab from "../../../Atoms/Tabs/ApplicantsTab";
import OnlineForm from "../OnlineForm/OnlineForm";
import styles from "./OnlineFormContainer.module.css";
import CustomizedSelect from "../../../Atoms/Inputs/CustomizedSelect";
import { useFetchApplicationByApplicantAndApplication } from "../../../../Hooks/SingleApplicant";
import { useParams } from "react-router-dom";
import StatusContainer from "../../Custom/StatusContainer";
import FilledUnFilledText from "../../../Atoms/CustomizedAtoms/FilledUnFilledText";
import statusicon from "../../../../assets/Imagess/statusicon.svg";
import Loader from "../../Loader/Loader";

const OnlineFormContainer = ({
  applicant,
  isApplicant,
  isLoading,
  setShowStatusModal,
}) => {
  const [selectedApplication, setSelectedApplication] = useState("graduate");
  const [applicationId, setApplicationId] = useState();
  const { id } = useParams();
  const [defaultApplication, setDefaultApplication] = useState();

  const { data: applicationDataById } =
    useFetchApplicationByApplicantAndApplication(
      id,
      applicationId?.value || defaultApplication?.applicationId
    );
  const data1 = applicant?.applications?.filter(
    (item) => item.startYourApplication === 0
  );
  const data2 = applicant?.applications?.filter(
    (item) => item.startYourApplication === 1
  );
  const data3 = applicant?.applications?.filter(
    (item) => item.startYourApplication === 2
  );
  useEffect(() => {
    if (data1 && data1.length > 0) {
      setSelectedApplication("undergraduate");
      setDefaultApplication(data1[0]);
    } else if (data2 && data2.length > 0) {
      setSelectedApplication("graduate");
      setDefaultApplication(data2[0]);
    } else if (data3 && data3.length > 0) {
      setSelectedApplication("visiting");
      setDefaultApplication(data3[0]);
    }
  }, [data1, data2, data3]);
  useEffect(() => {
    if (data1 && data1.length > 0) {
      setDefaultApplication(data1[0]);
    } else if (data2 && data2.length > 0) {
      setDefaultApplication(data2[0]);
    } else if (data3 && data3.length > 0) {
      setDefaultApplication(data3[0]);
    }
  }, []);

  const selectedOptions =
    selectedApplication === "undergraduate"
      ? data1
        ? data1?.map((option) => ({
            value: option.applicationId,
            text: option.fieldOfInterest_Display,
          }))
        : []
      : selectedApplication === "graduate"
      ? data2
        ? data2?.map((option) => ({
            value: option.applicationId,
            text: option.fieldOfInterest_Display,
          }))
        : []
      : data3
      ? data3?.map((option) => ({
          value: option.applicationId,
          text: option.fieldOfInterest_Display,
        }))
      : [];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div className={styles["onlinform-container"]}>
      <div>
        {isApplicant ? (
          <div className={styles["tab-container"]}>
            {data1?.length > 0 && (
              <ApplicantsTab
                text='Undergraduate'
                active={selectedApplication === "undergraduate" ? true : false}
              />
            )}
            {data2?.length > 0 && (
              <ApplicantsTab
                text='Graduate'
                active={selectedApplication === "graduate" ? true : false}
              />
            )}
            {data3?.length > 0 && (
              <ApplicantsTab
                text='Visiting'
                active={selectedApplication === "visiting" ? true : false}
              />
            )}
          </div>
        ) : (
          ""
        )}

        <div className={styles["applicationTab-subcontainer"]}>
          <div className={styles["tab-subcontainer"]}>
            {isApplicant ? (
              <div className={styles["select_subcontainer"]}>
                {" "}
                <h3>Select Application: </h3>
                <div style={{ width: "auto" }}>
                  <CustomizedSelect
                    placeholder={defaultApplication?.fieldOfInterest_Display}
                    noborder={true}
                    options={selectedOptions}
                    value={applicationId || defaultApplication}
                    onChange={setApplicationId}
                  />
                </div>
              </div>
            ) : (
              " "
            )}
            {applicant?.applications &&
              applicant?.applications[0]?.statusText === "Converted" && (
                <StatusContainer
                  status={
                    applicant?.applications &&
                    applicant?.applications[0]?.statusText
                  }
                />
              )}
            <div className={styles["details-subcontainer"]}>
              {isApplicant && (
                <div>
                  <h4>Program:</h4>{" "}
                  <h5>
                    <FilledUnFilledText
                      required={true}
                      data={
                        applicant?.applications &&
                        applicant?.applications[0]?.programOfInterest_Display
                      }
                    />
                  </h5>
                </div>
              )}
              <div>
                <h4>Field of Interest:</h4>{" "}
                <h5>
                  <FilledUnFilledText
                    required={true}
                    data={applicant?.intersted?.fieldOfInterest_Display}
                  />
                </h5>
              </div>

              <div>
                <h4>Status:</h4>
                <h5>
                  <StatusContainer status={applicant?.intersted?.statusText} />
                </h5>
              </div>
            </div>
          </div>
          <div
            onClick={() => setShowStatusModal(true)}
            className={styles["status-icon"]}
          >
            <img src={statusicon} className='icon-img' alt='icon' /> Change
            Status
          </div>
        </div>
      </div>

      <div>
        <OnlineForm
          applicant={applicant}
          applicationData={applicationDataById?.data}
          isApplicant={isApplicant}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default OnlineFormContainer;
