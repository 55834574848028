import React from "react";
import WhiteContainer from "../../HOC/WrappingContainer/WhiteContainer";
import CampaignDetailedBox from "../Campaign/CampaignDetails/CampaignDetailedBox";
import EmptyText from "../Custom/EmptyText";

const SingleCampaignAudience = ({ campaigns }) => {
  console.log("cvoeroijvorhvr", campaigns);
  return (
    <WhiteContainer white>
      {campaigns?.length > 0 ? (
        campaigns?.map((item) => {
          return <CampaignDetailedBox data={item}  contact/>;
        })
      ) : (
        <EmptyText text='Campaigns' />
      )}
    </WhiteContainer>
  );
};

export default SingleCampaignAudience;
