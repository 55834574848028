import axios from "axios";
import { useMutation, useQuery } from "react-query";
import { API_URL } from "../Constants";

//post an email
const addEmail = (values) => {
  return axios.post(API_URL + `/api/Notifications/PostNotifications`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useAddNotification = () => {
  return useMutation(addEmail);
};

//get an email
const fetchNotifications = async (data) => {
  let url = `${API_URL}/api/Notifications/Notifications/${data?.isCampaign}`;

  const queryParams = [];
  if (data?.emailType) queryParams.push(`NotificationType=${data?.emailType}`);
  if (data?.search) queryParams.push(`SearchBy=${data?.search}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }

  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchNotifications = (emailType) => {
  return useQuery({
    queryKey: ["notifications", emailType],
    queryFn: () => fetchNotifications(emailType),
    enabled: true,
  });
};

///delete email
const deleteEmail = (notificationId) => {
  return axios.delete(
    API_URL + `/api/Notifications/DeleteNotification/${notificationId}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDeleteEmail = () => {
  return useMutation(deleteEmail);
};

//send email to applicants
const sendEmail = (values) => {
  return axios.post(API_URL + `/api/Notifications/SendNotification`, values, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};

export const useSendEmail = () => {
  return useMutation(sendEmail);
};

//send email to interested
const sendEmailInterested = (values) => {
  return axios.post(
    API_URL + `/api/Notifications/SendShowingInterestNotification`,
    values,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useSendEmailInterested = () => {
  return useMutation(sendEmailInterested);
};

///get email types

const fetchEmailTypes = async () => {
  return await axios.get(API_URL + "/api/Notifications/NotificationsTypes", {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchEmailTypes = () => {
  return useQuery({
    queryKey: ["emailTypes"],
    queryFn: () => fetchEmailTypes(),
    enabled: true,
  });
};

//change notification status

const changeEmailStatus = async (values) => {
  return await axios.post(
    API_URL +
      `/api/Notifications/ChangeNoticationStatus?NotificationId=${values.NotificationId}&isActive=${values.isActive}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useChangeEmailStatus = () => {
  return useMutation(changeEmailStatus);
};

//get active emails

const fetchActiveNotifications = async (isCampaign, notificatioType) => {
  let url = `${API_URL}/api/Notifications/ActiveNotifications/${isCampaign}`;

  const queryParams = [];
  if (notificatioType) queryParams.push(`NotifcationType=${notificatioType}`);

  if (queryParams.length > 0) {
    url += `?${queryParams.join("&")}`;
  }
  return await axios.get(url, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchActiveNotifications = (isCampaign, notificatioType) => {
  return useQuery({
    queryKey: ["activeNotifications", isCampaign, notificatioType],
    queryFn: () => fetchActiveNotifications(isCampaign, notificatioType),
    enabled: true,
  });
};
//*********export notification table */
const exportNotification = async (type) => {
  return await axios.get(API_URL + `/api/Export/ExportNotification/${type}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    responseType: "blob",
  });
};
export const useExportNotificationTable = (type) => {
  return useMutation(() => exportNotification(type));
};

///get email types

const fetchNotificationById = async (id) => {
  if (id) {
    return await axios.get(
      API_URL + `/api/Notifications/NotificationsById/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }
    );
  }
};
export const useFetchNotificationsById = (id) => {
  return useQuery({
    queryKey: ["notificationsById", id],
    queryFn: () => fetchNotificationById(id),
    enabled: true,
  });
};

//duplicate email template

const DuplicateEmailTemplate = async (id) => {
  return await axios.post(
    API_URL + `/api/Notifications/DuplicateNotification/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    }
  );
};

export const useDuplicateEmailTemplate = () => {
  return useMutation(DuplicateEmailTemplate);
};

//fetch values  for text editor
const fetchNotificationOPtions = async () => {
  return axios.get(API_URL + `/api/Notifications/GetNotificationOptions`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  });
};
export const useFetchNotificationOptions = () => {
  return useQuery({
    queryKey: ["NotificationOptions"],
    queryFn: () => fetchNotificationOPtions(),
    enabled: true,
  });
};
