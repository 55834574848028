import React from "react";
import MoreButton from "../../../../Custom/PopOver/MoreButton";
import PopOver from "../../../../Custom/PopOver/PopOver";
import plusIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/plusIcon (2).svg";
import addActionIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/addActionVector.svg";
import addwaitIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/addWaitVector.svg";
import addemailIcon from "../../../../../../assets/Imagess/Campaign/NewCampaign/addEmailVector.svg";
import addcondition from "../../../../../../assets/Imagess/Campaign/NewCampaign/addConditionIcon.svg";

const AdditionalList = ({ onAddNode, included }) => {
  return (
    <MoreButton
      icon={plusIcon}
      off
      center
      imgWidth='15px'
      imgHeight='15px'
      // included
      toolTipText="More"
      placement="bottom"
    >
      <PopOver
        info={[
          {
            title: "Add Email",
            icon: addemailIcon,
            handleFunction: () => onAddNode("rectangularNode", "email"),
          },
          {
            title: "Add Wait Time",
            icon: addwaitIcon,
            handleFunction: () => onAddNode("rectangularNode", "wait"),
          },
          {
            title: "Add Action",
            icon: addActionIcon,
            handleFunction: () => onAddNode("rectangularNode", "action"),
          },
          {
            title: "Add Condition",
            icon: addcondition,
            handleFunction: () => onAddNode("diamondNode", "condition"),
          },
        ]}
      />
    </MoreButton>
  );
};

export default AdditionalList;
