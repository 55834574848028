import React, { useState } from "react";
import styles from "./EmailsReceivedList/EmailsReceivedList.module.css";
import ReceivedEmailPopup from "./ReceivedEmailPopup";

const MobileRecievedEmail = ({ data }) => {
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState({});
  return (
    <>
      <div
        className={styles["receivedEmailList_container"]}
        style={{ width: "100%" }}
      >
        {data?.map((item, index) => {
          return (
            <div
              key={index}
              className={`${styles["received_List"]}`}
              onClick={() => (setSelectedEmail(item), setIsShowPopup(true))}
            >
              <div className={styles["List"]}>
                <h2>{item.subject}</h2>
                <h3>{item.text}</h3>
              </div>
            </div>
          );
        })}
      </div>
      {isShowPopup && (
        <ReceivedEmailPopup
          selectedEmail={selectedEmail}
          handleOnClose={() => (setIsShowPopup(false), setSelectedEmail({}))}
        />
      )}
    </>
  );
};

export default MobileRecievedEmail;
