import React from "react";
import Notes from "./Notes";
import ProspectLeadNotes from "./ProspectLeadNotes";
import styles from "./Notes.module.css";
const NotesContainer = ({
  notes,
  id,
  refetch,
  isProspectLead,
  applicantLeadNotes,
}) => {
  return (
    <>
      {isProspectLead ? (
        <div className={styles["notes-leadProspect-cont"]}>
          <Notes notes={notes} refetch={refetch} id={id} />
          <ProspectLeadNotes
            applicantLeadNotes={applicantLeadNotes}
            refetch={refetch}
            id={applicantLeadNotes && applicantLeadNotes[0]?.applicantId}
          />
        </div>
      ) : (
        <Notes notes={notes} refetch={refetch} id={id} />
      )}
    </>
  );
};

export default NotesContainer;
