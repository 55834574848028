export const prospectHeader = [
  { title: "First Name", key: "firstName" },
  { title: "Last Name", key: "lastName" },
  { title: "Program Type", key: "applicationStart_Display" },
  { title: "Field", key: "fieldOfInterest_Display" },
  { title: "Phone", key: "mobile", flag: "mobile" },
  { title: "Email", key: "email" },
  { title: "User Assign", key: "userAssigned" },
  { title: "Date Applied", key: "createdOn", flag: "dateTime" },
  { title: "Status", key: "statusText", flag: "status" },
  { title: "Notes Count", key: "noteCount" },
  { title: "Email Count", key: "emailCount" },

  
];

export const leadTableHeader = [
  { title: "First Name", key: "firstName" },
  { title: "Last Name", key: "lastName" },
  { title: "Program Type", key: "applicationStart_Display" },
  { title: "Field", key: "fieldOfInterest_Display" },
  { title: "Phone", key: "mobile", flag: "dublicate" },
  { title: "Email", key: "email", flag: "recruitment" },
  { title: "User Assign", key: "userAssigned" },
  { title: "Created On", key: "dateApplied", flag: "dateTime" },
  { title: "Status", key: "statusText", flag: "status" },
  { title: "Notes Count", key: "noteCount" },
];
