import React, { useState } from "react";
import styles from "../Users/UsersTable.module.css";
import deleteIcon from "../../../assets/Imagess/deleteicon.svg";
import editIcon from "../../../assets/Imagess/Edit (1).svg";
import Loader from "../Loader/Loader";
import Toggle from "../../Atoms/Inputs/Toggle";
import { useChangeEmailStatus } from "../../../Hooks/Notification";
import Tooltip from "../../Atoms/Tooltip/Tooltip";
import { useLocation } from "react-router-dom";

const EmailTable = ({
  data,
  setShowDeleteModal,
  setSelectedEmail,
  handleEdit,
  additionalIcon,
  isEmailLoading,
  refetchEmails,
  setOrderBy,
  setOrderDir,
  orderDir,
  orderBy,
  isCampaign = false,
}) => {
  const [isActiveStatus, setIsActiveStatus] = useState(false);
  const [rotatedColumn, setRotatedColumn] = useState(null);
  const [loadingToggleIndex, setLoadingToggleIndex] = useState(null);
  const location = useLocation();
  const changeEmailStatus = useChangeEmailStatus();

  if (isEmailLoading) {
    return <Loader />;
  }

  const orderByFunction = (item, index) => {
    setTimeout(() => {
      const nameToUpperCase = item[0].toUpperCase() + item.slice(1);
      setOrderBy(nameToUpperCase);
      setRotatedColumn(item);
      const newOrderDir = orderDir === "asc" ? "desc" : "asc";
      setOrderDir(newOrderDir);

      data.refetch({
        pageNumber: data.page,
        orderDir: newOrderDir,
        orderBy: nameToUpperCase,
      });
    }, 500);
  };

  const handleToggleChange = async (index) => {
    setLoadingToggleIndex(index);
    const updatedData = [...data];
    updatedData[index].isActive = !updatedData[index].isActive;

    try {
      await changeEmailStatus.mutate(
        {
          NotificationId: updatedData[index].notificationId,
          isActive: updatedData[index].isActive,
        },
        {
          onSuccess: () => {
            refetchEmails();
            setLoadingToggleIndex(null);
          },
          onError: () => {
            setLoadingToggleIndex(null);
          }
        }
      );
    } catch (error) {
      console.error("Error occurred while changing email status:", error);
      setLoadingToggleIndex(null);
    }
  };

  return (
    <div className={styles["container"]}>
      <table className={styles["userstable-container"]}>
        <thead>
          <tr>
            <th>Name</th>
            {!isCampaign && <th>Email Type</th>}
            <th>Attachment</th>
            <th>Created By</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            <>
              {data?.map((item, index) => {
                const isButtonDisabled = loadingToggleIndex !== null && loadingToggleIndex !== index;
                return (
                  <tr key={item.notificationId}>
                    <td>{item.notificationName || "-"}</td>
                    {!isCampaign && <td>{item.notificationType_Display || "-"}</td>}
                    <td>{item.hasAttachment || "-"}</td>
                    <td>{item.userCreated || "-"}</td>
                    <td>
                      <Toggle
                        isActive={item.isActive}
                        onChange={() => handleToggleChange(index)}
                        disabled={isButtonDisabled}
                      />
                    </td>
                    <td className={styles["icon-cont-table"]}>
                      {additionalIcon ? additionalIcon(item) : ""}
                      <Tooltip content='Edit'>
                        <img
                          src={editIcon}
                          alt='icon'
                          onClick={() => handleEdit(item)}
                          disabled={isButtonDisabled}
                        />
                      </Tooltip>
                      <Tooltip content='Delete' red>
                        <img
                          src={deleteIcon}
                          alt='icon'
                          onClick={() => (
                            setShowDeleteModal(true), setSelectedEmail(item)
                          )}
                          disabled={isButtonDisabled}
                        />
                      </Tooltip>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <>
              <tr>
                <td>No data</td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default EmailTable;
