import React, { useState } from "react";
import Chart from "react-apexcharts";
import DashboardContainer from "../../DashboardContainer";

const StackedColumnGraph = () => {
  const [options, setOptions] = useState({
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 20,
        columnWidth: "50%",
      },
    },
    xaxis: {
      categories: [
        "Campaign 1",
        "Campaign 2",
        "Campaign 3",
        "Campaign 4",
        "Campaign 5",
        "Campaign 6",
        "Campaign 7",
      ],
    },
    legend: {
      position: "top",
    },
    fill: {
      opacity: 1,
    },
    dataLabels: {
      enabled: true,
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return val + "%";
        },
      },
    },
    colors: ["#9FD5F3", "#0078E6"],
  });

  const [series, setSeries] = useState([
    {
      name: "Interested",
      data: [44, 55, 41, 67, 22, 43, 21],
    },
    {
      name: "Converted",
      data: [13, 23, 20, 8, 13, 27, 33],
    },
  ]);

  return (
    <DashboardContainer>
      <h2>Converted to Interest per Campaign</h2>
      <Chart options={options} series={series} type='bar' height={350} />
    </DashboardContainer>
  );
};

export default StackedColumnGraph;
