import React, { useEffect, useState } from "react";
import WrappingContainer from "../../../HOC/WrappingContainer/WrappingContainer";
import SettingsTopHeader from "../SettingsMolecules/SettingsTopHeader";
import TitlePageHeader from "../SettingsMolecules/TitlePageHeader";
import WhiteContainer from "../../../HOC/WrappingContainer/WhiteContainer";
import ExternalContactForm from "./ExternalContactForm";
import { FormikProvider, useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import externalContactValidationSchema from "./externalContactValidationSchema";
import {
  useAddExternalContact,
  useFetchExternalContactById,
} from "../../../../Hooks/Externalcontact";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";

const AddExternalContact = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { id } = params;

  const [init, setInit] = useState({});

  const [mode, setMode] = useState(location?.state?.mode || "add");
  const {
    data: contactDetails,
    refetch,
    isLoading: isLoadingExternalContactById,
  } = useFetchExternalContactById({ id });
  const { mutate: addExternalContact, isLoading: isAdding } =
    useAddExternalContact();
  useEffect(() => {
    const initialvalues = {
      contactName: contactDetails?.data?.externalContact?.contactName || "",
      mobile: contactDetails?.data?.externalContact?.mobile || "",
      email: contactDetails?.data?.externalContact?.email || "",
      contactTypeId: contactDetails?.data?.externalContact?.contactTypeId || "",
      schoolName: contactDetails?.data?.externalContact?.schoolName || "",
      priorityId: contactDetails?.data?.externalContact?.priorityId || "",
      sendMailer: contactDetails?.data?.externalContact?.sendMailer || false,
      countryId: contactDetails?.data?.externalContact?.countryId || "",
      nationalityId: contactDetails?.data?.externalContact?.nationalityId || "",
      jobTitle: contactDetails?.data?.externalContact?.jobTitle || "",
      languageId: contactDetails?.data?.externalContact?.languageId || "",
      curriculumId: contactDetails?.data?.externalContact?.curriculumId || "",
      gradeId: contactDetails?.data?.externalContact?.gradeId || "",
      highSchoolGPA: contactDetails?.data?.externalContact?.highSchoolGPA || "",
    };
    setInit(initialvalues);
  }, [contactDetails]);

  useEffect(() => {
    refetch({ id });
  }, [id]);

  const formik = useFormik({
    initialValues: init,
    validationSchema: externalContactValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const includedValues = {
        externalContactId:
          contactDetails?.data?.externalContact?.externalContactId ||
          "00000000-0000-0000-0000-000000000000",
      };

      const filteredValues = Object.fromEntries(
        Object.entries(values).filter(
          ([key, value]) =>
            value !== undefined &&
            value !== "" &&
            !(
              (key === "curriculumId" ||
                key === "gradeId" ||
                key === "highSchoolGPA") &&
              values.contactTypeId !== "e1cffe75-79d4-4517-ada5-a75fc192a510"
            )
        )
      );

      const valuesToSend = { ...filteredValues, ...includedValues };

      addExternalContact(valuesToSend, {
        onSuccess: () => {
          toast.success(
            id
              ? "Contact is updated successfully"
              : "Contact is added successfully"
          );
          navigate("/home/settings", {
            state: {
              type: 21,
              selected: contactDetails?.data?.externalContact?.isBlocked
                ? "Blocked Contacts"
                : "All",
            },
          });
        },
        onError: (error, data) => {
          toast.error("Error");
        },
      });
    },
  });

  if (isLoadingExternalContactById || isAdding) {
    return <Loader />;
  }

  return (
    <WrappingContainer>
      <WhiteContainer white>
        <FormikProvider value={formik}>
          <TitlePageHeader
            title={id == null ? "Create New Contact" : "Update Contact"}
            buttonText={id == null ? "Save" : "Update"}
            type='submit'
            handleOnClick={formik.submitForm}
            isLoading={isAdding}
          />
          <ExternalContactForm />
        </FormikProvider>
      </WhiteContainer>
    </WrappingContainer>
  );
};

export default AddExternalContact;
