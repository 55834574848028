import React, { useState } from "react";
import styles from "../../../Atoms/Modal/Modal.module.css";
import Modal from "../../../Atoms/Modal/Modal";
import TextInput from "../../../Atoms/Inputs/TextInput";
import Buttons from "../../../Atoms/Buttons/Buttons";
import folder from "../../../../assets/Imagess/folderICon.svg";
import hash from "../../../../assets/Imagess/hashicon.svg";
import ModalChildrenContainer from "../../../Atoms/Modal/ModalChildrenContainer";
import {
  useAddAcademicYear,
  useAddValuesForType,
  useEditValuesForTypes,
} from "../../../../Hooks/Settings";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Loader from "../../Loader/Loader";
import LoaderButton from "../../Loader/LoaderButton";
import { toast } from "react-toastify";
import DateInput from "../../../Atoms/Inputs/DateInput";
import Toggle from "../../../Atoms/Inputs/Toggle";

const AcademicYearsModal = ({
  handleOnClose,
  title,
  mode,
  refetch,
  selectedItem,
  setSelectedItem,
}) => {
  const { mutate: addAcademicYear, isLoading: isAddYearLoading } =
    useAddAcademicYear();

  const initialValues = {
    acadamicYearId:
      selectedItem?.acadamicYearId || "00000000-0000-0000-0000-000000000000",
    academicName: selectedItem?.academicName || "",
    shortName: selectedItem?.shortName || "",
    startDate: selectedItem?.startDate?.substring(0, 10) || "",
    endDate: selectedItem?.endDate?.substring(0, 10) || "",
    active: selectedItem?.active || false,
  };
  const validationSchema = Yup.object().shape({
    acadamicYearId: Yup.string().required("acadamicYearId is required"),
    academicName: Yup.string().required("academicName is required"),
    shortName: Yup.string().required("shortName is required"),
    startDate: Yup.date().required("startDate is required"),
    endDate: Yup.date().required("endDate is required"),
    active: Yup.boolean().required("active is required"),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        addAcademicYear(values, {
          onSuccess: () => {
            handleOnClose();
            refetch();
            setSelectedItem({});

            if (mode === "add") {
              toast.success(`${values.academicName} is successfully added`);
            } else {
              toast.success(`${values.academicName} is successfully updated`);
            }
          },
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
       
        const handleToggleChange = () => {
          setFieldValue("active", !values.active);
        };
        return (
          <Form>
            <Modal
              onClose={handleOnClose}
              title={mode == "add" ? "Add" + " " + title : "Edit" + " " + title}
              width='40%'
            >
              <ModalChildrenContainer>
                <TextInput
                  label='Academic Year Name'
                  icon={folder}
                  placeholder='Academic Year'
                  name='academicName'
                  value={values.academicName}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.academicName}
                  touched={touched.academicName}
                />
                <TextInput
                  label='Short Academic Year Name'
                  icon={folder}
                  placeholder='Short Term Name'
                  name='shortName'
                  value={values.shortName}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.shortName}
                  touched={touched.shortName}
                />{" "}
                <Toggle
                  isActive={values.active}
                  onChange={handleToggleChange}
                  text='Active Year'
                />
                <DateInput
                  label='Start Date'
                  name='startDate'
                  required={true}
                  value={values.startDate}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.startDate}
                  touched={touched.startDate}
                />
                <DateInput
                  label='End Date'
                  name='endDate'
                  value={values.endDate}
                  required={true}
                  onChange={(name, value) => {
                    setFieldValue(name, value);
                  }}
                  errors={errors.endDate}
                  touched={touched.endDate}
                  startDate={values.startDate}
                />
                {isAddYearLoading ? (
                  <LoaderButton />
                ) : (
                  <Buttons
                    text={mode == "add" ? "Save" : "update"}
                    type='Submit'
                    handleOnClick={handleSubmit}
                  />
                )}
              </ModalChildrenContainer>
            </Modal>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AcademicYearsModal;
