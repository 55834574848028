import React from "react";
import styles from "./onlineForm.module.css";
import TextContainer from "../../../Atoms/CustomizedAtoms/TextContainer";

const AcademicInfo = ({ applicant, applicationData }) => {
  
  return (
    <div className={styles["personal-container"]}>
      <h3>Academic Information</h3>
      <div className={styles["personalsub-container"]}>
        <div>
          <TextContainer
            title={
              applicant?.applications &&
              (applicant?.applications[0]?.applyingAs == 1 ||
                applicant?.applications[0]?.applyingAs == 0)
                ? "Country You Graduated from"
                : "Country of Current University"
            }
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]
                ?.currentUniversityCountry_Display
            }
            required={true}
          />
          <TextContainer
            title={
              applicant?.intersted?.applicationStart_Display == "Undergraduate"
                ? "School Name"
                : "University Name"
            }
            data={
              applicationData?.applicantDetails &&
              applicationData?.applicantDetails[0]?.schoolCountry_Display
            }
            required={true}
          />
          {applicant?.applications &&
          applicant?.applications[0]?.startYourApplication !== 2 ? (
            <>
              <TextContainer
                title={
                  applicant?.applications &&
                  (applicant?.applications[0]?.applyingAs === 4 ||
                    applicant?.applications[0]?.startYourApplication === 1)
                    ? "Degree Earned Major"
                    : "High School Diploma"
                }
                data={
                  applicationData?.applicantDetails &&
                  applicationData?.applicantDetails[0]?.diplomaType_Display
                }
                required={true}
              />
              {applicant?.applications &&
              applicant?.applications[0]?.applyingAs !== 5 ? (
                <TextContainer
                  title="Graduation Date"
                  data={
                    applicationData?.applicantDetails &&
                    applicationData?.applicantDetails[0]?.graduationYear?.substring(
                      10,
                      0
                    )
                  }
                  required={true}
                />
              ) : null}
              {applicant?.applications &&
              applicant?.applications[0]?.applyingAs == 1 ? (
                <>
                  <TextContainer
                    title="Country of Your Current University"
                    data={
                      applicationData?.applicantDetails &&
                      applicationData?.applicantDetails[0]
                        ?.currentUniversityCountry2_Display
                    }
                    required={true}
                  />
                  <TextContainer
                    title="University Name"
                    data={
                      applicationData?.applicantDetails &&
                      applicationData?.applicantDetails[0]
                        ?.schoolCountry2_Display
                    }
                    required={true}
                  />
                </>
              ) : null}
            </>
          ) : null}
          {applicant?.applications &&
          applicant?.applications[0]?.applyingAs == 8 ? (
            <TextContainer
              title="State"
              data={
                applicationData?.applicantDetails &&
                applicationData?.applicantDetails[0]?.schoolState
              }
              required={true}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AcademicInfo;
