import React from "react";
import Buttons from "../../../Atoms/Buttons/Buttons";
import styles from "../../../Template/SettingsTemplate/SettingsPage.module.css";
import Spinner from "../../Loader/Spinner";

const TitlePageHeader = ({
  title,
  buttonText,
  handleOnClick,
  icon,
  secondButton,
  isLoading = false,
}) => {
  return (
    <div className={styles["icon-container"]}>
      <h1>{title}</h1>
      <div className={styles["icon-subcontainer"]}>
        {secondButton}
        {isLoading ? (
          <Spinner />
        ) : (
          <Buttons
            text={buttonText}
            rounded={true}
            icon={icon ? icon : ""}
            color='#F3223C'
            handleOnClick={handleOnClick}
          />
        )}
      </div>
    </div>
  );
};

export default TitlePageHeader;
